import { createTheme } from '@mui/material/styles';

export const typographyTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem',
          color: '#808080',
          fontFamily: 'DM Sans',
          fontStyle: 'italic',
          marginTop: '8px'
        }
      }
    }
  }
});
export const accordionDetailsTheme = createTheme({
  components: {
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }
      }
    }
  }
});

export const formControlTheme = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: '#F8F8F8',
          borderRadius: '12px',
          padding: '16px',
          maxWidth: '100%',
          alignSelf: 'stretch',
          flexWrap: 'wrap',
          rowGap: '20px'
        }
      }
    }
  }
});

export const selectTheme = (width) =>
  createTheme({
    components: {
      MuiSelect: {
        styleOverrides: {
          root: {
            maxHeight: '2.5rem',
            borderRadius: '8px',
            fontFamily: 'DM Sans',
            fontSize: '0.875rem',
            color: '#595959',
            backgroundColor: '#FFFFFF',
            width
          }
        }
      }
    }
  });
