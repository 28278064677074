import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import {
  handleCheckAll,
  toggleChecked,
  resetCollectionItems,
  updateCollection,
  fetchCollectionItems
} from 'redux/actions';
import { Icon, bpProps, Loader } from '@teespring/ts-components';
import { pluralize } from 'lib/helpers';
import { DASHBOARD_COLLECTIONS_EVENT } from 'lib/tracking';
import { useAmplitude, useCollection } from 'hooks';
import every from 'lodash/every';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import SaveBar from 'components/SaveBar';
import ReturnLink from '../ReturnLink';
import ProductSection from './ProductSection';
import ItemSelectionHeader from './ItemSelectionHeader';
import './CollectionDetail.scss';

export const CollectionDetail = () => {
  const [checkAll, setCheckAll] = useState(false);
  const { collectionId } = useParams();
  const { collection, collectionItems } = useCollection(collectionId);
  const checkedItems = filter(collectionItems, (item) => item.isChecked, []);
  const dispatch = useDispatch();
  const { bpIsLT, setClass } = useSelector((state) => ({ ...bpProps(state) }));
  const isMobileScreen = bpIsLT('mobileLg');
  const { logEvent } = useAmplitude();

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const isAllChecked = every(collectionItems, (item) => item.isChecked);
    setCheckAll(isAllChecked);
  }, [collection, collectionItems]);

  const onCheckedHandler = (id) => dispatch(toggleChecked(id, collectionId));

  const itemsAreChecked = !isEmpty(checkedItems);

  const logAddingProductsEvent = () => {
    logEvent(
      `${DASHBOARD_COLLECTIONS_EVENT}_detail_screen_add_product.clicked`
    );
  };
  const isDeselect = itemsAreChecked && !checkAll;

  const toggleCheckAll = () =>
    dispatch(handleCheckAll(isDeselect ? checkAll : !checkAll, collectionId));

  const onDiscardHandler = () => {
    dispatch(resetCollectionItems(collectionId));
  };

  const onSaveHandler = () => {
    setIsSaving(true);
    dispatch(updateCollection({ collectionItems }, collectionId));

    // TODO Remove timeout once this action
    // is connected to the endpoint
    setTimeout(() => {
      setIsSaving(false);
    }, 1000);
  };

  const handleShowMoreProducts = () => {
    logEvent(
      `${DASHBOARD_COLLECTIONS_EVENT}_collection_detail_show_more_products.clicked`
    );

    dispatch(
      fetchCollectionItems(collectionId, get(collection, 'pageNum') + 1)
    );
  };

  const isDirty = get(collection, 'isDirty');

  return (
    <>
      <div className='collection__header__wrapper border-bottom--light'>
        <ReturnLink path='/listings/collections' sectionName='Collections' />
        <div
          className={setClass({
            default: 'collection__header layout--flex baseline',
            mobileLg: 'collection__header mb3'
          })}
        >
          <h1>{`${get(collection, 'name') ?? ''}`}</h1>
          <span>{pluralize(get(collection, 'totalItems', 0), 'product')}</span>
        </div>
      </div>

      <div
        className={`collection__products__header layout--flex ${
          isMobileScreen ? 'flex-start' : 'center'
        } mt3 mb3`}
      >
        <div>
          <h1>Products</h1>
          <p className='collection__products__header__description'>
            Sort products into the order you would like them to appear in your
            store.
          </p>
        </div>

        <Link
          className='btn collection__products__header__add-product'
          to={`/listings/collections/${collectionId}/products`}
          onClick={logAddingProductsEvent}
        >
          {isMobileScreen ? <Icon name='Plus' /> : 'Add product'}
        </Link>
      </div>

      <div
        className={classnames('collection__productfeed__container', {
          'collection__productfeed--padded-bottom': isDirty
        })}
      >
        <ItemSelectionHeader
          className='collection_detail__header'
          isDeselect={isDeselect}
          isChecked={itemsAreChecked}
          title='Title'
          showDelete={true}
          collectionId={collectionId}
          numCheckedItems={checkedItems.length}
          handleCheckAll={toggleCheckAll}
        />

        <div className='collection__productfeed'>
          <ul>
            {!isEmpty(collectionItems) ? (
              <ProductSection
                products={get(collection, 'collectionItems')}
                collectionId={collectionId}
                handleCheck={onCheckedHandler}
              />
            ) : (
              <p className='typ--center'>
                There are currently no products in this collection
              </p>
            )}

            <li className='collection__productfeed__add'>
              <Link
                to={`/listings/collections/${collectionId}/products`}
                onClick={logAddingProductsEvent}
              >
                <Icon name='PlusCircle' size={16} />
                Add product
              </Link>
            </li>
          </ul>
        </div>

        <div className='collection__product__load__more'>
          {get(collection, 'isFetching') && !isEmpty(collectionItems) ? (
            <Loader />
          ) : (
            <>
              {!get(collection, 'hideLoadMoreBtn') && (
                <button
                  type='button'
                  className='btn btn--transparent'
                  onClick={handleShowMoreProducts}
                  disabled={get(collection, 'hideLoadMoreBtn')}
                >
                  Show more products
                </button>
              )}
            </>
          )}
        </div>
      </div>
      <SaveBar
        onSave={onSaveHandler}
        isLoading={isSaving}
        onDiscard={onDiscardHandler}
        isDirty={isDirty}
      />
    </>
  );
};

export default CollectionDetail;
