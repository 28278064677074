import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addToast, fetchSamplesProductsForListing } from 'redux/actions';
import axios from 'axios';
import get from 'lodash/get';
import { COMMERCE_API_HOST } from '../constants';

const useProductsForListing = (listingId, currency = 'USD', region) => {
  const dispatch = useDispatch();
  const [isFetchingProducts, setIsFetchingProducts] = useState(false);
  const [products, setProducts] = useState([]);

  const fetchListing = useCallback(async () => {
    try {
      setIsFetchingProducts(true);
      const { data: listingData } = await axios.get(
        `${COMMERCE_API_HOST}/v1/listings?listingId=${listingId}&currency=${currency}&visibility=any`
      );
      const listingProducts = await fetchSamplesProductsForListing(
        get(listingData, 'url'),
        currency,
        region
      );
      setProducts(listingProducts);
      setIsFetchingProducts(false);
    } catch (err) {
      dispatch(addToast(err?.response?.data?.message ?? err.message, 'danger'));
    }
  }, [currency, dispatch, listingId, region]);

  useEffect(() => {
    fetchListing();
  }, [listingId, currency, fetchListing]);

  return { isFetchingProducts, products };
};

export default useProductsForListing;
