import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@teespring/ts-components';
import { oneOf } from 'prop-types';
import './ErrorPage.scss';

const errorCta = {
  listing: {
    linkTo: '/overview',
    linkText: 'Return to dashboard'
  },
  store: {
    linkTo: '/stores',
    linkText: 'Return to store'
  }
};

const ErrorPage = ({ type }) => {
  const { linkTo, linkText } = errorCta[type];

  return (
    <div className='page__error'>
      <Icon name='AlertTriangle' size={50} />
      <h2>There was a problem</h2>
      <h2>loading this {type}</h2>

      <div className='page__error__body'>
        <div>
          Please check the URL has been entered correctly. If you have more than
          one account, make sure you are logged into the correct one.
        </div>
      </div>
      <Link to={linkTo} className='btn btn--transparent'>
        {linkText}
      </Link>
    </div>
  );
};

ErrorPage.propTypes = {
  type: oneOf(['listing', 'store']).isRequired
};

export default ErrorPage;
