/* eslint-disable no-case-declarations */
import {
  RECEIVE_COLLECTIONS,
  SET_COLLECTION_ITEMS,
  REORDER_PRODUCTS,
  ADD_COLLECTION_ITEMS,
  RESET_COLLECTION_ITEMS,
  UPDATE_COLLECTION,
  COLLECTIONS_IS_FETCHING,
  COLLECTIONS_HIDE_LOAD_MORE_BUTTON
} from 'redux/actions';
import get from 'lodash/get';

/**
 * Get Collection data from teespring
 * @param  {Object} state  Redux State
 * @param  {Object} action Action data
 * @return {Object} Returns the state
 */
export default (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_COLLECTIONS:
      const collectionIds = Object.keys(action.collections);
      const collectionObj = {};
      collectionIds.forEach((id) => {
        collectionObj[id] = {
          ...action.collections[id],
          isDirty: false,
          originalCollectionItems: action.collections[id].collectionItems,
          isLoading: false
        };
      });
      return {
        ...state,
        ...collectionObj
      };
    case SET_COLLECTION_ITEMS:
      return {
        ...state,
        [action.collectionId]: {
          ...state[action.collectionId],
          collectionItems: action.collectionItems,
          totalItems:
            action.totalItems ??
            (get(state, `${[action.collectionId]}.totalItems`) || 0),
          isDirty: false
        }
      };
    case REORDER_PRODUCTS:
      return {
        ...state,
        [action.collectionId]: {
          ...state[action.collectionId],
          collectionItems: action.collectionItems,
          isDirty: action.isDirty
        }
      };

    case RESET_COLLECTION_ITEMS:
      return {
        ...state,
        [action.collectionId]: {
          ...state[action.collectionId],
          collectionItems: action.collectionItems,
          isDirty: false
        }
      };
    case ADD_COLLECTION_ITEMS:
      return {
        ...state,
        [action.collectionId]: {
          ...state[action.collectionId],
          pageNum:
            action.pageNum ??
            (get(state, `${[action.collectionId]}.pageNum`) || 1),
          collectionItems: [
            ...(state[action.collectionId]?.collectionItems ?? []),
            ...action.collectionItems
          ],
          totalItems:
            action.totalItems ??
            (get(state, `${[action.collectionId]}.totalItems`) || 0) +
              action.collectionItems.length,
          originalCollectionItems: [
            ...(get(
              state,
              `${[action.collectionId]}.originalCollectionItems`
            ) || []),
            ...action.collectionItems
          ]
        }
      };
    case UPDATE_COLLECTION:
      return {
        ...state,
        [action.collectionId]: {
          ...state[action.collectionId],
          ...action.payload,
          originalCollectionItems: get(action, 'payload.collectionItems')
            ? get(action, 'payload.collectionItems')
            : get(state, `${[action.collectionId]}.originalCollectionItems`) ||
              [],
          isDirty: false
        }
      };
    case COLLECTIONS_IS_FETCHING:
      return {
        ...state,
        [action.collectionId]: {
          ...state[action.collectionId],
          isFetching: action.isFetching
        }
      };
    case COLLECTIONS_HIDE_LOAD_MORE_BUTTON:
      return {
        ...state,
        [action.collectionId]: {
          ...state[action.collectionId],
          hideLoadMoreBtn: true
        }
      };
    default:
      return state;
  }
};
