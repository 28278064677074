import { useState } from 'react';
import axios from 'axios';
import { SERVERLESS_BASE_URL } from '../constants';

const useImageUpload = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [percentage, setPercentage] = useState(0);

  const uploadMediaToS3 = async (storeSlug, base64Image) => {
    setIsLoading(true);

    const options = {
      headers: {
        'Content-Type': 'application/json'
      },
      onUploadProgress: ({ loaded, total }) => {
        const percentCompleted = Math.round((loaded * 100) / total);
        setPercentage(percentCompleted);

        if (percentCompleted === 100) {
          setTimeout(() => setIsLoading(false), 1000);
        }
      }
    };

    const { data } = await axios.post(
      `${SERVERLESS_BASE_URL}/previewImage`,
      { storeSlug, base64Image },
      options
    );

    return data;
  };

  const onImageSelect = async (slug, value, name, updateFormData) => {
    if (value) {
      const response = await uploadMediaToS3(slug, value);
      updateFormData({ name, value: response });
    } else {
      updateFormData({ name, value: null });
    }
  };

  return { isLoading, percentage, onImageSelect };
};

export default useImageUpload;
