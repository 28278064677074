import React from 'react';
import { Icon } from '@teespring/ts-components';
import { Link } from 'react-router-dom';
import { useAmplitude } from 'hooks';
import propTypes from 'prop-types';

export const ListingAdminBarActions = ({ actions, showIcons }) => {
  const { logEvent } = useAmplitude();
  return (
    <>
      <ul className='adminbar__actions'>
        {actions.map((act) => {
          const iconButton = (
            <button
              className={`btn ${showIcons && act.icon ? 'spr_iconbutton' : ''}`}
              type='button'
            >
              {act.icon && showIcons && <Icon name={act.icon} size={16} />}
              {act.label}
            </button>
          );

          return (
            <li key={act.id} className={act.id}>
              {act.url && (
                <a
                  href={act.url}
                  target='_blank'
                  rel='noreferrer noopener'
                  onClick={() => logEvent(act.event)}
                >
                  {iconButton}
                </a>
              )}
              {act.routeTo && (
                <Link
                  target='_blank'
                  rel='noreferrer noopener'
                  to={act.routeTo}
                  onClick={() => logEvent(act.event)}
                >
                  {iconButton}
                </Link>
              )}
              {act.content && act.content}
            </li>
          );
        })}
      </ul>
    </>
  );
};

const { arrayOf, string, node, shape, bool } = propTypes;
ListingAdminBarActions.propTypes = {
  actions: arrayOf(
    shape({
      id: string,
      url: string,
      label: string,
      content: node,
      routeTo: string
    })
  ).isRequired,
  showIcons: bool
};

ListingAdminBarActions.defaultProps = {
  showIcons: false
};

export default ListingAdminBarActions;
