import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Icon } from '@teespring/ts-components';
import { object } from 'prop-types';
import { removeItemFromCart } from 'redux/actions';
import has from 'lodash/has';
import { useAmplitude } from 'hooks';

import './OrderSummaryCartItem.scss';

const OrderSummaryCartItem = ({ item }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { logEvent } = useAmplitude();

  const editCartItem = (listingId) => {
    logEvent('samples.order_summary.edit.clicked', { product_id: listingId });
    history.push(`/samples/edit?listingId=${listingId}`);
  };

  const deleteCartItem = (itemToDelete) => {
    logEvent('samples.order_summary.delete.clicked', {
      product_id: itemToDelete.listingId
    });
    dispatch(removeItemFromCart(itemToDelete.variantCartId));
  };

  return (
    <div key={item.id} className='order__summary__cart__item__container'>
      <div className='order__summary__cart__item'>
        <img src={item.thumbnail} alt={item.productTitle} />
        <div className='order__summary__cart__item__info'>
          <strong>
            {has(item, 'productTitle') && <span>{item.productTitle} -</span>}{' '}
            {item.productType}
          </strong>
          <div className='order__summary__cart__item__order__details'>
            <span>{item.selectedColor.label}</span>
            <span>{item.selectedSize.label}</span>
            <span>{item.region.toUpperCase()}</span>
            <span className='order__summary__cart__item__order__details__qty'>
              Qty: {item.quantity}
            </span>
          </div>
        </div>
        <div className='order__summary__action__container'>
          <button
            className='order__summary__action__item'
            type='button'
            onClick={() => editCartItem(item.listingId)}
          >
            <Icon name='Edit3' />
          </button>
          <button
            className='order__summary__action__item'
            type='button'
            onClick={() => deleteCartItem(item)}
          >
            <Icon name='Trash2' />
          </button>
        </div>
      </div>
    </div>
  );
};

OrderSummaryCartItem.propTypes = {
  item: object.isRequired
};

export default OrderSummaryCartItem;
