export const DNS_STATUS = {
  dns_pending: 0,
  dns_success: 1,
  dns_failed: 2
};

export const NETLIFY_STATUS = {
  build_pending: 0,
  build_success: 1,
  build_failed: 2
};
