import { Icon, LoaderButton, bpProps } from '@teespring/ts-components';
import { bool, element, func, string } from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import './ModalTemplate.scss';

/*
Modal Template:
- Most parameters below are optional except modalClose
- Provides standard "Spring approved" styling for title and action
  buttons with some options to easily modify placement and add classes for customization.
- Also provides a fully customizable content slot that accepts any vanilla js or react element.
*/

const ModalTemplate = ({
  modalOnLoad,
  modalContent,
  modalTitle,
  modalTitleAlign,
  modalBtnText,
  modalBtnAction,
  modalBtnClass,
  modalBtnDisabled,
  modalBtnLoading,
  modalClose,
  modalHideCancelButton,
  modalHideActions,
  modalHideCloseButton
}) => {
  const { bpIsGT } = useSelector((state) => ({ ...bpProps(state) }));

  // On mount, remove padding from any elements with class 'modal__content'.
  //
  // This is a terrible hack required because this template expects to be styled
  // without any padding from its parents. The alternative is to update the
  // parent component (ModalConductor) but we can't do that without breaking
  // modals that are not using this template.
  useEffect(() => {
    document
      .getElementsByClassName('modal__content')
      .forEach((modalElement) => {
        modalElement.style.padding = '0px';
      });
  }, []);
  useEffect(() => modalOnLoad(), [modalOnLoad]);
  return (
    <section className='modal_template__container'>
      {!bpIsGT('mobileLg') && (
        /* eslint-disable */
        <hr className='modal_template__drag_bar' onClick={modalClose} />
      )}
      {(modalTitle || bpIsGT('mobileLg')) && (
        <div className='modal_template__title_container'>
          <span className={`modal_template__title ${modalTitleAlign}`}>
            {modalTitle}
          </span>
        </div>
      )}
      {!modalHideCloseButton && bpIsGT('mobileLg') && (
        <button
          type='button'
          onClick={modalClose}
          className='modal_template__close_icon'
        >
          <Icon name='X' />
        </button>
      )}
      {modalContent && (
        <div className='modal_template__content'>{modalContent}</div>
      )}
      <div
        className={`modal_template__actions ${
          modalHideActions ? 'hidden' : ''
        }`}
      >
        {!modalHideCancelButton && (
          <button
            className={`modal_template__actions__cancel_button ${
              bpIsGT('mobileLg') ? 'mr1' : ''
            }`}
            type='button'
            onClick={modalClose}
          >
            Cancel
          </button>
        )}
        <LoaderButton
          className={`${
            modalBtnDisabled ? 'disabled' : ''
          }  modal_template__actions__confirm_button ${modalBtnClass}`}
          type='button'
          loadingIconOnly={modalBtnDisabled}
          loading={modalBtnLoading}
          disabled={modalBtnDisabled}
          onClick={modalBtnAction}
          text={modalBtnText}
        />
      </div>
    </section>
  );
};

ModalTemplate.propTypes = {
  modalOnLoad: func,
  modalClose: func.isRequired,
  modalBtnAction: func,
  modalTitle: string,
  modalTitleAlign: string,
  modalContent: element,
  modalBtnText: string,
  modalBtnLoading: bool,
  modalBtnDisabled: bool,
  modalBtnClass: string,
  modalHideCancelButton: bool,
  modalHideActions: bool,
  modalHideCloseButton: bool
};

ModalTemplate.defaultProps = {
  modalOnLoad: () => {},
  modalBtnLoading: false,
  modalBtnDisabled: false,
  modalHideCancelButton: false,
  modalHideActions: false,
  modalBtnClass: '',
  modalBtnText: 'Action',
  modalBtnAction: () => {},
  modalContent: undefined,
  modalTitle: 'Modal template',
  modalTitleAlign: 'left',
  modalHideCloseButton: false
};

export default ModalTemplate;
