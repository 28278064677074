import { v4 } from 'uuid';

export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';

export const TOAST_DURATION = 60 * 10 * 1000; // 10 minutes
export const TOAST_POSITION = 'top-right';

export const removeToast = (id) => (dispatch, getState) => {
  const { notifications } = getState().toast;
  dispatch({
    type: REMOVE_TOAST,
    payload: [...notifications.filter((notification) => notification.id !== id)]
  });
};

export const addToast = (toastMessage, type = 'danger') => {
  const toast = {
    id: v4(),
    message: toastMessage,
    type,
    options: {
      timeout: TOAST_DURATION,
      position: TOAST_POSITION
    }
  };

  return {
    type: ADD_TOAST,
    toast
  };
};
