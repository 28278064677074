import styled from '@emotion/styled';
import {
  AccordionDetails,
  FormControl,
  ListItemIcon,
  MenuItem,
  Select,
  Typography,
  useMediaQuery
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactComponent as IconProducts } from 'assets/PopUpShop/products.svg';
import { ReactComponent as IconTheme } from 'assets/PopUpShop/theme.svg';
import { ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import map from 'lodash/map';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import {
  AccordionItem,
  AccordionTitle,
  IconContainerSm,
  ItemTitle
} from './StyledMuiComponents/Accordion';
import SliderAndInput from './SliderAndInput';
import { ColorPicker } from './ColorPicker';
import {
  WIDGET_LAYOUT_NAMES,
  WIDGET_LAYOUTS
} from '../../../constants/widgetLayouts';
import './styles/form.scss';
import {
  formControlTheme,
  selectTheme,
  accordionDetailsTheme,
  typographyTheme
} from './styles/formThemes';
import {
  modifyLayout,
  modifyProductAmount,
  modifyTheme
} from '../../../redux/actions';

const FormContent = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

function Form() {
  const dispatch = useDispatch();
  const embedStoreParams = useSelector((state) => state.embedStoreParams);
  const isMobile = useMediaQuery('(max-width:950px)', { noSsr: true });

  return (
    <FormContent onChange={() => {}}>
      <AccordionItem defaultExpanded={true}>
        <AccordionTitle
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <ItemTitle>
            <IconContainerSm>
              <IconProducts />
            </IconContainerSm>
            <div>
              <Typography variant='subtitle1' component='h3'>
                Number of products
              </Typography>
            </div>
          </ItemTitle>
        </AccordionTitle>
        <ThemeProvider theme={accordionDetailsTheme}>
          <AccordionDetails>
            <SliderAndInput
              label='Number of products'
              value={embedStoreParams.productAmount}
              onChange={(e) => {
                dispatch(modifyProductAmount(e.target.value));
              }}
              min={1}
              max={30}
            />
            <ThemeProvider theme={typographyTheme}>
              <Typography variant='caption' style={{ textAlign: 'center' }}>
                Products will appear in the same order as your store
              </Typography>
            </ThemeProvider>
          </AccordionDetails>
        </ThemeProvider>
      </AccordionItem>
      <AccordionItem className='style-accordion-item' defaultExpanded={true}>
        <AccordionTitle
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <ItemTitle>
            <IconContainerSm>
              <IconTheme />
            </IconContainerSm>
            <div>
              <Typography variant='subtitle1' component='h3'>
                Style
              </Typography>
              <Typography />
            </div>
          </ItemTitle>
        </AccordionTitle>
        <ThemeProvider theme={accordionDetailsTheme}>
          <AccordionDetails>
            <ThemeProvider theme={formControlTheme}>
              <FormControl>
                <div className='theme-and-layout-picker'>
                  <Typography
                    fontSize='0.875rem'
                    mr='10px'
                    fontFamily='DM Sans'
                  >
                    Theme:
                  </Typography>
                  <ColorPicker
                    themeOnChange={(e) => {
                      dispatch(modifyTheme(e));
                    }}
                    theme={embedStoreParams.widgetTheme}
                  />
                </div>
                <div className='theme-and-layout-picker'>
                  <Typography
                    fontSize='0.875rem'
                    mr='10px'
                    fontFamily='DM Sans'
                  >
                    Desktop layout:
                  </Typography>
                  <ThemeProvider
                    theme={selectTheme(isMobile ? '147px' : '215px' )}
                  >
                    <Select
                      labelId='dropdown-label'
                      id='dropdown'
                      value={embedStoreParams.widgetLayout}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left'
                        },
                        transformOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left'
                        },
                        getContentAnchorEl: null
                      }}
                      onChange={(e) => {
                        dispatch(modifyLayout(e.target.value));
                      }}
                      renderValue={() => (
                        <div className='layout-select'>
                          {WIDGET_LAYOUT_NAMES[embedStoreParams.widgetLayout]}
                        </div>
                      )}
                    >
                      {map(Object.values(WIDGET_LAYOUTS), (layout) => {
                        return (
                          <MenuItem
                            value={layout}
                            sx={{
                              fontFamily: 'DM Sans',
                              fontSize: '0.875rem',
                              color: 'black',
                              fontWeight: 500
                            }}
                          >
                            <ListItemIcon>
                              {embedStoreParams.widgetLayout === layout && (
                                <DoneRoundedIcon
                                  fontSize='small'
                                  sx={{ color: 'black' }}
                                />
                              )}
                            </ListItemIcon>
                            {WIDGET_LAYOUT_NAMES[layout]}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </ThemeProvider>
                </div>
              </FormControl>
            </ThemeProvider>
            <ThemeProvider theme={typographyTheme}>
              <Typography variant='caption' style={{ textAlign: 'center' }}>
                The widget width will automatically expand to fit mobile and
                desktop layouts.
              </Typography>
            </ThemeProvider>
          </AccordionDetails>
        </ThemeProvider>
      </AccordionItem>
    </FormContent>
  );
}

export default Form;
