import React from 'react';
import { Icon, CopyToClipboard, bpProps } from '@teespring/ts-components';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useAmplitude } from 'hooks';
import { BRANDED_STORES_EVENT } from 'lib/tracking';
import './ShareTooltipContent.scss';

const ShareTooltipContent = ({ shareContent, setCopying }) => {
  const { bpIsGT } = useSelector((state) => ({ ...bpProps(state) }));
  const { logEvent } = useAmplitude();

  return (
    <div className='sharetooltip__content'>
      <CopyToClipboard
        contentToCopy={shareContent}
        onCopy={() => setCopying instanceof Function && setCopying(true)}
        onConfirmationEnd={() =>
          setCopying instanceof Function && setCopying(false)
        }
        confirmationMessage={
          bpIsGT('tabletLg') ? 'Copied' : 'Copied to clipboard'
        }
        copyToggle={
          <div
            className='sharetooltip__title'
            onClick={() =>
              logEvent(
                `${BRANDED_STORES_EVENT}.logged_in_tool_bar.share_listing_copy_link.clicked`
              )
            }
          >
            <Icon size={16} name='Link' className='mr1' />
            Copy Link
          </div>
        }
      />

      <ul className='sharetooltip__shareicons'>
        {/* <li className="icon--youtube">
          <a
            href=""
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon size={ 24 } name="Youtube" />
          </a>
        </li> */}
        <li className='icon--facebook'>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${shareContent}`}
            target='_blank'
            rel='noopener noreferrer'
            onClick={() =>
              logEvent(
                `${BRANDED_STORES_EVENT}.logged_in_tool_bar.share_listing_facebook.clicked`
              )
            }
          >
            <Icon size={16} name='Facebook' />
          </a>
        </li>
        <li className='icon--instagram'>
          <a
            href=''
            target='_blank'
            rel='noopener noreferrer'
          >
            <Icon size={ 24 } name='Instagram' />
          </a>
        </li>
        <li className='icon--twitter'>
          <a
            href={`https://twitter.com/intent/tweet?text=${shareContent}`}
            target='_blank'
            rel='noopener noreferrer'
            onClick={() =>
              logEvent(
                `${BRANDED_STORES_EVENT}.logged_in_tool_bar.share_listing_twitter.clicked`
              )
            }
          >
            <Icon size={16} name='Twitter' />
          </a>
        </li>
      </ul>
    </div>
  );
};

const { string, func } = propTypes;
ShareTooltipContent.propTypes = {
  shareContent: string.isRequired,
  setCopying: func
};

ShareTooltipContent.defaultProps = {
  setCopying: () => {}
};

export default ShareTooltipContent;
