import { useSelector } from 'react-redux';
import { bpProps } from '@teespring/ts-components';

const useBpProps = () => {
  const { bpIsGT } = useSelector((state) => ({ ...bpProps(state) }));
  let deviceType;
  if (bpIsGT('tabletLg')) {
    deviceType = 'desktop';
  } else if (bpIsGT('mobileLg')) {
    deviceType = 'tablet';
  } else {
    deviceType = 'mobile';
  }
  return { bpIsGT, deviceType };
};

export default useBpProps;
