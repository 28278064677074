import { useHistory } from 'react-router-dom';
import './NotFoundPage.scss';

const NotFoundPage = () => {
  const history = useHistory();

  return (
    <div className='not_found'>
      <div className='not_found__container'>
        <h1 className='not_found__header'>404: Page Not Found</h1>
        <div className='not_found__body'>
          Somehow you have come to a page that does not exist.
        </div>
        <button
          className='btn not_found__dashboard_button btn--transparent'
          onClick={() => history.push('/overview')}
          type='button'
        >
          Return to Dashboard
        </button>
      </div>
    </div>
  );
};

export default NotFoundPage;
