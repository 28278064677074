import get from 'lodash/get';
import axios from 'axios';
import { FLAGS } from 'lib/featureFlags';
import { COMMERCE_API_HOST, API_HOST } from '../../../constants';
import { addToast } from '../toast';
import { formatAxiosRequestHeaders } from '../../../lib/helpers';

export const RECEIVE_HEADER_LINKS = 'RECEIVE_HEADER_LINKS';
export const RECEIVE_HEADER_USER_DATA = 'RECEIVE_HEADER_USER_DATA';
export const UPDATE_USER_ADDRESS = 'UPDATE_USER_ADDRESS';
export const RECEIVE_RECENTLY_SIGNED_UP = 'RECEIVE_RECENTLY_SIGNED_UP';

/**
 * Finished header links request
 * @param  {object} data      The header link data
 * @return {func}             The dispatch function
 */
export const receiveHeaderLinks = (data) => (dispatch) => {
  dispatch({
    type: RECEIVE_HEADER_LINKS,
    headerLinks: data
  });
};

/**
 * Finished header user data request
 * @param  {object} data      The header user data
 * @return {func}             The dispatch function
 */
export const receiveHeaderUserData = (data) => (dispatch) => {
  dispatch({
    type: RECEIVE_HEADER_USER_DATA,
    userData: data
  });
};

/**
 * Finished user sign up time request
 * @param  {boolean} recentlySignedUp   Whether the use has recently signed up or not
 * @return {func}                       The dispatch function
 */
export const receiveRecentlySignedUp = (recentlySignedUp) => (dispatch) => {
  dispatch({
    type: RECEIVE_RECENTLY_SIGNED_UP,
    recentlySignedUp
  });
};

/**
 * Fetches header links used to populate the sidenav
 * @param {string} storeVersion store version
 * @return {func}               The dispatch function
 */
export const fetchHeaderLinks =
  (storeVersion) => async (dispatch, getState) => {
    try {
      const showCollections =
        getState().user?.featureFlags?.[FLAGS.SHOW_COLLECTIONS];
      const dataUrl = `${COMMERCE_API_HOST}/v1/dashboard/navigation?stores=${storeVersion}&showCollections=${showCollections}`;
      const { data } = await axios.get(dataUrl);
      dispatch(receiveHeaderLinks(data));
    } catch (err) {
      dispatch(
        addToast(
          `Error getting header data: ${
            err?.response?.data?.message ?? err.message
          }`,
          'danger'
        )
      );
    }
  };

/**
 * Fetches header userData used to populate the sidenav
 * @param {number} userId     Unique identifier for user
 * @return {func}             The dispatch function
 */
export const fetchHeaderUserData = (userId) => async (dispatch, getState) => {
  try {
    if (userId) {
      const dataUrl = `${API_HOST}/api/v2/user/header`;
      const { user } = getState();
      const csrfToken = user.token;
      const { data } = await axios.get(
        dataUrl,
        formatAxiosRequestHeaders(csrfToken)
      );
      dispatch(receiveHeaderUserData(get(data, 'user')));
    }
  } catch (err) {
    dispatch(
      addToast(
        `Error getting user data: ${
          err?.response?.data?.message ?? err.message
        }`,
        'danger'
      )
    );
  }
};

/**
 * Gets if user has recently signed up or not
 * @return {func}             The dispatch function
 */
export const getUserSignUpTime = async (store) => {
  try {
    const dataUrl = `${API_HOST}/api/v2/user/recently_signed_up`;
    const { user } = store.getState();
    const csrfToken = user.token;
    const { data } = await axios.get(
      dataUrl,
      formatAxiosRequestHeaders(csrfToken)
    );
    store.dispatch(receiveRecentlySignedUp(get(data, 'recently_signed_up')));
  } catch (err) {
    store.dispatch(
      addToast(
        `Error getting user signed up data: ${
          err?.response?.data?.message ?? err.message
        }`,
        'danger'
      )
    );
  }
};
