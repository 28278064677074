import React from 'react';
import DeleteIcon from 'components/DeleteIcon';
import { CheckboxInput } from '@teespring/ts-components';
import { bool, number, string, func } from 'prop-types';
import { pluralize } from 'lib/helpers';
import './ItemSelectionHeader.scss';

const ItemSelectionHeader = (props) => {
  const {
    className,
    isDeselect,
    isChecked,
    title,
    showDelete,
    collectionId,
    numCheckedItems,
    handleCheckAll
  } = props;

  const itemsSelected = isDeselect || isChecked;

  return (
    <div
      className={`item_selection_header layout--flex center ${
        itemsSelected ? 'items-checked' : ''
      } ${className}`}
    >
      {itemsSelected ? (
        <div className='collection__selected__products'>
          {`${pluralize(numCheckedItems, 'product')} selected`}
          {showDelete && <DeleteIcon collectionId={collectionId} />}
        </div>
      ) : (
        <h2 className='collection__title'>{title}</h2>
      )}

      <CheckboxInput
        className='mb0 select__all__products'
        checked={isChecked || isDeselect}
        onChange={handleCheckAll}
        manageStateExternally={true}
        customIcon={isDeselect ? 'Minus' : ''}
      />
    </div>
  );
};

ItemSelectionHeader.propTypes = {
  className: string,
  isDeselect: bool.isRequired,
  isChecked: bool.isRequired,
  title: string,
  showDelete: bool,
  collectionId: string,
  numCheckedItems: number,
  handleCheckAll: func.isRequired
};

ItemSelectionHeader.defaultProps = {
  className: '',
  title: '',
  showDelete: true,
  collectionId: '',
  numCheckedItems: 0
};

export default ItemSelectionHeader;
