import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import KitchenSinkStory from 'react-data-table-component';
import { format, subDays, addDays } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { fetchAnalyticsGraphql } from '../../redux/actions/analytics';
import { parseQueryStrings } from '../../lib/helpers';
import 'react-day-picker/dist/style.css';
import './AnalyticsPage.scss';

const Analytics = () => {
  const location = useLocation();
  const endDate = new Date();
  const startDate = subDays(endDate, 7);
  const { start, end } = parseQueryStrings(location.search);
  const defaultSelected = {
    from: startDate,
    to: endDate
  };
  const [graphType, setGraphType] = useState('profitAsNumber');
  const [range, setRange] = useState(defaultSelected);
  const [openPopup, setOpenPopup] = useState(false);
  const analytics = useSelector((state) => state.analytics);
  const dispatch = useDispatch();
  const sourceColumns = [
    {
      name: 'Source',
      selector: (row) => row.source,
      sortable: true
    },
    {
      name: 'Orders',
      selector: (row) => row.totalOrders,
      sortable: true
    },
    {
      name: 'Units',
      selector: (row) => row.totalUnits,
      sortable: true
    },
    {
      name: 'Profit',
      selector: (row) => row.totalProfit,
      width: '150px',
      right: true,
      sortable: true
    }
  ];
  const listingColumns = [
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true
    },
    {
      name: 'Url',
      selector: (row) => row.url,
      sortable: true
    },
    {
      name: 'Views',
      selector: (row) => row.views,
      maxWidth: '150px',
      sortable: true
    },
    {
      name: 'Orders',
      selector: (row) => row.orders,
      maxWidth: '150px',
      sortable: true
    },
    {
      name: 'Units',
      selector: (row) => row.units,
      maxWidth: '150px',
      sortable: true
    },
    {
      name: 'Profit',
      selector: (row) => row.profit.amount,
      maxWidth: '150px',
      right: true,
      sortable: true
    }
  ];
  const orderColumns = [
    {
      name: 'Order Date',
      selector: (row) => row.order_date,
      format: (row) => format(new Date(row.order_date), 'MMM dd - hh:mmaaa '),
      maxWidth: '150px',
      sortable: true
    },
    {
      name: 'Source',
      selector: (row) => row.source,
      cell: (row) => (
        <div>
          <span>{row.source}</span>

          {row.promo_code && (
            <>
              <br />
              <span>Promo: {row.promo_code}</span>
            </>
          )}
        </div>
      ),
      wrap: true,
      maxWidth: '250px',
      sortable: true
    },
    {
      name: 'Listing',
      selector: (row) => row.campaign_name,
      sortable: true
    },
    {
      name: 'Products',
      selector: (row) => row.line_items,
      cell: (row) => (
        <div>
          {row.line_items.map((item) => {
            return (
              <>
                <span>
                  {item.quantity} x {item.name}
                </span>
                <br />
              </>
            );
          })}
        </div>
      ),
      wrap: true
    },
    {
      name: 'Profit',
      selector: (row) => row.net_profit,
      maxWidth: '150px',
      right: true,
      sortable: true
    }
  ];

  useEffect(() => {
    if (start && end) {
      const newStart = new Date(start);
      const newEnd = new Date(end);
      if (newStart < newEnd) {
        setRange({
          from: addDays(newStart, 1),
          to: addDays(newEnd, 1)
        });
      }
      const getUpdatedAnalyticsData = async () => {
        await dispatch(fetchAnalyticsGraphql(newStart, newEnd));
      };
      getUpdatedAnalyticsData();
    }
  }, [start, end]);
  useEffect(() => {
    const getUpdatedAnalyticsData = async () => {
      await dispatch(
        fetchAnalyticsGraphql(
          format(range.from, 'yyyy-MM-dd'),
          format(range.to ? range.to : range.from, 'yyyy-MM-dd')
        )
      );
    };
    getUpdatedAnalyticsData();
  }, [range, dispatch]);
  return (
    <div className='analytics'>
      <div
        className='overview'
        style={{
          overflowY: openPopup ? 'hidden' : 'auto',
          height: openPopup ? '100vh' : 'auto'
        }}
      >
        <div className='overview__content'>
          <div className='title'>
            <h1>Analytics</h1>
            <button
              type='button'
              onClick={() => {
                setOpenPopup(true);
              }}
            >
              {format(range.from, 'MM/dd/yy')}-
              {format(range.to ? range.to : range.from, 'MM/dd/yy')}
            </button>
          </div>
          <div className='content'>
            <div className='summary'>
              <div className='item'>
                <p className='number'>
                  {analytics.isFetching ? '--' : analytics.stats.orders}
                </p>
                <span className='label'>Orders Made</span>
              </div>
              <div className='item'>
                <p className='number'>
                  {analytics.isFetching ? '--' : analytics.stats.order_units}
                </p>
                <span className='label'>Units Sold</span>
              </div>
              <div className='item'>
                <p className='number'>
                  {analytics.isFetching
                    ? '--'
                    : analytics.stats.profit_made.amount}
                </p>
                <span className='label'>Total Profit</span>
              </div>
            </div>
            <div className='chart'>
              <ResponsiveContainer
                id='springBoardChart'
                width='100%'
                height='100%'
              >
                <AreaChart
                  width={730}
                  height={250}
                  data={analytics.stats.chart_data}
                  margin={{
                    top: 15,
                    right: 30,
                    bottom: 15,
                    left: 0
                  }}
                >
                  <defs>
                    <linearGradient id='color' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor='#1954eb' stopOpacity={0.8} />
                      <stop offset='95%' stopColor='#1954eb' stopOpacity={0} />
                    </linearGradient>
                    <filter id='blurMe'>
                      <feGaussianBlur stdDeviation='4' />
                    </filter>
                  </defs>
                  <XAxis dataKey='date' />
                  <YAxis dataKey={graphType} />
                  <Area
                    type='monotone'
                    dataKey={graphType}
                    stroke='#1954eb'
                    fillOpacity={1}
                    fill='url(#color)'
                    filter={analytics.isFetching ? 'url(#blurMe)' : ''}
                  />
                  <Tooltip
                    formatter={(name, value) => [
                      `${
                        graphType === 'profitAsNumber'
                          ? '$'.concat(name.toFixed(2))
                          : name
                      }`,
                      `${graphType === 'profitAsNumber' ? 'Profit' : 'Views'}`,
                      `${
                        graphType === 'profitAsNumber'
                          ? '$'.concat(value)
                          : value
                      }`
                    ]}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
            <div className='actions'>
              <button
                type='button'
                className={`${
                  graphType === 'profitAsNumber' ? 'selected' : ''
                }`}
                onClick={() => {
                  setGraphType('profitAsNumber');
                }}
              >
                Profit
              </button>
              <button
                type='button'
                className={`${graphType === 'views' ? 'selected' : ''}`}
                onClick={() => {
                  setGraphType('views');
                }}
              >
                Views
              </button>
            </div>
          </div>
          <div className='content'>
            <div className='title'>
              <h3>Source of Units</h3>
            </div>
            <KitchenSinkStory
              direction='auto'
              fixedHeader={false}
              noHeader={true}
              responsive={true}
              striped={true}
              subHeader={false}
              subHeaderAlign='right'
              subHeaderWrap={true}
              data={analytics.stats.unit_sources_data}
              columns={sourceColumns}
              pagination={true}
              progressPending={analytics.isFetching}
            />
          </div>
          <div className='content'>
            <div className='title'>
              <h3>Listings</h3>
            </div>
            <KitchenSinkStory
              direction='auto'
              fixedHeader={false}
              noHeader={true}
              responsive={true}
              striped={true}
              subHeader={false}
              subHeaderAlign='right'
              subHeaderWrap={true}
              data={analytics.stats.campaign_roots_data}
              columns={listingColumns}
              pagination={true}
              progressPending={analytics.isFetching}
            />
          </div>
          <div className='content'>
            <div className='title'>
              <h3>Orders</h3>
            </div>
            <KitchenSinkStory
              direction='auto'
              fixedHeader={false}
              noHeader={true}
              responsive={true}
              striped={true}
              subHeader={false}
              subHeaderAlign='right'
              subHeaderWrap={true}
              data={analytics.stats.order_feed}
              columns={orderColumns}
              pagination={true}
              progressPending={analytics.isFetching}
            />
          </div>
        </div>
      </div>
      {openPopup && (
        <div className='overlay'>
          <div className='modal'>
            <DayPicker
              mode='range'
              selected={range}
              defaultMonth={startDate}
              onSelect={setRange}
            />
            <div className='actions'>
              <button
                className='secondary'
                type='button'
                onClick={() => {
                  setOpenPopup(false);
                }}
              >
                Cancel
              </button>
              <button
                className='primary'
                type='button'
                onClick={() => {
                  setOpenPopup(false);
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Analytics;
