/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { string } from 'prop-types';
import './IntegrationItem.scss';
import { useAmplitude } from 'hooks';
import { useEffect } from 'react';

const IntegrationItem = ({ url, image, newTarget }) => {
  const { logEvent } = useAmplitude();
  const gotoIntegration = async (target) => {
    await logEvent('integration.clicked', {
      url
    });
    window.open(url, target);
  };

  useEffect(() => {
    logEvent('integration.viewed', { url });
  });

  return (
    <div className='integration-item'>
      <img src={image} alt='' onClick={() => gotoIntegration(newTarget)} />
    </div>
  );
};

IntegrationItem.defaultProps = {
  url: '',
  image: '',
  newTarget: '_blank'
};

IntegrationItem.propTypes = {
  url: string,
  image: string,
  newTarget: string
};

export default IntegrationItem;
