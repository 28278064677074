import { string } from 'prop-types';

export const IntegrationsPageSubtitle = ({ subtitle }) => {
  return (
    <div
      className='integrations_page_subtitle'
      style={{
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 400,
        color: '#404040'
      }}
    >
      {subtitle}
    </div>
  );
};

IntegrationsPageSubtitle.propTypes = {
  subtitle: string.isRequired
};

export default IntegrationsPageSubtitle;
