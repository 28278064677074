import {
  fetchHeaderLinks,
  fetchHeaderUserData,
  getFeatureFlagsForLargeGroupOfUsers,
  getOverviewContent,
  getUserSignUpTime,
  getUserStores,
  setStoreVersion,
  setToken,
  setUserId
} from './actions';

export const hydrateStore = async (store, userId, storeVersion, token) => {
  store.dispatch(setToken(token));
  store.dispatch(setUserId(userId));
  store.dispatch(setStoreVersion(storeVersion ?? 'v1'));
  await store.dispatch(fetchHeaderLinks(storeVersion ?? 'v1'));
  await store.dispatch(fetchHeaderUserData(userId));
  await store.dispatch(getFeatureFlagsForLargeGroupOfUsers(userId));
  await store.dispatch(getUserStores(userId));
  await store.dispatch(getOverviewContent());
  await getUserSignUpTime(store);
};
