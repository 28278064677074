import { Accordion, AccordionSummary } from '@mui/material';
import styled from '@emotion/styled';

export const AccordionItem = styled(Accordion)`
  margin-top: 16px !important;
  box-shadow: none !important;
  overflow: hidden;
  width: 100%;
  border-bottom: solid 1px #eeeeee;
  &.Mui-expanded .MuiCollapse-root {
    margin-bottom: 16px;
  }
  &.Mui-expanded {
    margin-bottom: 0px;
  }
`;

export const AccordionTitle = styled(AccordionSummary)`
  height: 64px;
  padding: 0 0 16px 0 !important;
  .MuiAccordionSummary-content {
    display: flex;
    gap: 12px;
    flex-direction: row;
    align-items: center;
  }
`;

export const ItemTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

export const IconContainerLg = styled.div`
  background: #f8f8f8;
  border: 1px solid #eeeeee;
  border-radius: 20000px;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconContainerSm = styled.div`
  background: #f8f8f8;
  border: 1px solid #eeeeee;
  border-radius: 20000px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
