import { useEffect, useState } from 'react';
import axios from 'axios';
import { isNil } from 'lodash';
import map from 'lodash/map';
import { useSelector } from 'react-redux';
import { Icon, bpProps } from '@teespring/ts-components';
import { COMMERCE_API_HOST } from '../../../constants';
import IntegrationItem from './IntegrationItem';
import './IntegrationShelf.scss';

const IntegrationShelf = () => {
  const { bpIsGT } = useSelector((state) => ({ ...bpProps(state) }));
  const [ integrations, setIntegrations ] = useState([]);
  useEffect(async () => {
    const requestSource = axios.CancelToken.source();
    try {
      const response = await axios.get(`${COMMERCE_API_HOST}/v1/dashboard/navigation?stores=v1`,  {
        cancelToken: requestSource.token
      });
      const activeIntegrations = response.data.items?.find(item => item.label === 'Integrations')?.subNav || [];
      const integrationsToDisplay = activeIntegrations
          .filter(integration => !isNil(integration.image) )
          .sort((aIntegration,anotherIntegration) => aIntegration.index - anotherIntegration.index)
      setIntegrations(integrationsToDisplay)
    } catch (error) {
      if (axios.isCancel(error)) {
        setIntegrations([])
      }
    }
    return () => {
      requestSource.cancel();
    };

  }, []);

  const scrollLeft = () => {
    const el = document.getElementsByClassName('integration-shelf');
    el[0].scrollLeft -= 300;
  };
  const scrollRight = () => {
    const el = document.getElementsByClassName('integration-shelf');
    el[0].scrollLeft += 300;
  };
  return (
    <div className='integration-shelf__wrapper'>
      <div className='integration-shelf__title'>
        <h1 className='mb1' style={{ marginLeft: '30px' }}>
          Sell anywhere
        </h1>
        <div className='spacer' />
        {bpIsGT('mobileLg') && (
          <>
            <button type='button' onClick={() => scrollLeft()}>
              <Icon className='scrollLeft' name='ArrowLeft' />
            </button>
            <button type='button' onClick={() => scrollRight()}>
              <Icon className='scrollRight' name='ArrowRight' />
            </button>
          </>
        )}
      </div>
      <div id='integration-shelf' className='integration-shelf'>
        {map(integrations, (item) => (
          <IntegrationItem
            key={item.locationUrl}
            image={item.image}
            url={item.locationUrl}
            newTarget='_self'
          />
        ))}
      </div>
    </div>
  );
};

IntegrationShelf.defaultProps = {
  url: '',
  image: ''
};

export default IntegrationShelf;
