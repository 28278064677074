/**
 * Action types and action creators for user integration status
 * @return {Object}
 */

export const SET_INTEGRATED = 'SET_INTEGRATED';
export const SET_NOT_INTEGRATED = 'SET_NOT_INTEGRATED';

export const userIntegrated = () => {
  return { type: SET_INTEGRATED };
};

export const userNotIntegrated = () => {
  return { type: SET_NOT_INTEGRATED };
};
