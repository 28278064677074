import {
  SET_COLLECTION_PRODUCTS,
  ADD_INDIVIDUAL_LISTING_PRODUCTS,
  COLLECTION_PRODUCTS_IS_FETCHING,
  SET_SEARCH_RESULTS,
  SET_SORTBY,
  SET_VIEW,
  SELECT_PRODUCT,
  SELECT_ALL_PRODUCT,
  COLLECTION_PRODUCTS_HIDE_LOAD_MORE_BUTTON,
  SORT_LISTINGS
} from '../actions/collectionProducts';

import { IS_LOADING, MIGRATE_COLLECTIONS } from '../actions/collections';

const INITIAL_STATE = {
  // not sure if this needs to be a hash map or just an array
  products: [],
  selected: [],
  view: 'product',
  sortBy: {
    id: 'recent',
    sortByParam: 'createdDate',
    orderBy: 'DESC'
  },
  isFetching: false,
  searchResults: [],
  isDisplayingSearchResults: false,
  hideLoadMoreBtn: false,
  isLoading: false,
  pageNum: 1,
  migrationSucceeded: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MIGRATE_COLLECTIONS:
      return {
        ...state,
        migrationSucceeded: action.migrationSucceeded
      };
    case SET_COLLECTION_PRODUCTS:
      return {
        ...state,
        products: state.products.concat(action.payload),
        isFetching: false,
        pageNum: action.pageNum
      };
    case ADD_INDIVIDUAL_LISTING_PRODUCTS:
      return {
        ...state,
        products: [
          ...state.products.slice(0, action.index),
          action.payload,
          ...state.products.slice(action.index + 1, state.products.length)
        ],
        isFetching: false
      };
    case COLLECTION_PRODUCTS_IS_FETCHING:
      return {
        ...state,
        isFetching: action.value
      };
    case IS_LOADING:
      return {
        ...state,
        isLoading: action.value
      };
    case SET_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.results,
        isDisplayingSearchResults: action.results.length > 0
      };
    case SET_VIEW:
      return {
        ...state,
        view: action.view
      };
    case SET_SORTBY:
      return {
        ...state,
        sortBy: action.sortBy
      };
    case SELECT_PRODUCT:
    case SELECT_ALL_PRODUCT:
      return {
        ...state,
        products: action.payload
      };
    case COLLECTION_PRODUCTS_HIDE_LOAD_MORE_BUTTON:
      return {
        ...state,
        hideLoadMoreBtn: true
      };
    case SORT_LISTINGS:
      return {
        ...state,
        products: action.payload
      };
    default:
      return state;
  }
};
