import axios from 'axios';
import get from 'lodash/get';
import { formatAxiosRequestHeaders } from './helpers';
import { API_HOST, IS_PRODUCTION } from '../constants';

const syncUrl = (storeId) =>
  `${API_HOST}/api/v0/stores/${storeId}/collections/default/products`;

export const syncAddCollectionItem = (collection, currentItem, token) => {
  if (IS_PRODUCTION) {
    axios.post(
      syncUrl(get(collection, 'store_id')),
      {
        campaignId: get(currentItem, 'listingId'),
        productId: get(currentItem, 'productId')
      },
      formatAxiosRequestHeaders(token)
    );
  }
};

export const syncDeleteCollectionItem = (collection, items, token) => {
  if (IS_PRODUCTION) {
    items.forEach((item) => {
      const productId = get(item, 'productId') || get(item, 'default_id');
      const campaignId = get(item, 'listingId') || get(item, 'external_id');
      axios.delete(`${syncUrl(get(collection, 'store_id'))}`, {
        ...formatAxiosRequestHeaders(token),
        data: {
          campaignId,
          productId
        }
      });
    });
  }
};
