import './IntegrationDisconnectModal.scss';
import { bool, func } from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import { API_HOST } from '../../../../constants';
import {
  userIntegrated,
  userNotIntegrated
} from '../../../../redux/actions/integratedConnection';
import useIntegrations from '../../../../hooks/useIntegrations';
import useCookie from '../../../../hooks/useCookie';
import { formatAxiosRequestHeaders } from '../../../../lib/helpers';

const IntegrationDisconnectModal = ({ openModal, closeModal }) => {
  const ref = useRef();
  const [hasBeenDisconnected, setHasBeenDisconnected] = useState(false);
  const [disconnectionError, setDisconnectionError] = useState(false);
  const [disableDisconnectButton, setDisableDisconnectButton] = useState(false);
  const { getIntegrationData } = useIntegrations();
  const dispatch = useDispatch();
  const { getValue } = useCookie();
  const token = getValue('token');
  useEffect(() => {
    if (openModal) {
      ref.current?.showModal?.();
    } else {
      ref.current?.close?.();
    }
  }, [openModal]);

  const handleDisconnectClick = async () => {
    try {
      setDisableDisconnectButton(true);
      const response = await axios.delete(
        `${API_HOST}/dashboard/api/integrations/youtube/disconnect`,
          formatAxiosRequestHeaders(token)
      );
      if (response.status === 200) {
        setHasBeenDisconnected(true);
        const status = await getIntegrationData();
        if (status?.youtube) {
          setTimeout(() => {
            dispatch(userIntegrated());
          }, 5000);
        } else {
          setTimeout(() => {
            dispatch(userNotIntegrated());
          }, 5000);
        }
      }
    } catch (e) {
      setDisconnectionError(true);
    }
  };

  const debouncedHandleDisconnectClick = debounce(handleDisconnectClick, 300);

  return (
    <dialog
      className='youtube_disconnect_modal'
      ref={ref}
      onCancel={closeModal}
    >
      <button
        type='button'
        className='youtube_disconnect_modal_close'
        onClick={closeModal}
      >
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M18 6L6 18'
            stroke='#08080D'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M6 6L18 18'
            stroke='#08080D'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </button>
      {!hasBeenDisconnected && !disconnectionError && (
        <>
          <hgroup>
            <h1>
              Are you sure you want to disconnect your YouTube account? This
              will revoke Spring's access to your YouTube data.
            </h1>
          </hgroup>
          <article>
            <h3>Important</h3>
            <ul>
              <li>
                <span>
                  This action will delete all data stored by Spring that was
                  accessed through your YouTube account within 7 calendar days.
                </span>
              </li>
              <li>
                <span>
                  Deleting data stored by Spring does not affect any data stored
                  on YouTube. To delete data on YouTube, please use the YouTube
                  application or an authorized API Client that supports data
                  deletion.
                </span>
              </li>
            </ul>
          </article>
          <hr />
          <footer>
            <span>Do you wish to proceed?</span>
            <button type='button' onClick={closeModal}>
              Cancel
            </button>
            <button
              type='button'
              disabled={disableDisconnectButton}
              onClick={debouncedHandleDisconnectClick}
            >
              Yes, Disconnect
            </button>
          </footer>
        </>
      )}
      {hasBeenDisconnected && (
        <section className='youtube_disconnect_modal-after_disconnect'>
          <hgroup>
            <h1>
              Your YouTube account has been successfully disconnected from
              Spring.
            </h1>
          </hgroup>
          <button
            type='button'
            onClick={closeModal}
            style={{ marginTop: '2rem' }}
          >
            OK
          </button>
        </section>
      )}
      {disconnectionError && (
        <section className='youtube_disconnect_modal-after_disconnect'>
          <hgroup>
            <h1>Unable to disconnect</h1>
          </hgroup>
          <p>
            We are unable to process the disconnection at this time. Please try
            again later.
          </p>
          <button type='button' onClick={closeModal}>
            OK
          </button>
        </section>
      )}
    </dialog>
  );
};

IntegrationDisconnectModal.propTypes = {
  openModal: bool.isRequired,
  closeModal: func.isRequired
};

export default IntegrationDisconnectModal;
