import axios from 'axios';
import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import { processCollectionItemSlug } from 'lib/helpers';
import { useCookie } from 'hooks';
import {
  syncAddCollectionItem,
  syncDeleteCollectionItem
} from 'lib/syncCollections';
import { DEFAULT_COLUMN_KEY, COLLECTIONS_API_HOST } from '../../../constants';
import { addToast } from '../toast';

export const MIGRATE_COLLECTIONS = 'MIGRATE_COLLECTIONS';
export const RECEIVE_COLLECTIONS = 'RECEIVE_COLLECTIONS';
export const SET_COLLECTION_ITEMS = 'SET_COLLECTION_ITEMS';
export const REORDER_PRODUCTS = 'REORDER_PRODUCTS';
export const ADD_COLLECTION_ITEMS = 'ADD_COLLECTION_ITEMS';
export const RESET_COLLECTION_ITEMS = 'RESET_COLLECTION_ITEMS';
export const UPDATE_COLLECTION = 'UPDATE_COLLECTION';
export const IS_LOADING = 'IS_LOADING';
export const COLLECTIONS_IS_FETCHING = 'COLLECTIONS_IS_FETCHING';
export const COLLECTIONS_HIDE_LOAD_MORE_BUTTON =
  'COLLECTIONS_HIDE_LOAD_MORE_BUTTON';

export const migrateCollections = () => async (dispatch, getState) => {
  try {
    const { userId } = getState().user;
    const { status } = await axios.post(
      `${COLLECTIONS_API_HOST}/collections/migrate?userId=${userId}`
    );

    dispatch({
      type: MIGRATE_COLLECTIONS,
      migrationSucceeded: status === 200
    });
  } catch (err) {
    throw err;
  }
};

export const fetchCollectionItems =
  (collectionId, pageNum = 1) =>
  async (dispatch) => {
    try {
      const dataUrl = `${COLLECTIONS_API_HOST}/collection/${collectionId}/items?page=${pageNum}&visibility=any`;
      dispatch({
        type: COLLECTIONS_IS_FETCHING,
        collectionId,
        isFetching: true
      });
      const { data } = await axios.get(dataUrl);

      if (get(data, 'totalPages') === pageNum) {
        dispatch({ type: COLLECTIONS_HIDE_LOAD_MORE_BUTTON, collectionId });
      }

      dispatch({
        type: ADD_COLLECTION_ITEMS,
        collectionItems: data.items,
        collectionId,
        pageNum,
        totalItems: get(data, 'count')
      });
      dispatch({
        type: COLLECTIONS_IS_FETCHING,
        collectionId,
        isFetching: false
      });
    } catch (err) {
      dispatch(addToast(err?.response?.data?.message ?? err.message, 'danger'));
    }
  };

/**
 * Get collections data for a store
 * @param  {string}  storeSlug      The slug of the store
 * @return {function}               The dispatch function
 */
export const fetchCollections = () => async (dispatch, getState) => {
  try {
    const { userId, stores } = getState().user;
    const dataUrl = `${COLLECTIONS_API_HOST}/collections?userId=${userId}`;
    const {
      data: { collections }
    } = await axios.get(dataUrl);

    const collectionsMap = {};

    const promises = collections.map(async (collection) => {
      collectionsMap[collection.id] = collection;
      const storeName = get(
        find(stores, (s) => s.id === collection.store_id),
        'name',
        ''
      );
      collectionsMap[collection.id].name = storeName;
      return await dispatch(fetchCollectionItems(collection.id));
    });

    await Promise.all(promises);

    dispatch({
      type: RECEIVE_COLLECTIONS,
      collections: collectionsMap
    });
  } catch (err) {
    dispatch(addToast(err?.response?.data?.message ?? err.message, 'danger'));
  }
};

/**
 * Deletes collection items using their ids
 * @param {string} collectionId The collection id
 * @return {function} The dispatch function
 */

export const deleteCollectionItem =
  (collectionId) => async (dispatch, getState) => {
    try {
      const collection = get(getState().collections, collectionId);
      const items = collection.collectionItems.slice();

      const { itemIds, numItems, itemList } = reduce(
        items,
        (acc, item) => {
          if (item.isChecked) {
            acc.numItems += 1;
            acc.itemIds += `itemId=${item.id}&`;
            acc.itemList.push(item);
          }
          return acc;
        },
        { itemIds: '', numItems: 0, itemList: [] }
      );

      const url = `${COLLECTIONS_API_HOST}/collection/${collectionId}/items?${itemIds}`;
      await axios.delete(url);

      const newItems = [
        ...filter(items, (item) => {
          if (!itemIds.includes(item.id)) {
            return item;
          }
        })
      ];

      const { getValue } = useCookie();
      const token = getValue('token');

      syncDeleteCollectionItem(collection, itemList, token);

      dispatch({
        type: SET_COLLECTION_ITEMS,
        collectionItems: newItems,
        totalItems: get(collection, 'totalItems', 0) - numItems || 0,
        collectionId
      });
    } catch (error) {
      throw new Error(`An error occured ${error.message}`);
    }
  };

const generateCollectionItemPOSTBody = (item, collectionId, rank) => ({
  rank,
  collection_id: collectionId,
  collection_item_type_id: 1,
  external_id: item.listingId,
  product_type_id_default: item.productId ?? item.primaryProductId,
  price: item.price,
  currency_code: item.currency,
  title: item.title,
  description: '',
  product_url: processCollectionItemSlug(
    item.url,
    item.productId ?? item.primaryProductId
  ),
  visibility: item.visibility !== 'private',
  images: item.thumbnail
});

export const addCollectionItem =
  (items, collectionId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: IS_LOADING,
        value: true
      });
      const collection = get(getState().collections, collectionId);
      const newItems = map(items, (item) => ({
        ...item,
        isChecked: false,
        primaryProductType:
          get(item, 'primaryProductType') || get(item, 'productType'),
        productType: '',
        external_id: item.listingId
      }));

      const url = `${COLLECTIONS_API_HOST}/collection/items`;
      const { getValue } = useCookie();
      const token = getValue('token');

      for (let i = 0; i < items.length; i += 1) {
        const currentItem = items[i];
        const { data } = await axios.post(
          url,
          generateCollectionItemPOSTBody(currentItem, collectionId, i + 1)
        );
        newItems[i].id = data.id;

        syncAddCollectionItem(collection, currentItem, token);
      }

      dispatch({
        type: ADD_COLLECTION_ITEMS,
        collectionItems: newItems,
        collectionId
      });
    } catch (error) {
      throw new Error(`An error occured ${error.message}`);
    } finally {
      dispatch({
        type: IS_LOADING,
        value: false
      });
    }
  };

/**
 * Resets collectionItems state to match data stored in originalCollectionItems
 * @param {string} collectionId The collection id
 * @return {function} The dispatch function
 */

export const resetCollectionItems = (collectionId) => (dispatch, getState) => {
  const collection = get(getState().collections, collectionId);
  const originalItems = get(collection, 'originalCollectionItems').slice();

  dispatch({
    type: RESET_COLLECTION_ITEMS,
    collectionId,
    collectionItems: originalItems
  });
};

/**
 * Toggles checked state for a collection item
 * @param {string} collectionItemId The collection item id
 * @param {string} collectionId The collection id
 * @return {function} The dispatch function
 */

export const toggleChecked =
  (collectionItemId, collectionId) => (dispatch, getState) => {
    const collection = get(getState().collections, collectionId);
    const items = collection.collectionItems.slice();
    const updated = map(items, (item) =>
      item.id === collectionItemId
        ? { ...item, isChecked: !item.isChecked ?? true }
        : item
    );

    dispatch({
      type: SET_COLLECTION_ITEMS,
      collectionItems: updated,
      collectionId
    });
  };

/**
 * Toggles checked state for all collection items within a collection
 * @param {bool} isChecked
 * @param {string} collectionId The collection id
 * @return {function} The dispatch function
 */

export const handleCheckAll =
  (isChecked, collectionId) => (dispatch, getState) => {
    const collection = get(getState().collections, collectionId);
    const items = collection.collectionItems.slice();
    const updated = map(items, (item) => ({ ...item, isChecked }));

    dispatch({
      type: SET_COLLECTION_ITEMS,
      collectionItems: updated,
      collectionId
    });
  };

/**
 * Handle reordering of products from drag n drop
 * @param {object} newState
 * @param {string or int} collectionId
 * @return {function} dispatch function
 */

export const reorderProducts =
  (newState, collectionId) => (dispatch, getState) => {
    const collection = get(getState().collections, collectionId);
    const items = get(collection, 'collectionItems').slice();

    const newCollectionItems = newState.columns[
      DEFAULT_COLUMN_KEY
    ].cellIds.reduce((acc, curr) => {
      const product = find(items, (item) => item.id === +curr);
      if (product) acc.push(product);
      return acc;
    }, []);

    const isDirty = !isEqual(
      newCollectionItems,
      collection.originalCollectionItems
    );

    dispatch({
      type: REORDER_PRODUCTS,
      collectionItems: newCollectionItems,
      collectionId,
      isDirty
    });
  };

export const updateCollection = (newState, collectionId) => (dispatch) => {
  const url = `${COLLECTIONS_API_HOST}/collection/${collectionId}/items`;
  axios.put(url, {
    collectionItems: newState.collectionItems
  });

  dispatch({
    type: UPDATE_COLLECTION,
    collectionId,
    payload: newState
  });
};
