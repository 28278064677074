/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import styled from '@emotion/styled';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { useGTM, useAmplitude, useBpProps, useBannerVisibility } from 'hooks';
import { useSelector } from 'react-redux';
import { UPGRADES_PAGE_EVENT } from 'lib/tracking';
import moment from 'moment';
import FeatureCard from '../../components/FeatureCard';
import Data from './UpgradesConfig';
import UpgradeFilter from './UpgradeFilter';

import './Upgrades.css';

const Container = styled.div`
  overflow: visible;
  max-width: 980px;
  padding: 0 32px;
  margin: 40px auto auto auto;
  @media only screen and (max-width: 980px) {
    padding: 0 16px 0px 16px;
  }
`;

const Upgrades = () => {
  const [item, setItem] = useState(Data);
  const menuItems = [...new Set(Data.map((Val) => Val.appType))];
  const { deviceType } = useBpProps();
  const { user } = useSelector((state) => state);
  const location = useLocation();
  const timestamp = moment().toISOString();
  const filterItem = (curcat) => {
    const newItem = Data.filter((newVal) => {
      return newVal.appType === curcat;
    });
    setItem(newItem);
  };

  const { pushToDataLayer } = useGTM();
  const { logEvent } = useAmplitude();

  useEffect(() => {
    logEvent('upgradesPage.viewed');
  });

  const onClick = (value) => {
    logEvent(`${UPGRADES_PAGE_EVENT}.tile.clicked`, {
      deviceType,
      tileName: value.elementName,
      userId: user.userId,
      creatorName: user.name,
      dateTime: timestamp,
      page: location.pathname
    });

    pushToDataLayer({
      event: 'gtm.linkClick',
      gtm: {
        elementClasses: 'upgrades-page',
        elementId: value.elementName,
        elementUrl: value.ctaLink
      }
    });
  };
  const MotionUpgradeCard = motion(FeatureCard);

  // Banner active from date 2024-09-03 3:00 AM PST to September 22, 2024 11:59 PM PST
  const isBannerVisible = useBannerVisibility(
    '2024-09-03T11:00:00Z',
    '2024-09-23T06:59:00Z'
  );

  return (
    <div className='upgrades-page'>
      <Container>
        <Grid container={true} spacing={2}>
          <AnimatePresence>
            {item.map((val) => {
              if (val.isSpecialBanner && !isBannerVisible) {
                return null;
              }

              return (
                <MotionUpgradeCard
                  key={val.id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  displayName={val.displayName}
                  logo={val.logoSrc}
                  subHeading={val.subHeading}
                  heading={val.heading}
                  body={val.body}
                  ctaTxt={val.ctaText}
                  ctaLink={val.ctaLink}
                  onClick={() => onClick(val)}
                  target={val.target}
                  featureImg={val.featureImg}
                  footnote={val.footnote}
                  colSpan={val.colSpan}
                  appIcon={val.appIcon}
                  appDescription={val.appDescription}
                  appType={val.appType}
                  txtAlign={val.styles.align}
                  jc={val.styles.jc}
                  txtColor={val.styles.txtColor}
                  backgroundDesktop={val.styles.bgDesktop}
                  backgroundMobile={val.styles.bgMobile}
                  customStyles={val.styles.customStyles}
                />
              );
            })}
          </AnimatePresence>
        </Grid>
      </Container>
    </div>
  );
};

export default Upgrades;
