import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useFeatureFlag, FLAGS } from 'lib/featureFlags';
import { object, string, bool } from 'prop-types';

const CollectionsRoute = ({ component, path, exact }) => {
  const showCollections = useFeatureFlag(FLAGS.SHOW_COLLECTIONS);

  return (
    <Route
      path={path}
      exact={exact}
      render={() => (showCollections ? component : <Redirect to='/' />)}
    />
  );
};

CollectionsRoute.propTypes = {
  component: object.isRequired,
  path: string.isRequired,
  exact: bool
};

CollectionsRoute.defaultProps = {
  exact: false
};

export default CollectionsRoute;
