import { useSelector } from 'react-redux';

export const useHeaderData = () => {
  const user = useSelector((state) => state.user);
  const header = useSelector((state) => state.header);
  const storeVersion = useSelector((state) => state.store.storeVersion);

  return {
    user,
    header,
    storeVersion
  };
};

export default useHeaderData;
