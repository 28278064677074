import React from 'react';
import { bool, element, func, object, string } from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import isEmpty from 'lodash/isEmpty';
import Accordion from './Accordion';
import './AppManagement.scss';
import VIEWPORT_SIZES from '../../constants/viewportSizes';
import MEDIA_TYPES from '../../constants/AppSettingsMediaTypes';

const Steps = ({ isMobile, hasMediaToShow, children }) => {
  return isMobile && hasMediaToShow ? (
    <Accordion title='Read instructions'>{children}</Accordion>
  ) : (
    <>{children}</>
  );
};
Steps.propTypes = {
  isMobile: bool.isRequired,
  hasMediaToShow: bool.isRequired,
  children: element.isRequired
};
/**
 * Renders Management and organization content for service settings pages.
 *
 * @return {object} JSX.Element
 * */
const AppManagement = ({
  title,
  subtitle,
  content,
  onRenderContentList: handleRenderContentList,
  ...other
}) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${VIEWPORT_SIZES.tabletMd}`
  });
  const hasMediaToShow = !isEmpty(content.media);
  const isMediaContentAVideo = content.media.type === MEDIA_TYPES.video;
  return (
    <div className='app-settings__general__service-management' {...other}>
      <h1 className='title'>{title}</h1>
      <p>{subtitle}</p>
      <div className='container'>
        {hasMediaToShow && (
          <div
            className={`content-container--${
              isMediaContentAVideo ? MEDIA_TYPES.video : MEDIA_TYPES.img
            }`}
          >
            {isMediaContentAVideo && (
              <div className='video-content'>
                <iframe
                  allowFullScreen='1'
                  title={content.media.title}
                  className='media-item--video'
                  src={content.media.url}
                />
              </div>
            )}
            {!isMediaContentAVideo && (
              <img
                className='media-item--img'
                src={content.media.url}
                title={content.media.title}
                alt={content.media.title}
              />
            )}
          </div>
        )}
        {isEmpty(content.list) ? null : (
          <Steps isMobile={isMobile} hasMediaToShow={hasMediaToShow}>
            <ol> {content.list.map(handleRenderContentList)} </ol>
          </Steps>
        )}
      </div>
    </div>
  );
};
AppManagement.propTypes = {
  title: string.isRequired,
  subtitle: string.isRequired,
  content: object.isRequired,
  onRenderContentList: func.isRequired
};
export default AppManagement;
