import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetInfringementCheck,
  setStorePreviewData,
  updateStore,
  updateThemeConfig,
  setPristine,
  setPersistedPreviewData
} from 'redux/actions';
import { isObjectEmpty, processValue } from 'lib/helpers';
import get from 'lodash/get';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';

export const HOME_FIELDS = {
  storeName: 'meta.storeName',
  socialIcons: 'brand.socialMedia'
};

export const EDITOR_FIELDS = {
  containerBg: 'content.heroBanner.containerBg',
  heroBgColor: 'styles.heroBanner.bgStyles.backgroundColor',
  heroTextColor: 'styles.heroBanner.textStyles.color',
  subtitle: 'content.heroBanner.subtitle',
  title: 'content.heroBanner.title',
  body: 'content.heroBanner.body',
  heroCtaLink: 'content.heroBanner.ctaLink',
  heroCtaText: 'content.heroBanner.ctaText',
  heroCtaNewWindow: 'content.heroBanner.ctaOpenInNewWindow',
  heroCtaBgColor: 'styles.heroBanner.ctaBtnStyles.backgroundColor',
  heroCtaTextColor: 'styles.heroBanner.ctaBtnStyles.color',
  logo: 'content.header.logo',
  headerBgColor: 'styles.header.bgStyles.backgroundColor',
  bannerTextColor: 'styles.header.textStyles.color',
  buttonBgColor: 'styles.button.primary.backgroundColor',
  buttonTextColor: 'styles.button.primary.color',
  footerSocial: 'content.footer.showSocialIcons',
  copyright: 'content.footer.copyright',
  footerAboutText: 'content.footer.about.textContent',
  footerBgColor: 'styles.footer.bgStyles.backgroundColor',
  footerTextColor: 'styles.footer.textStyles.color',
  headerSocial: 'content.header.showSocialIcons',
  heroTemplate: 'styles.heroBanner.template'
};

export const ALL_FIELDS = Object.values({ ...HOME_FIELDS, ...EDITOR_FIELDS });

export const usePreviewData = (formState = {}) => {
  const [isPublishing, setIsPublishing] = useState(false);
  const storePreviewData = useSelector(
    (state) => state.storePreviewData.previewData
  );
  const initialPreviewData = useSelector(
    (state) => state.storePreviewData.initialPreviewData
  );

  const store = useSelector((state) => state.store);
  const dispatch = useDispatch();
  const [themePayload, setThemePayload] = useState({});

  useEffect(() => {
    const newPreviewData = {};
    const formStateKeys = Object.keys(formState);

    if (formStateKeys.length > 0) {
      formStateKeys.forEach((key) => {
        let value = get(formState, `['${key}'].value`);
        value = processValue(value, store);
        newPreviewData[key] =
          value === null || value === undefined ? '' : value;
      });

      dispatch(setStorePreviewData(newPreviewData));
    }
  }, [formState, dispatch, store]);

  useEffect(() => {
    const updateTheme = async () => {
      dispatch(resetInfringementCheck());
      await dispatch(updateThemeConfig(get(store, 'slug'), themePayload));
      setTimeout(() => setIsPublishing(false), 1000);
    };
    if (store?.infringementCheckComplete && !isEmpty(themePayload)) {
      updateTheme();
    }
  }, [store?.infringementCheckComplete]);

  // Indicates that save is from general page and not the store editor
  const publishTheme = async (fields = ALL_FIELDS) => {
    setThemePayload({});
    const payload = {};
    const persistPayload = {};
    const previewDataKeys = Object.keys(storePreviewData);

    const cleanImage = (imgUrl) => {
      if (imgUrl.includes('media')) {
        const fileName = get(imgUrl.split('/media/'), '[1]');
        return fileName;
      }
      return imgUrl;
    };

    previewDataKeys
      // .filter(key => fieldsToUse.includes(key))
      .forEach((key) => {
        let value = storePreviewData[key];

        if (value !== initialPreviewData[key]) {
          if (key === EDITOR_FIELDS.logo || key === EDITOR_FIELDS.containerBg) {
            value = cleanImage(value);
          }
          if (fields.includes(key)) {
            set(payload, key, value);
          } else {
            persistPayload[key] = value;
          }
        }
      });

    if (!isEmpty(payload)) {
      setThemePayload(payload);
      setIsPublishing(true);
      if (!isEmpty(persistPayload)) {
        dispatch(setPersistedPreviewData(persistPayload));
      }
      await dispatch(
        updateStore({ title: get(payload, HOME_FIELDS.storeName) })
      );
    } else {
      dispatch(setPristine());
    }
  };

  return { storePreviewData, publishTheme, isPublishing };
};

export const useEditorFields = (initializeFormState) => {
  const themeConfig = useSelector(
    (state) => state.storePreviewData.themeConfig
  );
  const storePreviewData = useSelector(
    (state) => state.storePreviewData.previewData
  );
  const store = useSelector((state) => state.store);
  const isDirty = useSelector((state) => state.storePreviewData.isDirty);
  const isPreviewDataEmpty = isObjectEmpty(storePreviewData);
  const dataSrc = isPreviewDataEmpty ? themeConfig : storePreviewData;

  useEffect(() => {
    const initData = {};
    Object.values(ALL_FIELDS).forEach((key) => {
      let val = get(dataSrc, key, '');
      val = processValue(val, store);
      initData[key] = { value: val };
    });

    initializeFormState(initData);
  }, [themeConfig, isDirty, store]);
};
