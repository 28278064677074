import React from 'react';
import propTypes from 'prop-types';
import ListingAdminBarActions from '../ListingAdminBarActions';
import './ListingActionsModal.scss';

const ListingActionsModal = ({ actions, closeModal }) => {
  return (
    <div className='actionsmodal'>
      <ListingAdminBarActions actions={actions} showIcons={true} />

      <button
        className='actionsmodal__close'
        type='button'
        onClick={closeModal}
      >
        Cancel
      </button>
    </div>
  );
};

const { func, arrayOf, shape, string, node } = propTypes;
ListingActionsModal.propTypes = {
  closeModal: func,
  actions: arrayOf(
    shape({
      id: string,
      icon: string,
      url: string,
      label: string,
      content: node,
      routeTo: string
    })
  ).isRequired
};

ListingActionsModal.defaultProps = {
  closeModal: () => {}
};

export default ListingActionsModal;
