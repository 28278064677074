import React from 'react';
import { useLocation } from 'react-router-dom';
import { node } from 'prop-types';
import ProductViewSidebar from './ProductViewSidebar';
import './CollectionsWrapper.scss';

const CollectionsWrapper = ({ children }) => {
  const { pathname } = useLocation();
  const collectionsIndex = pathname === '/listings/collections';

  return (
    <div className='standard__layout'>
      <main
        className={`standard__layout__main ${
          collectionsIndex ? 'hide-aside' : ''
        }`}
      >
        {children}
      </main>

      {pathname.includes('products') && <ProductViewSidebar />}
    </div>
  );
};

CollectionsWrapper.propTypes = {
  children: node.isRequired
};

export default CollectionsWrapper;
