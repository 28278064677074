import { useEffect } from 'react';

const useScript = (scriptContext) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = scriptContext;
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [scriptContext]);
};

export default useScript;
