import { useEffect } from 'react';
import { shape, func, node } from 'prop-types';
import { withRouter } from 'react-router-dom';

export const ScrollToTop = ({ history, children }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return children;
};

ScrollToTop.propTypes = {
  history: shape({
    listen: func
  }).isRequired,
  children: node.isRequired
};

export default withRouter(ScrollToTop);
