import axios from 'axios';
import { generateClientToken } from 'lib/auth';
// import { formatAxiosRequestHeaders, isSubdomain } from 'lib/helpers';
import {
  formatAxiosRequestHeaders,
  isSubdomain,
  // updateBuildStatus,
  // getDomainsByUserAndStore,
  // updateDnsVerificationStatus,
  getDnsVerificationStatusForVerifyDomain,
  getUserStoreDomains,
  getNetlifyBuildStatus,
  getDnsVerificationStatus,
  getRegistrantVerificationStatus,
  deleteDomain
} from 'lib/helpers';
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import flatten from 'lodash/flatten';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import { loadStripe } from '@stripe/stripe-js';
import psl from 'psl';
import { useServiceAgreement } from 'hooks';
import {
  COMMERCE_API_V1_PATH,
  SERVERLESS_BASE_URL,
  STRIPE_PUBLISHABLE_KEY,
  GRAPHQL_API_HOST
} from '../../../constants';
import { addToast } from '../toast';

export const ADD_CUSTOM_DOMAIN = 'ADD_CUSTOM_DOMAIN';
export const DELETE_CUSTOM_DOMAIN = 'DELETE_CUSTOM_DOMAIN';
export const HIDE_SHOW_MORE = 'HIDE_SHOW_MORE';
export const IS_CHECKING_OUT = 'IS_CHECKING_OUT';
export const IS_CREATING_DOMAIN = 'IS_CREATING_DOMAIN';
export const IS_CREATING_DOMAIN_DONE = 'IS_CREATING_DOMAIN_DONE';
export const IS_FETCHING_DOMAINS = 'IS_FETCHING_DOMAINS';
export const IS_VERIFICATION_DONE = 'IS_VERIFICATION_DONE';
export const IS_VERIFICATION_LOADING = 'IS_VERIFICATION_LOADING';
export const LOAD_MORE_DOMAINS = 'LOAD_MORE_DOMAINS';
export const RECEIVE_CUSTOM_DOMAINS = 'RECEIVE_CUSTOM_DOMAINS';
export const RECEIVE_EMAIL_VERIFICATION_STATUS =
  'RECEIVE_EMAIL_VERIFICATION_STATUS';
export const RECEIVE_PURCHASABLE_DOMAINS = 'RECEIVE_PURCHASABLE_DOMAINS';
export const RECEIVE_VERIFICATION_STATUS = 'RECEIVE_VERIFICATION_STATUS';
export const REDIRECT_TO_STRIPE_CHECKOUT = 'REDIRECT_TO_STRIPE_CHECKOUT';
export const SET_CREATED_CUSTOM_DOMAIN = 'SET_CREATED_CUSTOM_DOMAIN';
export const SET_CUSTOM_DOMAIN_FB_PIXEL = 'SET_CUSTOM_DOMAIN_FB_PIXEL';
export const SHOW_PURCHASE_MODAL = 'SHOW_PURCHASE_MODAL';
export const TRANSFER_DOMAIN = 'TRANSFER_DOMAIN';

//helpers
const validateCheckoutPayload = (payload) => {
  return Object.keys(payload).reduce(
    (acc, curr) => {
      // address2 is not a required param. To optimize in the future we can put all non required fields in a constant array and check against that
      if (curr === 'address2') return acc;

      // checking if every value in the playload is not a empty string with spaces
      if (payload[curr].replace(/\s/g, '').length === 0) {
        acc.emptyFields.push(curr);
        acc.isValid = false;
      }
      return acc;
    },
    { emptyFields: [], isValid: true }
  );
};

const newSubscriptionServiceHeaders = (userId) => {
  const token = generateClientToken(userId);
  const bearer = `Bearer ${token}`;

  const headers = formatAxiosRequestHeaders(undefined, bearer);
  return headers;
};

const getPurchaseableDomains = async (domainUrl, offset = 0) => {
  const searchTerm = psl.parse(domainUrl).domain;
  const url = `${SERVERLESS_BASE_URL}/search-for-domain?searchTerm=${encodeURI(
    searchTerm
  )}&offset=${offset}`;

  const { data } = await axios.get(url);
  return data;
};

const searchForDomain = async (userId, query, offset = 0) => {
  if (isEmpty(query)) throw new Error('Invalid searchTerm');
  const res = await axios.post(
    GRAPHQL_API_HOST,
    {
      query: `
        query SearchDomainAvailability($offset: Float!, $query: String!) {
          searchDomainAvailability(offset: $offset, query: $query) {
            domain,
            available,
            price,
            suggestions {
              domain,
              price,
              available
            }
          }
        }
      `,
      variables: { query, offset }
    },
    formatAxiosRequestHeaders('', `Bearer ${generateClientToken(userId)}`)
  );
  return res.data;
};

const formatCustomDomainPayload = (customDomain, store) => {
  return {
    url: customDomain,
    storeId: store.id,
    primary: false
  };
};

const getDomainsForStore = async (
  storeId,
  token,
  newSubService = false,
  user = undefined
) => {
  if (newSubService) {
    return await getUserStoreDomains(user, storeId);
  } else {
    const url = `${COMMERCE_API_V1_PATH}/stores/${storeId}/domains`;
    const { data } = await axios.get(url, formatAxiosRequestHeaders(token));
    return data;
  }
};

const updateStoreDomain = async (storeId, domainId, userToken, updateData) => {
  const { data } = await axios.put(
    `${COMMERCE_API_V1_PATH}/stores/${storeId}/domains/${domainId}`,
    updateData,
    formatAxiosRequestHeaders(userToken)
  );
  return data;
};

// pure actions
export const addCustomDomain = (data, storeId) => ({
  type: ADD_CUSTOM_DOMAIN,
  data,
  storeId
});

export const creatingDomain = () => ({ type: IS_CREATING_DOMAIN });

export const creatingDomainComplete = () => ({ type: IS_CREATING_DOMAIN_DONE });

export const verificationComplete = () => ({ type: IS_VERIFICATION_DONE });

export const setIsCheckingOut = (isCheckingOut) => ({
  type: IS_CHECKING_OUT,
  isCheckingOut
});

export const toggleShowPurchaseDomainModal = (show) => ({
  type: SHOW_PURCHASE_MODAL,
  show
});

export const setCreatedCustomDomain = (createdDomain) => ({
  type: SET_CREATED_CUSTOM_DOMAIN,
  createdDomain
});

// thunked actions
export const deleteCustomDomain =
  ({
    domainId,
    netlifySiteId,
    storeId,
    trackDeleteSuccess,
    newSubService = false
  }) =>
  async (dispatch, getState) => {
    const { customDomain, user } = getState();
    const domainIndexInRedux = findIndex(customDomain.domains[storeId], [
      'id',
      domainId
    ]);
    try {
      if (newSubService) {
        await deleteDomain(domainId, storeId, netlifySiteId, user);
      } else {
        await axios.post(
          `${SERVERLESS_BASE_URL}/delete-site`,
          { domainId, netlifySiteId, storeId },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
      }

      dispatch({
        type: DELETE_CUSTOM_DOMAIN,
        domainIndex: domainIndexInRedux,
        storeId
      });
      trackDeleteSuccess();
      dispatch(addToast('Successfully deleted domain', 'success'));
    } catch (err) {
      dispatch(addToast(err?.response?.data?.message ?? err.message, 'danger'));
    }
  };

export const fetchPurchasableDomains =
  (domainUrl, offset = 0) =>
  async (dispatch, getState) => {
    if (offset === 0) {
      dispatch({ type: IS_FETCHING_DOMAINS, isFetching: true });
    } else {
      dispatch({ type: LOAD_MORE_DOMAINS, loading: true });
    }

    const { user } = getState();
    try {
      const { purchasableDomains } = getState().customDomain;

      const didSearchTextChange = purchasableDomains?.domain !== domainUrl;

      const {
        data: { searchDomainAvailability }
      } = await searchForDomain(user.userId, domainUrl, offset);
      const suggestions = get(searchDomainAvailability, 'suggestions', []).map(
        (s) => {
          s.price = (parseInt(s.price) / 100).toFixed(2);
          return s;
        }
      );

      // the api will always return 10 results each offset, we want to hide when we reached the end
      if (isEmpty(suggestions) || suggestions.length < 10)
        dispatch({ type: HIDE_SHOW_MORE });

      const payload =
        !isEmpty(purchasableDomains) && !didSearchTextChange
          ? purchasableDomains
          : searchDomainAvailability;

      if (offset > 0 && !didSearchTextChange)
        payload.suggestions = [
          ...payload.suggestions,
          ...searchDomainAvailability.suggestions
        ];

      dispatch({
        type: RECEIVE_PURCHASABLE_DOMAINS,
        data: payload
      });
    } catch (err) {
      dispatch(addToast(err?.response?.data?.message ?? err.message, 'danger'));
    } finally {
      dispatch({ type: IS_FETCHING_DOMAINS, isFetching: false });
      dispatch({ type: LOAD_MORE_DOMAINS, loading: false });
    }
  };

// might depreciate but will keep it we need to fetch all domains for every store again
export const fetchStoreCustomDomains =
  (stores) => async (dispatch, getState) => {
    try {
      const state = getState();
      const token = get(state, 'user.token', '');
      const domains = get(state, 'customDomain.domains');

      if (isEmpty(domains) && !isEmpty(stores)) {
        const promises = stores.map(async (store) => {
          const url = `${COMMERCE_API_V1_PATH}/stores/${store.id}/domains`;
          const { data } = await axios.get(
            url,
            formatAxiosRequestHeaders(token)
          );
          return data;
        });
        const res = flatten(await Promise.all(promises));
        dispatch({
          type: RECEIVE_CUSTOM_DOMAINS,
          data: res
        });
      }
    } catch (err) {
      dispatch(addToast(err?.response?.data?.message ?? err.message, 'danger'));
    }
  };

export const fetchCustomDomainEmailVerificationStatus =
  (storeId, newSubService = false, user = undefined) =>
  async (dispatch, getState) => {
    const { customDomain } = getState();
    const storeDomains = get(customDomain, `domains[${storeId}]`, []);
    const springRegisteredDomains = filter(
      storeDomains,
      (storeDomain) => storeDomain.is_registered_with_spring
    );

    if (isEmpty(springRegisteredDomains)) {
      return;
    }

    try {
      const promises = map(springRegisteredDomains, async (springDomain) => {
        const domainUrl = get(springDomain, 'url');
        const url = `${SERVERLESS_BASE_URL}/domains/verifications?domain=${domainUrl}`;
        const { data } = newSubService
          ? await getRegistrantVerificationStatus(domainUrl, user)
          : await axios.get(url, {
              headers: {
                'Content-Type': 'application/json'
              }
            });
        if (data.errors) {
          const errorMessage = data.errors[0].message;
          throw new Error(errorMessage);
        }
        return data;
      });
      const results = flatten(await Promise.all(promises));
      const resultsByDomainIndexInRedux = keyBy(results, (result) => {
        const domainIndexInRedux = findIndex(storeDomains, [
          'url',
          get(result, 'data.domain')
        ]);
        return domainIndexInRedux;
      });

      const updatedStoreDomainsState = map(
        storeDomains,
        (domain, indexInRedux) => {
          const result = get(
            resultsByDomainIndexInRedux,
            `[${indexInRedux}].data`,
            {}
          );
          if (isEmpty(result)) {
            return domain;
          }

          return {
            ...domain,
            email_verification: {
              status: get(result, 'email.code'),
              details: get(result, 'email.details')
            }
          };
        }
      );

      dispatch({
        type: RECEIVE_EMAIL_VERIFICATION_STATUS,
        data: updatedStoreDomainsState,
        storeId
      });
    } catch (err) {
      const errMessage = err?.response?.data?.message ?? err.message;
      dispatch(addToast(`Domain Email Verification: ${errMessage}`, 'danger'));
    }
  };

export const fetchCustomDomains =
  (storeId, newSubService = false, domainStatusSection = false) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const token = get(state, 'user.token', '');
      const user = get(state, 'user', undefined);
      if (storeId) {
        const domainsList = await getDomainsForStore(
          storeId,
          token,
          newSubService,
          user
        );
        const domainMap = new Map();
        for (const domain of domainsList) {
          domainMap.set(domain.url, domain);
        }
        const domainsIterator = domainMap.values();
        const uniqueDomains = [...domainsIterator];

        if (domainStatusSection && newSubService) {
          for (const domain of uniqueDomains) {
            // update netlify build status only if associated netlify_site_id is present
            if (domain.netlify_site_id) {
              const netlify_status = await getNetlifyBuildStatus(
                domain.netlify_site_id,
                user
              );
              domain.netlify_build_status = netlify_status;
            }
            const dns_status = await getDnsVerificationStatus(domain.url, user);
            domain.dns_verification_status = dns_status;
          }
        }
        dispatch({
          type: RECEIVE_CUSTOM_DOMAINS,
          data: uniqueDomains,
          storeId
        });
        dispatch(
          fetchCustomDomainEmailVerificationStatus(storeId, newSubService, user)
        );
      }
    } catch (err) {
      dispatch(addToast(err?.response?.data?.message ?? err.message, 'danger'));
    }
  };

export const redirectToCheckout = (payload) => async (dispatch, getState) => {
  try {
    dispatch(setIsCheckingOut(true));

    const { user, store } = getState();
    const { emptyFields, isValid } = validateCheckoutPayload(payload);

    // check for address is empty of not
    if (!isValid) {
      const errorMessage = emptyFields.reduce((acc, curr) => {
        acc += ` ${curr},`;
        return acc;
      }, 'The following fields cannot be empty: \n');

      setTimeout(() => dispatch(setIsCheckingOut(false)), 2000);
      return dispatch(addToast(errorMessage, 'danger'));
    }
    const { legalNames, signAgreement } = useServiceAgreement(user);
    const res = await signAgreement(legalNames.DOMAIN_PURCHASE_AGREEMENT);
    if (res.status < 399) {
      const storeName = `${store.slug}-${payload.domainName}`.replace(
        /\./g,
        '-'
      );

      const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);
      const { data } = await axios.post(
        `${SERVERLESS_BASE_URL}/domain-checkout-session`,
        {
          ...payload,
          userId: +user.userId,
          storeId: store.id,
          storeName
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      const result = await stripe.redirectToCheckout({
        sessionId: data.id
      });

      if (result?.error) {
        throw result.error;
      }

      dispatch({
        type: REDIRECT_TO_STRIPE_CHECKOUT,
        data
      });
    }
  } catch (err) {
    dispatch(addToast(err?.response?.data?.message ?? err.message, 'danger'));
  } finally {
    setTimeout(() => dispatch(setIsCheckingOut(false)), 2000);
  }
};

export const retriggerCustomDomainEmailVerification =
  (domainUrl) => async (dispatch) => {
    try {
      const url = `${SERVERLESS_BASE_URL}/domains/verifications/email`;
      const { data } = await axios.post(
        url,
        { domain: domainUrl },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      dispatch(addToast(`${get(data, 'status')}`, 'success'));
    } catch (err) {
      dispatch(addToast(err?.response?.data?.message ?? err.message, 'danger'));
    }
  };

export const setCustomDomainFbPixel =
  ({ domainId, storeId, txtValue }) =>
  async (dispatch, getState) => {
    try {
      const { customDomain, store, user } = getState();
      const domainIndexInRedux = findIndex(customDomain.domains[storeId], [
        'id',
        domainId
      ]);
      const userToken = get(user, 'token', '');
      const removeStoreSlugFromDomainUrl = (slug, url) =>
        (url ?? '').startsWith(`${slug}-`) ? url.split(`${slug}-`)[1] : url;

      const domainName = removeStoreSlugFromDomainUrl(
        store.slug,
        get(customDomain, `domains[${storeId}][${domainIndexInRedux}].url`, '')
      );
      const data = await updateStoreDomain(storeId, domainId, userToken, {
        facebook_pixel_verification_code: txtValue
      });

      await axios.put(
        `${SERVERLESS_BASE_URL}/update-dns`,
        {
          domain: domainName,
          records: [
            {
              host: '@',
              text: txtValue
            }
          ]
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      dispatch({
        type: SET_CUSTOM_DOMAIN_FB_PIXEL,
        data,
        storeId,
        domainIndex: domainIndexInRedux
      });
      dispatch(addToast('Successfully added Facebook TXT record', 'success'));
    } catch (err) {
      dispatch(addToast(err?.response?.data?.message ?? err.message, 'danger'));
    }
  };

export const verifyDomain =
  (payload, newSubService = false) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: IS_VERIFICATION_LOADING });

      const { customDomain, user } = getState();
      const customDomains = cloneDeep(
        get(customDomain.domains, payload.storeId, [])
      );
      const domain = find(customDomains, ['id', payload.domainId]);
      const { header } = getState();
      const userEmail = get(header, 'user.email');

      if (newSubService) {
        domain.dns_verification_status =
          await getDnsVerificationStatusForVerifyDomain(
            payload.hostname,
            payload.buildSite,
            payload.storeId,
            payload.storeName, //siteName
            user.userId,
            userEmail
          );
      } else {
        const { data } = await axios.post(
          `${SERVERLESS_BASE_URL}/verifydomain`,
          { ...payload },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );

        forEach(data.results, (result) => {
          if (result.type === 'A') {
            domain.apex_dns_verification_status = result.success;
          } else if (result.type === 'CNAME') {
            domain.cname_dns_verification_status = result.success;
          }
        });

        let dnsIsVerified = false;

        if (isSubdomain(domain.url)) {
          dnsIsVerified = domain.cname_dns_verification_status;
        } else {
          dnsIsVerified =
            domain.cname_dns_verification_status &&
            domain.apex_dns_verification_status;
        }

        domain.dns_verification_status = dnsIsVerified
          ? 'dns_success'
          : 'dns_failed';
      }

      dispatch({
        type: RECEIVE_VERIFICATION_STATUS,
        data: customDomains,
        storeId: payload.storeId
      });
      setTimeout(() => {
        dispatch(verificationComplete());
      }, 200);
    } catch (err) {
      dispatch(addToast(err?.response?.data?.message ?? err.message, 'danger'));
      dispatch(verificationComplete());
    }
  };

export const createCustomDomain =
  (domainUrl, newSubService = false) =>
  async (dispatch, getState) => {
    try {
      dispatch(creatingDomain());
      const { store, user } = getState();
      const domain = newSubService
        ? await searchForDomain(user.id, domainUrl)
        : await getPurchaseableDomains(domainUrl);
      dispatch({
        type: RECEIVE_PURCHASABLE_DOMAINS,
        data: newSubService ? domain.data.searchDomainAvailability : domain
      });
      if (domain.available) {
        dispatch(toggleShowPurchaseDomainModal(true));
        return dispatch(creatingDomainComplete());
      } else {
        const payload = {
          query: `mutation UpdateUserSubscriptionDataWithDomain($userId: String!, $storeId: String!, $primary: String!, $url: String!) {
          updateUserSubscriptionDataWithDomain(userId: $userId, storeId: $storeId, primary: $primary, url: $url) {
            dns_verification_status
            facebook_pixel_verification_code
            id
            is_registered_with_spring
            netlify_build_error
            netlify_build_status
            netlify_site_id
            primary
            status
            store_id
            url
            user_id
          }
        }
        `,
          variables: {
            userId: `${user.userId}`,
            storeId: `${store.id}`,
            primary: '',
            url: domainUrl
          },
          operationName: 'UpdateUserSubscriptionDataWithDomain'
        };

        const headers = newSubscriptionServiceHeaders(user.userId);

        const { data } = newSubService
          ? await axios.post(GRAPHQL_API_HOST, payload, headers)
          : await axios.post(
              `${COMMERCE_API_V1_PATH}/stores/${store.id}/domains`,
              formatCustomDomainPayload(domainUrl, store),
              formatAxiosRequestHeaders(user.token)
            );

        if (data.errors) {
          throw new Error(data.errors[0].message ?? 'Error adding domain');
        }
        const storeData = newSubService
          ? data.data.updateUserSubscriptionDataWithDomain
          : data;

        dispatch(addCustomDomain(storeData, store.id));
        dispatch(setCreatedCustomDomain(storeData));

        setTimeout(() => {
          dispatch(creatingDomainComplete());
        }, 200);
      }
    } catch (err) {
      dispatch(addToast(err?.response?.data?.message ?? err.message, 'danger'));
      dispatch(creatingDomainComplete());
    }
  };

export const transferDomain =
  (domainId, domainName, newStoreId, userId, netlifySiteId, siteName) =>
  async (dispatch, getState) => {
    try {
      const { domains } = getState().customDomain;
      const { id: storeId } = getState().store;
      const storeDomains = [...domains[storeId]];

      await axios.put(`${SERVERLESS_BASE_URL}/domains/transfer`, {
        domainId,
        domainName,
        toStoreId: newStoreId,
        userId,
        netlifySiteId,
        siteName
      });

      const updated = storeDomains.filter((domain) => domain.id !== domainId);

      dispatch({ type: TRANSFER_DOMAIN, data: updated, storeId });
      dispatch(addToast('Domain transferred', 'success'));
    } catch (err) {
      dispatch(addToast(err?.response?.data?.message ?? err.message, 'danger'));
    }
  };

export const updatePrimaryDomain =
  (storeId, domainId) => async (dispatch, getState) => {
    try {
      const { user } = getState();
      const userToken = get(user, 'token', '');
      await updateStoreDomain(storeId, domainId, userToken, {
        primary: true
      });
      const updatedStoreDomains = await getDomainsForStore(storeId, userToken);
      dispatch({
        type: RECEIVE_CUSTOM_DOMAINS,
        storeId,
        data: updatedStoreDomains
      });
      dispatch(addToast('Primary domain updated!', 'success'));
    } catch (err) {
      dispatch(addToast(err?.response?.data?.message ?? err.message, 'danger'));
    }
  };
