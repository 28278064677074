import { bool, object } from 'prop-types';
import { Card, Button } from '@mui/material';
import './integrations.scss';
import { useAmplitude } from 'hooks';
import { useHistory } from 'react-router-dom';

const IntegrationCard = (props) => {
  const { logEvent } = useAmplitude();
  const navigate = useHistory();
  const { social, connected } = props;
  const isStoreDrop = social.name === 'Store drop';

  const IntegrationTitle = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <img
          src={social.logo}
          alt={social.name}
          className='integrations_card_img'
        />
        <div className='integrations_card_name'>{social.name}</div>
      </div>
    );
  };
  const GreenDot = () => <div className='integrations_green_dot' />;
  const ConnectButton = () => {
    return (
      <Button
        onClick={() => {
          logEvent('button.clicked', {
            journey: 'dashboard',
            feature: 'integrations',
            page: 'all integrations',
            button: 'social tile',
            cta: social.name,
            state: connected
          });
          navigate.push(social.href);
        }}
        sx={{
          cursor: 'pointer',
          background: connected ? '#F2F2F2' : '#FFFFFF',
          border: connected ? 'solid 1px #F2F2F2' : 'solid 1px #d9d9d9',
          width: '100%',
          height: '40px',
          borderRadius: '12px',
          textTransform: 'none !important',
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: connected ? 400 : 500,
          fontSize: connected ? '12px' : '14px',
          lineHeight: '18px',
          letterSpacing: '-0.01em',
          color: connected ? '#404040' : '#1954EB',
          '&:hover': {
            background: '#F2F2F2'
          }
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {connected ? <GreenDot /> : <></>}
          {connected && !isStoreDrop && 'Connected'}
          {!connected && !isStoreDrop && 'Add'}
          {isStoreDrop && 'Create'}
        </div>
      </Button>
    );
  };
  const IntegrationContent = () => {
    return (
      <div
        style={{
          margin: '12px 0',
          color: '#404040',
          fontFamily: 'Inter',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '21px',
          letterSpacing: '-0.01em'
        }}
        dangerouslySetInnerHTML={{ __html: social.text }}
      />
    );
  };
  return (
    <Card
      elevation={0}
      className='integration_card'
      sx={{ borderRadius: '8px', cursor: 'pointer' }}
      onClick={() => {
        logEvent('button.clicked', {
          journey: 'dashboard',
          feature: 'integrations',
          page: 'all integrations',
          button: 'social tile',
          cta: social.name,
          state: connected
        });
        navigate.push(social.href);
      }}
    >
      <IntegrationTitle />
      <IntegrationContent />
      <ConnectButton />
    </Card>
  );
};

IntegrationCard.propTypes = {
  social: object.isRequired,
  connected: bool.isRequired
};

export default IntegrationCard;
