import axios from 'axios';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import { API_HOST, FEATURE_FLAG_NAMES } from '../../../constants';
import { addToast } from '../toast';

export const SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_UNAUTHORIZED_USER = 'SET_UNAUTHORIZED_USER';
export const SET_USER_STORE_NOT_FOUND = 'SET_USER_STORE_NOT_FOUND';

const HEADERS = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
};

export const setFeatureFlags = (featureFlags) => {
  return {
    type: SET_FEATURE_FLAGS,
    payload: featureFlags
  };
};

export const getFeatureFlagsForLargeGroupOfUsers =
  () => async (dispatch, getState) => {
    const state = getState();
    const userId = get(state, 'user.userId', 0);
    const featuresUrl = `${API_HOST}/api/v1/features_for_large_groups`;

    if (!userId)
      return dispatch(
        addToast('User information not setup properly.', 'danger')
      );

    try {
      const { data } = await axios.get(featuresUrl, {
        params: {
          userId,
          names: encodeURIComponent(JSON.stringify(FEATURE_FLAG_NAMES))
        },
        ...HEADERS
      });

      if (data.length) {
        const featureFlags = {};
        data.forEach((e) => {
          featureFlags[camelCase(e.feature)] = e.active;
        });

        dispatch(setFeatureFlags(featureFlags));
      }
    } catch (err) {
      dispatch(
        addToast(
          `Error getting feature flags: ${
            err?.response?.data?.message ?? err.message
          }`,
          'danger'
        )
      );
    }
  };

export const setUserId = (userId) => {
  return {
    type: SET_USER_ID,
    payload: userId
  };
};

export const setToken = (token) => {
  return {
    type: SET_TOKEN,
    payload: token
  };
};

export const setUnauthorizedUser = (isUnauthorizedUser) => {
  return {
    type: SET_UNAUTHORIZED_USER,
    isUnauthorizedUser
  };
};

export const setUserStoreNotFound = (isUserStoreNotFound) => {
  return {
    type: SET_USER_STORE_NOT_FOUND,
    isUserStoreNotFound
  };
};
