import { Menu, MenuItem } from '@mui/material';
import { ModalConductor } from '@teespring/ts-components';
import { bool } from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { clearModalState, setActiveModal } from 'redux/actions';
import '../../integrations.scss';
import IntegrationDisconnectModal, {
  INTEGRATION_DISCONNECT_MODAL_ID
} from '../IntegrationsDosconnectModal/IntegrationDisconnectModal';
import PLATFORM_INTEGRATIONS from '../../../../constants/platformIntegrations';

const GreenDot = () => <div className='integrations_green_dot' />;
const EmptyDot = () => <div className='integrations_empty_dot' />;
const contStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer'
};
const fontStyle = {
  margin: '0px',
  fontStyle: 'Inter',
  fontWeight: 400,
  fontSize: '12px'
};

export const IntegrationsPageConnected = ({ connected }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { integrationSlug } = useParams();
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openDisconectModal = () => {
    handleClose();
    dispatch(setActiveModal({ id: INTEGRATION_DISCONNECT_MODAL_ID }));
  };

  const { modal: modalState } = useSelector((state) => state.modal);
  const closeModal = () => {
    dispatch(clearModalState());
  };

  const shouldShowConnectionStatus = (platform) => {
    return !Object.values(PLATFORM_INTEGRATIONS).includes(platform);
  };
  return (
    <>
      {shouldShowConnectionStatus(integrationSlug) && (
        <>
          <div
            id='connected-div'
            aria-controls={open ? 'integration-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            // onClick={handleClick}
          >
            {connected ? (
              <div style={contStyle}>
                <GreenDot />
                <span style={fontStyle}>Connected</span>
              </div>
            ) : (
              <div style={contStyle}>
                <EmptyDot />
                <span style={fontStyle}>Not Connected</span>
              </div>
            )}
          </div>
          <Menu
            id='integration-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                borderRadius: '8px',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px'
              }
            }}
            MenuListProps={{
              'aria-labelledby': 'connected-div'
            }}
          >
            <MenuItem
              sx={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px'
              }}
              onClick={handleClose}
            >
              Support
            </MenuItem>
            <MenuItem
              sx={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                color: '#DF0403'
              }}
              onClick={openDisconectModal}
            >
              Disconnect from Instagram
            </MenuItem>
            <MenuItem
              sx={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                color: '#DF0403'
              }}
              onClick={handleClose}
            >
              Disconnect from platform
            </MenuItem>
          </Menu>
          <ModalConductor
            activeModal={modalState}
            setActiveModal={(data) => dispatch(setActiveModal(data))}
            hideCloseBtn={true}
            onClose={closeModal}
            modals={[
              {
                id: INTEGRATION_DISCONNECT_MODAL_ID,
                node: <IntegrationDisconnectModal handleClose={closeModal} />
              }
            ]}
          />
        </>
      )}
      {integrationSlug === 'beacons' && (
        <div style={contStyle}>
          <span>See setup steps below</span>
        </div>
      )}
    </>
  );
};

IntegrationsPageConnected.propTypes = {
  connected: bool.isRequired
};

export default IntegrationsPageConnected;
