import { object } from 'prop-types';
import '../IntegrationsPage.scss';

export const IntegrationsPageStepCard = ({ step }) => {
  return (
    <div className='integrations_page_step_card'>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        {(step.step || step.icon) && (
          <div className='integrations_step_circle'>
            {step.step ? step.step : step.icon}
          </div>
        )}

        {step.text && (
          <div>
            <div
              className='integrations_page_step_text'
              dangerouslySetInnerHTML={{ __html: step.text }}
            />
            {step.note && (
              <div className='integrations_page_step_note'>
                <span className='integrations_page_step_note_title'>Note:</span>
                <div dangerouslySetInnerHTML={{ __html: step.note }} />
              </div>
            )}
          </div>
        )}
      </div>

      {step.render}
    </div>
  );
};

IntegrationsPageStepCard.propTypes = {
  step: object.isRequired
};

export default IntegrationsPageStepCard;
