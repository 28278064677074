import React, { useCallback, useEffect, useMemo } from 'react';
import { map, get, every, isEmpty, forEach, filter, some, find } from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Loader, Icon } from '@teespring/ts-components';
import classnames from 'classnames';
import {
  fetchCollectionProducts,
  selectProduct,
  selectAllProducts,
  selectSearchResult,
  addCollectionItem,
  selectAllSearchResults
} from 'redux/actions';
import { useTypeahead, useAmplitude, useCollection } from 'hooks';
import { DASHBOARD_COLLECTIONS_EVENT } from 'lib/tracking';
import { pluralize } from 'lib/helpers';
import { usePrevious } from 'lib/hooks/helpers';
import SaveBar from 'components/SaveBar';
import CollectionProductItem from '../CollectionDetail/CollectionProductItem';
import ReturnLink from '../ReturnLink';
import ItemSelectionHeader from '../CollectionDetail/ItemSelectionHeader';
import { SPRING_BASE_URL } from '../../../constants';
import './ProductPicker.scss';

const ProductPicker = () => {
  const { collectionId } = useParams();
  const { loading, search } = useTypeahead(true);
  const { logEvent } = useAmplitude();
  const dispatch = useDispatch();
  const { collection } = useCollection(collectionId);
  const history = useHistory();

  const {
    products,
    isFetching,
    hideLoadMoreBtn,
    isDisplayingSearchResults,
    searchResults,
    isLoading,
    pageNum
  } = useSelector((state) => state.collectionProducts);

  const isChecked = (listingProducts) => {
    if (isEmpty(listingProducts)) return false;
    return every(listingProducts, (product) => product.isChecked);
  };

  const getCollectionProducts = useCallback(
    async (page) => {
      await dispatch(fetchCollectionProducts(page));
    },
    [dispatch]
  );

  useEffect(() => {
    if (pageNum === 1 && isEmpty(products)) {
      getCollectionProducts();
    }
  }, [getCollectionProducts, pageNum, products]);

  const filteredProducts = useMemo(() => {
    return filter(
      products,
      (product) =>
        !some(
          collection?.collectionItems,
          (item) => get(item, 'external_id') === get(product, 'listingId')
        )
    );
  }, [products, collection]);

  const expandedSearchResults = useMemo(() => {
    if (isEmpty(searchResults)) return [];

    return map(searchResults, (result) => {
      const product = find(
        filteredProducts,
        (p) => get(p, 'listingId') === get(result, 'listingId')
      );

      return {
        ...result,
        products: get(product, 'products', [])
      };
    });
  }, [searchResults, filteredProducts]);

  const displayProducts = !isEmpty(expandedSearchResults)
    ? expandedSearchResults
    : filteredProducts;

  const checkedProducts = (displayProducts ?? []).reduce((acc, curr) => {
    const prods = get(curr, 'products', []);

    forEach(prods, (variation) => {
      if (get(variation, 'isChecked', false)) acc.push(variation);
    });

    if (isEmpty(prods) && get(curr, 'isChecked', false)) acc.push(curr);

    return acc;
  }, []);

  const prevIsLoading = usePrevious(isLoading);

  useEffect(() => {
    // Trigger a redirect back to the collection detail page when the following
    // criteria are met -- displayProducts is less than products, and isLoading is
    // currently false, but was true during the last update
    if (
      displayProducts.length < products.length &&
      !isLoading &&
      prevIsLoading
    ) {
      history.push(`/listings/collections/${collectionId}`);
    }
  }, [
    displayProducts,
    products,
    isLoading,
    collectionId,
    history,
    prevIsLoading
  ]);

  const isDeselect = !isChecked(displayProducts) && !isEmpty(checkedProducts);

  const handleCheckAll = () => {
    return isDisplayingSearchResults
      ? dispatch(
          selectAllSearchResults(isDeselect ? true : isChecked(displayProducts))
        )
      : dispatch(
          selectAllProducts(isDeselect ? true : isChecked(displayProducts))
        );
  };

  const handleCheck = (product) => {
    return isDisplayingSearchResults
      ? dispatch(selectSearchResult(product.listingId))
      : dispatch(
          selectProduct(
            product.listingId,
            !isEmpty(get(product, 'products', [])),
            false
          )
        );
  };

  const uncheckAll = () => dispatch(selectAllProducts(pageNum, true));

  const onAddProducts = () => {
    dispatch(addCollectionItem(checkedProducts, collectionId));
    uncheckAll();
  };

  const handleShowMoreProducts = () => {
    logEvent(
      `${DASHBOARD_COLLECTIONS_EVENT}_product_picker_show_more_products.clicked`
    );

    getCollectionProducts(pageNum + 1);
  };

  const numItems = displayProducts.reduce((total, product) => {
    return product?.products?.length >= 1
      ? (total += product.products.length)
      : (total += 1);
  }, 0);

  return (
    <>
      <ReturnLink
        path={`/listings/collections/${collectionId}`}
        sectionName={`${collection?.name ?? ''}: products`}
      />
      <div className='collection__header layout--flex baseline'>
        <h1>Product Picker</h1>
        <span>{numItems} products</span>
      </div>

      <div className='search_container'>
        <Icon name='Search' />
        <input
          placeholder='Search products'
          onChange={(e) => search(e.target.value)}
          onFocus={() =>
            logEvent(
              `${DASHBOARD_COLLECTIONS_EVENT}_product_picker_search_products.clicked`
            )
          }
        />
      </div>

      <hr className='form__divider form__divider--light mb4' />

      <div className='collection__productfeed__container'>
        <ItemSelectionHeader
          className='product_picker__header'
          isDeselect={isDeselect}
          isChecked={isChecked(displayProducts)}
          title='Title'
          showDelete={false}
          numCheckedItems={checkedProducts.length}
          handleCheckAll={handleCheckAll}
        />

        <div
          className={classnames(
            'collection__productfeed product_picker__feed',
            {
              'collection__productfeed--padded-bottom':
                !isEmpty(checkedProducts)
            }
          )}
        >
          {!isFetching && !isLoading && isEmpty(products) && (
            <div className='product_picker__noproducts row'>
              <h2 className='mb1'>No products found</h2>
              <p className='mb2'>Start by creating more products</p>
              <a className='btn' href={`${SPRING_BASE_URL}/design-launcher`}>
                Create a product
              </a>
            </div>
          )}

          {loading || (isFetching && isEmpty(products)) ? (
            <Loader />
          ) : (
            <>
              {map(displayProducts, (product) => (
                <CollectionProductItem
                  key={product.listingId}
                  product={product}
                  checked={get(product, 'isChecked', false)}
                  onChange={() => handleCheck(product)}
                />
              ))}
              <div className='collection__product__load__more'>
                {isFetching && !isEmpty(products) ? (
                  <Loader />
                ) : (
                  <>
                    {!hideLoadMoreBtn && !isDisplayingSearchResults && (
                      <button
                        type='button'
                        className='btn btn--transparent'
                        onClick={handleShowMoreProducts}
                        disabled={hideLoadMoreBtn}
                      >
                        Show more products
                      </button>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <SaveBar
        isDirty={!isEmpty(checkedProducts)}
        message={`Add ${pluralize(checkedProducts.length, 'product')} to ${get(
          collection,
          'name'
        )}`}
        onDiscard={uncheckAll}
        cancelBtn='Cancel'
        saveBtn='Add'
        onSave={onAddProducts}
        isLoading={isLoading}
      />
    </>
  );
};

export default ProductPicker;
