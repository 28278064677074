export const MODIFY_PRODUCT_AMOUNT = 'MODIFY_PRODUCT_AMOUNT';
export const MODIFY_LAYOUT = 'MODIFY_LAYOUT';
export const MODIFY_THEME = 'MODIFY_THEME';

export const modifyProductAmount = (productAmount) => {
  return {
    type: MODIFY_PRODUCT_AMOUNT,
    payload: productAmount
  };
};

export const modifyLayout = (layout) => {
  return {
    type: MODIFY_LAYOUT,
    payload: layout
  };
};

export const modifyTheme = (theme) => {
  return {
    type: MODIFY_THEME,
    payload: theme
  };
};
