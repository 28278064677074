import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSubscriptionService } from 'redux/actions';
import get from 'lodash/get';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { generateClientToken } from 'lib/auth';
import { formatAxiosRequestHeaders } from 'lib/helpers';

const useSubscriptionService = (serviceId) => {
  const dispatch = useDispatch();
  const { services } = useSelector((state) => state.subscriptions);
  const subscriptionService = get(services, serviceId, {});
  const { userId } = useSelector((state) => state.user);
  const { newSubServiceGetToolsPageMetadata } = useFlags();

  const token = generateClientToken(userId);
  const bearer = `Bearer ${token}`;

  const headers = formatAxiosRequestHeaders(undefined, bearer);

  useEffect(() => {
    const getSubscriptionService = async () => {
      await dispatch(
        fetchSubscriptionService(
          serviceId,
          headers,
          newSubServiceGetToolsPageMetadata
        )
      );
    };
    getSubscriptionService();
  }, [serviceId, dispatch]);

  return { ...subscriptionService };
};

export default useSubscriptionService;
