import { Button } from '@mui/material';
import { func } from 'prop-types';
import '../IntegrationsPage.scss';

export const IntegrationsPageSetup = ({ scrollTo }) => {
  return (
    <div className='integrations_page_sticky'>
      <Button
        onClick={() => scrollTo()}
        className='integrations_page_setup_btn'
      >
        Setup
      </Button>
    </div>
  );
};

IntegrationsPageSetup.propTypes = {
  scrollTo: func.isRequired
};

export default IntegrationsPageSetup;
