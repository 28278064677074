import React, { useState } from 'react';
import './Accordion.scss';
import { element, string } from 'prop-types';

const Accordion = ({ children, title }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const ArrowUp = (
    <svg
      width='16'
      height='10'
      viewBox='0 0 16 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.3615 9.33911C15.1768 9.55363 14.8773 9.55363 14.6926 9.33911L8.04828 1.6222C8.04201 1.6149 8.03414 1.60861 8.02596 1.60467C8.01776 1.60072 8.00889 1.59865 8.00002 1.59865C7.99113 1.59865 7.98234 1.60068 7.97414 1.60464C7.96596 1.60858 7.95845 1.61439 7.95219 1.62168L1.30741 9.33911C1.12271 9.55363 0.823238 9.55363 0.638531 9.3391C0.453824 9.12458 0.453824 8.77677 0.638532 8.56224L7.28242 0.845846C7.37659 0.736196 7.48846 0.649205 7.61163 0.589866C7.73474 0.530539 7.86672 0.5 8.00002 0.5C8.13331 0.5 8.26529 0.53054 8.3884 0.589866C8.51149 0.649167 8.62329 0.736081 8.71741 0.845625C8.71747 0.845699 8.71735 0.845551 8.71741 0.845625L15.3615 8.56224C15.5462 8.77677 15.5462 9.12458 15.3615 9.33911Z'
        fill='#08080D'
      />
    </svg>
  );
  const ArrowDown = (
    <svg
      width='16'
      height='10'
      viewBox='0 0 16 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.63853 0.660893C0.823238 0.446369 1.12271 0.446369 1.30741 0.660894L7.95172 8.3778C7.95799 8.38509 7.96586 8.39139 7.97404 8.39533C7.98224 8.39928 7.99111 8.40135 7.99998 8.40135C8.00887 8.40135 8.01766 8.39932 8.02586 8.39536C8.03404 8.39142 8.04155 8.3856 8.04781 8.37831L14.6926 0.660893C14.8773 0.446369 15.1768 0.446369 15.3615 0.660894C15.5462 0.875419 15.5462 1.22323 15.3615 1.43776L8.71758 9.15415C8.62341 9.2638 8.51154 9.35079 8.38837 9.41013C8.26526 9.46946 8.13328 9.5 7.99998 9.5C7.86669 9.5 7.73471 9.46946 7.6116 9.41013C7.48851 9.35083 7.37671 9.26392 7.28259 9.15437C7.28253 9.1543 7.28265 9.15445 7.28259 9.15437L0.63853 1.43776C0.453823 1.22323 0.453823 0.875418 0.63853 0.660893Z'
        fill='#08080D'
      />
    </svg>
  );
  const handleArrowButtonClick = () => setIsExpanded(!isExpanded);

  return (
    <div>
      <div className='accordion-title'>
        <span className='accordion-title__text'>{title}</span>
        <button type='button' onClick={handleArrowButtonClick}>
          {isExpanded ? ArrowUp : ArrowDown}
        </button>
      </div>
      {isExpanded && (
        <div className='accordion-items'>
          <ol>{children}</ol>
        </div>
      )}
    </div>
  );
};

Accordion.propTypes = {
  children: element.isRequired,
  title: string.isRequired
};

export default Accordion;
