/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import { useMemo } from 'react';
import amplitude from 'amplitude-js';
import { IS_PRODUCTION } from '../constants';

export const useAmplitude = () => {
  /**
   * Logs an amplitude event.
   *
   * @param {string} event The event name.
   * @param {Object} params The event parameters.
   * @param {*} context The event context.
   */
  const logEvent = useMemo(() => {
    return (event, params, context) => {
      // To enable Amplitude testing locally, add `|| true` in this if check
      if (IS_PRODUCTION) {
        const tracker = amplitude.getInstance();
        setTimeout(() => {
          const initialized = tracker._isInitialized;
          return initialized
            ? tracker.logEvent(event, { ...params, ...context })
            : // maybe we should display a toast?
              console.error(`Amplitude: Failed to log event ${event}`);
        });
      } else {
        // just log event in the console
        console.log(`Amplitude Event: ${event}`);
        console.log(`Amplitude Event Params: ${JSON.stringify(params)}`);
      }
    };
  }, []);

  const init = useMemo(() => {
    return () => {
      amplitude
        .getInstance()
        .init(process.env.REACT_APP_AMPLITUDE_API_KEY, null, {
          saveEvents: true,
          includeUtm: true,
          includeReferrer: true,
          includeGclid: true
        });
      setTimeout(() => {
        if (!amplitude.getInstance()._isInitialized) {
          console.error('Amplitude: Failed to initialize');
        }
      });
    };
  }, []);

  return {
    logEvent,
    init
  };
};

export default useAmplitude;
