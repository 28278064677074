import React from 'react';
import { useSelector } from 'react-redux';
import { func, bool, string } from 'prop-types';
import { LoaderButton, bpProps } from '@teespring/ts-components';
import classnames from 'classnames';
import './SaveBar.scss';

const SaveBar = (props) => {
  const {
    onSave,
    isLoading,
    onDiscard,
    isDirty,
    message,
    cancelBtn,
    saveBtn,
    isDisabled
  } = props;

  const { bpIsGT } = useSelector((state) => ({ ...bpProps(state) }));

  return (
    <>
      {(isDirty || isLoading) && (
        <div
          className={classnames({
            savebar: true,
            'savebar--disabled': isDisabled
          })}
        >
          {bpIsGT('mobileLg') && <div className='mr1'>{message}</div>}
          <div className='savebar-actions'>
            <button
              className={classnames({
                'savebar-actions-discard': true,
                'btn--disabled': isLoading,
                mr1: true
              })}
              onClick={onDiscard}
              type='button'
            >
              {cancelBtn}
            </button>
            <LoaderButton
              className={classnames({
                'savebar-actions-save': true,
                'btn--disabled': isDisabled
              })}
              type='button'
              onClick={onSave}
              loading={isLoading}
              text={saveBtn}
              loadingText='Saving...'
              disabled={isDisabled}
            />
          </div>
        </div>
      )}
    </>
  );
};

SaveBar.propTypes = {
  onSave: func.isRequired,
  isLoading: bool.isRequired,
  onDiscard: func.isRequired,
  isDirty: bool,
  message: string,
  cancelBtn: string,
  saveBtn: string,
  isDisabled: bool
};

SaveBar.defaultProps = {
  message: 'You have unsaved changes',
  cancelBtn: 'Discard changes',
  saveBtn: 'Save',
  isDirty: false,
  isDisabled: false
};

export default SaveBar;
