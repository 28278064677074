import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';

const FeatureCard = React.forwardRef((props, ref) => {
  const {
    displayName,
    logo,
    subHeading,
    heading,
    body,
    ctaTxt,
    ctaLink,
    target,
    featureImg,
    footnote,
    onClick,
    colSpan,
    appIcon,
    appDescription,
    txtAlign,
    jc,
    txtColor,
    backgroundMobile,
    backgroundDesktop,
    customStyles,
    hideBar,
    innerMaxWidth,
    borderRadius,
    lottieJSON
  } = props;

  const MaxWidth = styled.div`
    margin: auto;
    max-width: ${innerMaxWidth || 'initial'};
    width: 100%;
    display: flex;
    gap: 12px;
  `;

  const Card = styled.div`
    display: flex;
    color: ${txtColor};
    align-items: ${txtAlign};
    justify-content: ${jc};
    flex-direction: column;
    border-radius: ${borderRadius}px;
    min-height: 400px;
    height: 100%;
    height: fill-content;
    padding: 32px;
    position: relative;
    box-shadow: 0px 2.03704px 5.78519px rgba(0, 0, 0, 0.0274815),
      0px 0.462963px 2.79398px rgba(0, 0, 0, 0.0168519);

    transition: box-shadow 0.2s linear 0.2s;
    background: ${backgroundMobile || backgroundDesktop};
    @media screen and (min-width: 991px) {
      padding: 40px;
      background: ${backgroundDesktop};
    }
    :hover {
      box-shadow: 0px 25px 71px rgba(0, 0, 0, 0.07),
        0px 16.2037px 41.581px rgba(0, 0, 0, 0.0531481),
        0px 9.62963px 22.6148px rgba(0, 0, 0, 0.0425185),
        0px 5px 11.5375px rgba(0, 0, 0, 0.035),
        0px 2.03704px 5.78519px rgba(0, 0, 0, 0.0274815),
        0px 0.462963px 2.79398px rgba(0, 0, 0, 0.0168519);
    }
    ${customStyles}
  `;

  const CardContent = styled.div`
    flex-basis: fit-content;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    text-align: ${txtAlign};
    align-items: ${txtAlign};
    flex-direction: column;
    @media screen and (min-width: 991px) {
      flex-basis: ${(jc === 'center' && '470px') ||
      (colSpan <= 6 && 'initial') ||
      (colSpan > 6 && '400px')};
    }
  `;
  const CardTop = styled.div`
    display: flex;
    justify-content: ${txtAlign};
    flex-direction: ${(colSpan <= 6 && 'column') || (colSpan > 6 && 'row')};
    gap: 24px;
  `;
  const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow: hidden;
  `;

  const CardBottom = styled.div`
    padding: 16px 0px 32px 0px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 16px;
  `;

  const ButtonGroup = styled.div``;

  const CardButton = styled(Button)`
    background-color: #fff;
    border-radius: 12px;
    color: #000;
    width: fit-content;
    margin-top: 24px;
    text-transform: initial;
    font-family: 'Inter';
    :hover {
      background: rgba(255, 255, 255, 0.8);
    }
  `;

  const AppDetails = styled.div`
    display: flex;
    flex-direction: column;
  `;

  const AppIcon = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 20%;
  `;

  const Logo = styled.img`
    width: 100%;
    height: 24px;
    margin-bottom: 24px;
    object-fit: contain;
    object-position: left center;
  `;

  const BannerHeading = styled(Typography)`
    font-size: 56px;
    font-weight: 700;
    font-family: 'DM Sans';
    margin-bottom: 12px;
    letter-spacing: -0.04em;

    @media screen and (max-width: 600px) {
      font-size: 40px;
    }
  `;

  const Feature = styled.img``;

  const linkType = target ? { href: ctaLink, target } : { to: ctaLink };

  const CardLink = target ? styled.a`` : styled(Link)``;

  return (
    <Grid item={true} xs={12} md={colSpan} ref={ref}>
      <CardWrapper>
        <CardLink {...linkType} style={{ height: '100%' }} onClick={onClick}>
          <Card>
            <MaxWidth>
              <CardTop>
                <CardContent>
                  {logo && <Logo src={logo} alt={displayName} />}
                  {subHeading && (
                    <Typography
                      variant='subtitle1'
                      style={{
                        textTransform: 'uppercase',
                        marginBottom: '16px'
                      }}
                    >
                      {subHeading}
                    </Typography>
                  )}

                  {heading && (
                    <BannerHeading
                      dangerouslySetInnerHTML={{ __html: heading }}
                      variant='h3'
                    />
                  )}
                  {body && (
                    <Typography
                      dangerouslySetInnerHTML={{ __html: body }}
                      variant='body1'
                      style={{ opacity: '0.8' }}
                    />
                  )}
                  <ButtonGroup>
                    {ctaTxt && (
                      <CardButton variant='contained'>{ctaTxt}</CardButton>
                    )}
                  </ButtonGroup>
                </CardContent>
                <div style={{ maxWidth: innerMaxWidth }}>
                  <Lottie
                    style={{ position: 'absolute', bottom: '-8px' }}
                    animationData={lottieJSON}
                    loop={true}
                  />
                  <Feature width='100%' src={featureImg} alt='' />
                </div>
              </CardTop>
              {footnote && (
                <Typography
                  variant='caption'
                  style={{
                    color: 'rgba(255, 255, 255, 0.5)',
                    fontWeight: '400',
                    fontFamily: 'Inter',
                    marginTop: '40px'
                  }}
                >
                  {footnote}
                </Typography>
              )}
            </MaxWidth>
          </Card>
        </CardLink>
        {!hideBar && (
          <CardLink {...linkType}>
            <CardBottom>
              <MaxWidth>
                <AppIcon>
                  <img
                    src={appIcon}
                    alt={`${displayName} icon`}
                    width='48px'
                    height='48px'
                  />
                </AppIcon>
                <AppDetails>
                  <Typography variant='h6' style={{ color: '#000' }}>
                    {displayName}
                  </Typography>
                  <Typography
                    variant='subtitle2'
                    style={{
                      color: 'rgba(0, 0, 0, 0.6)',
                      fontWeight: '400',
                      fontFamily: 'Inter',
                      letterSpacing: '-0.02em'
                    }}
                  >
                    {appDescription}
                  </Typography>
                </AppDetails>
              </MaxWidth>
            </CardBottom>
          </CardLink>
        )}
      </CardWrapper>
    </Grid>
  );
});

FeatureCard.propTypes = {
  displayName: PropTypes.string.isRequired,
  logo: PropTypes.string,
  subHeading: PropTypes.string,
  heading: PropTypes.string,
  body: PropTypes.string,
  ctaTxt: PropTypes.string,
  ctaLink: PropTypes.string,
  target: PropTypes.string,
  featureImg: PropTypes.string,
  footnote: PropTypes.string,
  onClick: PropTypes.func,
  colSpan: PropTypes.number,
  appIcon: PropTypes.string.isRequired,
  appDescription: PropTypes.string.isRequired,
  txtAlign: PropTypes.string,
  jc: PropTypes.string,
  txtColor: PropTypes.string,
  backgroundMobile: PropTypes.string,
  backgroundDesktop: PropTypes.string,
  customStyles: PropTypes.string,
  hideBar: PropTypes.bool,
  innerMaxWidth: PropTypes.string,
  borderRadius: PropTypes.number,
  lottieJSON: PropTypes.object,
  isSpecialBanner: PropTypes.bool,
  elementName: PropTypes.string
};

FeatureCard.defaultProps = {
  logo: '',
  subHeading: '',
  heading: '',
  body: '',
  ctaTxt: '',
  ctaLink: '',
  target: '',
  featureImg: '',
  footnote: '',
  onClick: null,
  colSpan: '12',
  txtAlign: '',
  jc: '',
  txtColor: '#fff',
  backgroundMobile: '',
  backgroundDesktop: '',
  customStyles: '',
  hideBar: false,
  innerMaxWidth: '',
  borderRadius: 24,
  lottieJSON: null,
  isSpecialBanner: false,
  elementName: ''
};

export default FeatureCard;
