import { WIDGET_LAYOUTS } from '../../constants/widgetLayouts';
import { WIDGET_THEMES } from '../../constants/widgetThemes';
import {
  MODIFY_PRODUCT_AMOUNT,
  MODIFY_LAYOUT,
  MODIFY_THEME
} from '../actions/embedStoreParams';

export const INITIAL_STATE = {
  productAmount: 20,
  widgetTheme: WIDGET_THEMES.light,
  widgetLayout: WIDGET_LAYOUTS.carouselSmall
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MODIFY_PRODUCT_AMOUNT:
      return {
        ...state,
        productAmount: action.payload
      };
    case MODIFY_LAYOUT:
      return {
        ...state,
        widgetLayout: action.payload
      };
    case MODIFY_THEME:
      return {
        ...state,
        widgetTheme: action.payload
      };
    default:
      return state;
  }
};
