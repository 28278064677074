import HubspotForm from 'react-hubspot-form'


export const IntegrationPageHubspotButton = () => {
    const portalId = process.env.REACT_APP_TIKTOK_EARLY_ACCESS_HUBSPOT_PORTAL_ID
    const formId = process.env.REACT_APP_TIKTOK_EARLY_ACCESS_HUBSPOT_FORM_ID
    const region = process.env.REACT_APP_TIKTOK_EARLY_ACCESS_HUBSPOT_REGION
    return <div  className='integrations_page_hubspot_modal_container'>
        <HubspotForm
            portalId={portalId}
            formId={formId}
            region={region}
        />
    </div>
}
