import React from 'react';
import { Icon } from '@teespring/ts-components';
import { deleteCollectionItem } from 'redux/actions/collections';
import { useDispatch } from 'react-redux';
import { string } from 'prop-types';

const DeleteIcon = ({ collectionId }) => {
  const dispatch = useDispatch();
  const deleteCollections = async () => {
    await dispatch(deleteCollectionItem(collectionId));
  };

  return (
    <button className='delete--icon' type='button' onClick={deleteCollections}>
      <Icon name='Trash2' size={18} />
    </button>
  );
};

DeleteIcon.propTypes = {
  collectionId: string.isRequired
};

export default DeleteIcon;
