import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@teespring/ts-components';
import { string } from 'prop-types';

const ReturnLink = ({ path, sectionName }) => (
  <>
    <Link className='backlink mb3' to={path}>
      <Icon name='ChevronLeft' size={18} />
      <span>{sectionName}</span>
    </Link>
  </>
);

ReturnLink.propTypes = {
  path: string.isRequired,
  sectionName: string.isRequired
};

export default ReturnLink;
