import { useCallback, useEffect, useState } from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { addToast } from '../redux/actions';
import { COMMERCE_API_V1_PATH } from '../constants';

const useUserOrders = (userId) => {
  const dispatch = useDispatch();
  const [orders, setOrders] = useState([]);
  const [isFetchingOrders, setIsFetchingOrders] = useState(true);

  const fetchUserOrders = useCallback(async () => {
    try {
      setIsFetchingOrders(true);
      const { data } = await axios.get(`${COMMERCE_API_V1_PATH}/user/orders`, {
        params: {
          userId
        }
      });
      const formattedData = map(data, (item) => {
        const currency = get(item, 'item.totalCost.currency', 'USD');
        const price = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency,
          minimumFractionDigits: 2
        }).format(item.totalCost.amount / 100);
        const date = new Date(item.updated).toLocaleDateString('en-US');
        return {
          amount: `${currency} ${price}`,
          date,
          name: item.items[0].title,
          id: item.lookupNumber
        };
      });
      setOrders(formattedData);
    } catch (err) {
      dispatch(addToast(err?.response?.data?.message ?? err.message, 'danger'));
    } finally {
      setIsFetchingOrders(false);
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (userId) fetchUserOrders();
  }, [fetchUserOrders, userId]);

  return { isFetchingOrders, orders };
};

export default useUserOrders;
