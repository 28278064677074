import PropTypes from 'prop-types';
import { WIDGET_THEMES } from '../../../constants/widgetThemes';
import { BASE_WIDGET_URL } from '../../../constants';

function Iframe(props) {
  const {
    storeslug,
    products,
    layout,
    theme,
    title,
    width,
    height,
    currency,
    page
  } = props;

  return (
    <iframe
      style={{ borderRadius: '24px', border: 'none' }}
      src={`${BASE_WIDGET_URL}?slug=${storeslug}${
        products === undefined ? '' : `&per=${products}`
      }${currency === undefined ? '' : `&currency=${currency}`}${
        page === undefined ? '' : `&page=${page}`
      }&layout=${layout}&theme=${theme}`}
      title={`${title} Merch store powered by Spring`}
      width={width}
      height={height}
    />
  );
}

Iframe.propTypes = {
  storeslug: PropTypes.string,
  products: PropTypes.number,
  layout: PropTypes.string,
  theme: PropTypes.string,
  bgcolor: PropTypes.string,
  txtcolor: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  currency: PropTypes.string,
  page: PropTypes.number
};

Iframe.defaultProps = {
  storeslug: '',
  products: 6,
  layout: 'car-sm',
  theme: WIDGET_THEMES.light,
  bgcolor: '',
  txtcolor: '',
  title: '',
  width: '',
  height: '',
  currency: '',
  page: 1
};

export default Iframe;
