export const FLAGS = {
  PURCHASE_DOMAIN_V2: 'creator_tools_purchase_a_domain_v2',
  SPRINGMARK: 'creator_tools_can_purchase_pmd',
  DELETE_DOMAIN: 'can_delete_purchased_domain',
  SHOW_COLLECTIONS: 'showCollections',
  EMAIL_VERIFICATION: 'parEmailVerification',
  HAS_ZENDESK_LIVECHAT: 'hasZendeskLiveChat'
};

export { useFeatureFlag } from './useFeatureFlag';
