import React from 'react';
import { array } from 'prop-types';
import map from 'lodash/map';
import flatten from 'lodash/flatten';

import { Table, Loader } from '@teespring/ts-components';

import { useMultipleListings } from '../../../hooks';
import './SpringMarkContent.scss';

/**
 * Renders protected listings for SpringMark
 *
 * @return {object} JSX.Element
 * */
const SpringMarkContent = ({ subscriptions }) => {
  const campaigns = flatten(
    map(subscriptions, (sub) => sub?.metadata?.data?.campaigns)
  );
  const listingData = campaigns.map((campaign) => ({ sku: campaign.sku }));

  const { listings, isLoading } = useMultipleListings(listingData);

  const tableItems = listings.map(({ listingId, title }) => {
    const url = `/listings/${listingId}/admin`;
    return {
      name: (
        <a className='spring-mark-settings__listing-link' href={url}>
          {title}
        </a>
      )
    };
  });

  return (
    <div className='spring-mark-settings__container'>
      <h1>Your protected designs</h1>
      <p>SpringMark is activated for the following listings</p>
      <br />
      {isLoading ? (
        <Loader />
      ) : (
        <Table
          actionBtnShow={false}
          showHeaders={true}
          tableKey='pmd-listings'
          notFoundItemText='No listings currently protected'
          // TODO: `Table` does not handle empty items gracefully. Remove this when that bug is resolved.
          items={
            tableItems.length === 0
              ? [{ name: 'No listings currently protected' }]
              : tableItems
          }
        />
      )}
    </div>
  );
};

SpringMarkContent.propTypes = {
  /**
   * A subscription object for this service, e.g. from `useUserSubscriptions`
   */
  subscriptions: array.isRequired
};

export default SpringMarkContent;
