/**
 * Sets the active modal
 *
 * @param {string} id - String identifier for modal
 * @param {Object} props - Optional props object that gets injected into target modal
 * @return {Object} Action object
 */

export const CLEAR_MODAL_STATE = 'CLEAR_MODAL_STATE';
export const HIDE_MODALS = 'HIDE_MODALS';
export const UNHIDE_MODALS = 'UNHIDE_MODALS';
export const SET_ACTIVE_MODAL = 'SET_ACTIVE_MODAL';

export const setActiveModal = ({ id, props = {} }) => ({
  type: SET_ACTIVE_MODAL,
  id,
  props
});
export const clearModalState = () => ({ type: CLEAR_MODAL_STATE });
export const hideModal = () => ({ type: HIDE_MODALS });
export const unhideModal = () => ({ type: UNHIDE_MODALS });
