import React from 'react';
import hljs from 'highlight.js';
import PropTypes from 'prop-types';
import 'highlight.js/styles/monokai.css';

export default function Highlighter(props) {
  const { content, language } = props;

  const highlighted = language
    ? hljs.highlight(language, content)
    : hljs.highlightAuto(content);

  return (
    <pre className='hljs'>
      <code dangerouslySetInnerHTML={{ __html: highlighted.value }} />
    </pre>
  );
}

Highlighter.propTypes = {
  content: PropTypes.string.isRequired,
  language: PropTypes.string
};

Highlighter.defaultProps = {
  language: 'htmlbars'
};
