export const API_HOST = process.env.REACT_APP_BACKEND_HOST
  ? process.env.REACT_APP_BACKEND_HOST
  : 'https://teespring.com';

export const SPRING_BASE_URL = 'https://teespring.com';

export const STAGING_BASE_URL = 'https://teedown.com';

export const LOCAL_BASE_URL = 'http://localhost:3000';

export const COMMERCE_API_V1_PATH = `${API_HOST}/api/v1`;

export const GRAPHQL_API_HOST =
  process.env.REACT_APP_GRAPHQL_API_HOST || 'http://localhost:3000/graphql';

export const IS_PRODUCTION = process.env.NODE_ENV === 'production';

export const IS_STAGING = window.location.host.includes('teedown');

export const getBaseUrl = () => {
  if (IS_STAGING) {
    return STAGING_BASE_URL;
  } else if (IS_PRODUCTION) {
    return SPRING_BASE_URL;
  } else {
    return LOCAL_BASE_URL;
  }
};

export const LAUNCHER_PATH = '/design-launcher';

export const LAUNCHER_URL = `${getBaseUrl()}${LAUNCHER_PATH}`;

export const DASHBOARD_URL = IS_PRODUCTION
  ? 'https://dashboard.teespring.com'
  : 'http://localhost:3000';

export const COMMERCE_API_HOST = process.env.REACT_APP_COMMERCE_BACKEND_HOST
  ? process.env.REACT_APP_COMMERCE_BACKEND_HOST
  : 'https://commerce.teespring.com';

export const COLLECTIONS_API_HOST = IS_PRODUCTION
  ? `${COMMERCE_API_HOST}/v1`
  : 'https://35a63ruk86.execute-api.us-west-1.amazonaws.com/develop';

export const COMMERCE_API_VERSION = process.env.REACT_APP_COMMERCE_API_VERSION
  ? process.env.REACT_APP_COMMERCE_API_VERSION
  : 'v2';

export const FEATURE_FLAG_NAMES = process.env.REACT_APP_FEATURE_FLAG_NAMES
  ? process.env.REACT_APP_FEATURE_FLAG_NAMES.split(',')
  : [];

export const S3_DASHBOARD_CONTENT_URL = process.env
  .REACT_APP_S3_DASHBOARD_CONTENT_URL
  ? process.env.REACT_APP_S3_DASHBOARD_CONTENT_URL
  : 'https://d11q1jnxzf43no.cloudfront.net/dashboard';

export const S3_TEESPRING_ASS_URL = 'https://d1b2zzpxewkr9z.cloudfront.net';

export const S3_TEEDOWN_ASS_URL = 'https://d11q1jnxzf43no.cloudfront.net';

export const S3_ASSET_URL =
  IS_PRODUCTION && !IS_STAGING ? S3_TEESPRING_ASS_URL : S3_TEEDOWN_ASS_URL;

export const SERVERLESS_BASE_URL = process.env.REACT_APP_THEME_LAMBDA_PATH
  ? process.env.REACT_APP_THEME_LAMBDA_PATH
  : 'https://kxqd7cf966.execute-api.us-west-1.amazonaws.com/dev';

export const SUBSCRIPTION_API_GATEWAY_KEY = process.env
  .REACT_APP_SUBSCRIPTION_API_GATEWAY_KEY
  ? process.env.REACT_APP_SUBSCRIPTION_API_GATEWAY_KEY
  : '';

export const SUBSCRIPTIONS_API_HOST = process.env
  .REACT_APP_SUBSCRIPTIONS_API_HOST
  ? process.env.REACT_APP_SUBSCRIPTIONS_API_HOST
  : '';

export const STRIPE_PUBLISHABLE_KEY = process.env
  .REACT_APP_STRIPE_PUBLISHABLE_KEY
  ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  : '';

export const DEFAULT_COLUMN_KEY = 'col-1';

export const SPRING_FOR_CREATORS = 'springforcreators';

export const TEESPRING = 'teespring';

export const SUPPORT_EMAIL = 'creatorhelp@spri.ng';

export const SERVICE_ID_MAP = {
  connect_a_domain: 'c1236644-2cec-11ec-8d3d-0242ac130003',
  spring_mark: 'e1acd605-3215-4d2f-a5d9-cb3098e9de40',
  purchase_domain: '298f3e8f-83c0-4c3b-bcaf-64ae7b716b5f'
};

export const LAUNCHDARKLY_ID = process.env.REACT_APP_LAUNCHDARKLY_ID
  ? process.env.REACT_APP_LAUNCHDARKLY_ID
  : '644c17666332a912b6d44fda';

export const BASE_WIDGET_URL = process.env.REACT_APP_BASE_WIDGET_URL
  ? process.env.REACT_APP_BASE_WIDGET_URL
  : 'https://embed.creator-spring.com/widget';
