import axios from 'axios';
import { API_HOST } from '../../../constants';
import { addToast } from '../toast';

export const RECEIVE_LISTING = 'RECEIVE_LISTING';
export const LISTING_ERROR = 'LISTING_ERROR';

export function fetchListing(listingData) {
  return async (dispatch) => {
    try {
      let dataUrl = `${API_HOST}/api/v1/listings?visibility=any`;
      if (listingData.listingId) {
        dataUrl += `&listingId=${listingData.listingId}`;
      } else if (listingData.sku) {
        dataUrl += `&sku=${listingData.sku}`;
      } else {
        throw new Error('No sku or listingId provided.');
      }
      const { data } = await axios.get(dataUrl);
      dispatch({
        type: RECEIVE_LISTING,
        listingId: listingData.listingId,
        listingData: data
      });
    } catch (err) {
      dispatch(
        addToast(
          `Error getting listing ${
            err?.response?.data?.message ?? err.message
          }`,
          'danger'
        )
      );
      dispatch({ type: LISTING_ERROR, err: err.message });
    }
  };
}
