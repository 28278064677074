import {
  ADD_CUSTOM_DOMAIN,
  DELETE_CUSTOM_DOMAIN,
  HIDE_SHOW_MORE,
  IS_CHECKING_OUT,
  IS_CREATING_DOMAIN_DONE,
  IS_CREATING_DOMAIN,
  IS_FETCHING_DOMAINS,
  IS_VERIFICATION_DONE,
  IS_VERIFICATION_LOADING,
  LOAD_MORE_DOMAINS,
  RECEIVE_CUSTOM_DOMAINS,
  RECEIVE_EMAIL_VERIFICATION_STATUS,
  RECEIVE_PURCHASABLE_DOMAINS,
  RECEIVE_VERIFICATION_STATUS,
  REDIRECT_TO_STRIPE_CHECKOUT,
  SET_CREATED_CUSTOM_DOMAIN,
  SET_CUSTOM_DOMAIN_FB_PIXEL,
  SHOW_PURCHASE_MODAL,
  TRANSFER_DOMAIN
} from 'redux/actions';

const INITIAL_STATE = {
  domains: {},
  createdDomain: {},
  isCreatingDomain: false,
  isVerifying: false,
  purchasableDomains: {},
  stripeSessionData: {},
  isFetchingDomains: false,
  isFetchingMore: false,
  hideLoadMore: false,
  isCheckingOut: false,
  showPurchaseModal: false
};

/**
 * Get Custom Domain data from Spring
 * @param  {Object} state  Redux State
 * @param  {Object} action Action data
 * @return {Object}        Returns the state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_CUSTOM_DOMAIN:
      return {
        ...state,
        domains: {
          ...state.domains,
          [action.storeId]: [
            ...(state.domains[action.storeId] ?? []),
            action.data
          ]
        }
      };
    case DELETE_CUSTOM_DOMAIN:
      return {
        ...state,
        domains: {
          ...state.domains,
          [action.storeId]: [
            ...(state.domains[action.storeId].slice(0, action.domainIndex) ??
              []),
            ...(state.domains[action.storeId].slice(action.domainIndex + 1) ??
              [])
          ]
        }
      };
    case IS_CREATING_DOMAIN:
      return { ...state, isCreatingDomain: true };
    case IS_CREATING_DOMAIN_DONE:
      return { ...state, isCreatingDomain: false };
    case IS_VERIFICATION_DONE:
      return { ...state, isVerifying: false };
    case IS_VERIFICATION_LOADING:
      return { ...state, isVerifying: true };
    case RECEIVE_CUSTOM_DOMAINS:
    case RECEIVE_EMAIL_VERIFICATION_STATUS:
    case RECEIVE_VERIFICATION_STATUS:
    case TRANSFER_DOMAIN:
      return {
        ...state,
        domains: {
          ...state.domains,
          [action.storeId]: action.data
        }
      };
    case RECEIVE_PURCHASABLE_DOMAINS:
      return { ...state, purchasableDomains: action.data };
    case REDIRECT_TO_STRIPE_CHECKOUT:
      return { ...state, stripeSessionData: action.data };
    case IS_FETCHING_DOMAINS:
      return {
        ...state,
        isFetchingDomains: action.isFetching
      };
    case LOAD_MORE_DOMAINS:
      return {
        ...state,
        isFetchingMore: action.loading
      };
    case HIDE_SHOW_MORE:
      return {
        ...state,
        hideLoadMore: true
      };
    case IS_CHECKING_OUT:
      return {
        ...state,
        isCheckingOut: action.isCheckingOut
      };
    case SHOW_PURCHASE_MODAL:
      return {
        ...state,
        showPurchaseModal: action.show
      };
    case SET_CREATED_CUSTOM_DOMAIN:
      return {
        ...state,
        createdDomain: action.createdDomain
      };
    case SET_CUSTOM_DOMAIN_FB_PIXEL:
      return {
        ...state,
        domains: {
          ...state.domains,
          [action.storeId]: [
            ...(state.domains[action.storeId].slice(0, action.domainIndex) ??
              []),
            action.data,
            ...(state.domains[action.storeId].slice(action.domainIndex + 1) ??
              [])
          ]
        }
      };
    default:
      return state;
  }
};
