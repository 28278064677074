import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { addToast } from '../redux/actions/toast';
import { API_HOST } from '../constants';

const useMultipleListings = (listingDataArray) => {
  const [listings, setListings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const getListing = async (listingData, cancelToken) => {
    let dataUrl = `${API_HOST}/api/v1/listings?visibility=any`;
    if (listingData.listingId) {
      dataUrl += `&listingId=${listingData.listingId}`;
    } else if (listingData.sku) {
      dataUrl += `&sku=${listingData.sku}`;
    } else {
      return {};
    }
    const { data } = await axios.get(dataUrl, { cancelToken });
    return data;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cancelTokenSources = [];
  const cleanup = useCallback(
    () => cancelTokenSources.forEach((source) => source.cancel()),
    [cancelTokenSources]
  );

  useEffect(() => {
    if (!listingDataArray) {
      return;
    } else if (listingDataArray.length === 0) {
      setIsLoading(false);
      return;
    }

    const requestArray = listingDataArray.map((listingData) => {
      const cancelTokenSource = axios.CancelToken.source();
      cancelTokenSources.push(cancelTokenSource);

      return getListing(listingData, cancelTokenSource.token);
    });

    Promise.all(requestArray)
      .then((responses) => setListings(responses))
      .catch((err) => {
        cleanup();

        // Don't display a toast if the request was cancelled
        if (!axios.isCancel(err)) {
          dispatch(
            addToast(
              `Error getting listing ${
                err?.response?.data?.message ?? err.message ?? ''
              }`,
              'danger'
            )
          );
        }
      })
      .then(() => setIsLoading(false));

    return cleanup;
  }, [cancelTokenSources, cleanup, dispatch, listingDataArray]);

  return { listings, isLoading };
};

export default useMultipleListings;
