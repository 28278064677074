import React from 'react';
import { object } from 'prop-types';
import './Header.scss';
import parse from 'html-react-parser';
/**
 * Renders the header for service settings template.
 *
 * @return {object} JSX.Element
 * */
const Header = ({ logo, text, status, ...other }) => {
  return (
    <div className='app-settings__header' {...other}>
      <div className='flex-row'>
        <div className='logo'>
          {logo.imgSrc ? (
            <img src={logo.imgSrc} alt={logo.alt} />
          ) : (
            parse(logo.svgToParse ?? '')
          )}
        </div>
        <div>
          <h1 className='title'>{text.title}</h1>
          <p>
            by <strong>{text.author}</strong>
          </p>
        </div>
        <div className='status'>
          <div className='indicator' />
          <span className='text'> {status.description} </span>
        </div>
      </div>
      <p className='subtitle'>{text.subtitle}</p>
    </div>
  );
};

Header.propTypes = {
  logo: object.isRequired,
  text: object.isRequired,
  status: object.isRequired
};
export default Header;
