import React, {useEffect} from 'react';
import {useAmplitude} from 'hooks';
import useIntegrations from 'hooks/useIntegrations';
import IntegrationCards from './IntegrationCards';
import IntegrationsCarousel from './carousel/index';
import {SellInOtherPlatformsGuide} from './SellInOtherPlatformsGuide';

const IntegrationsMain = () => {
  const { logEvent } = useAmplitude();
  const { integrations, steps } = useIntegrations();
  useEffect(() => {
    logEvent('page.viewed', {
      journey: 'dashboard',
      feature: 'integrations',
      page: 'all integrations',
      button: 'social tile',
      url: window.location.href
    });
  }, []);
  return (
    <div className='integrations_page'>
      <IntegrationsCarousel steps={steps}/>
      <div className='integrations_page_sections'>
        <IntegrationCards socials={integrations}/>
        <SellInOtherPlatformsGuide/>
      </div>
    </div>

  );
};

export default IntegrationsMain;
