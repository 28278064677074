import { useSelector } from 'react-redux';
import get from 'lodash/get';

/**
 * @param {string} flagName name of the feature flag
 * @param {string} defaultValue fallback value, defaults to false
 * @returns {boolean} whether or not feature flag is enabled
 */
export const useFeatureFlag = (flagName, defaultValue = false) => {
  const featureFlags = useSelector((state) =>
    get(state, 'user.featureFlags', {})
  );
  return Boolean(featureFlags[flagName] ?? defaultValue);
};
