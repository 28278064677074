/* eslint-disable  react/state-in-constructor */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TrackJS } from 'trackjs';
import { shape, node } from 'prop-types';

export const SOMETHING_WENT_WRONG_ERROR_MESSAGE =
  'Something went wrong. Try later';

class ErrorHandler extends Component {
  state = {
    unexpectedErrorOccurred: false
  };

  componentDidCatch(error) {
    if (TrackJS.isInstalled()) TrackJS.track(error);
    this.setState({ unexpectedErrorOccurred: true });
  }

  isUnauthorizedError = () => {
    const { httpError } = this.props;
    return httpError.status === 401;
  };

  redirectTo = (url) => {
    window.location.href = url;
  };

  isProductionEnvironment = () => {
    return process.env.REACT_APP_ENV === 'production';
  };

  render() {
    const { children, httpError } = this.props;
    const { unexpectedErrorOccurred } = this.state;

    if (this.isUnauthorizedError() && this.isProductionEnvironment()) {
      this.redirectTo(
        `${httpError.redirectUrl}?redirect_to=${window.location.href}`
      );
    }

    return unexpectedErrorOccurred ? (
      <h1>{`${SOMETHING_WENT_WRONG_ERROR_MESSAGE}`}</h1>
    ) : (
      children
    );
  }
}

ErrorHandler.propTypes = {
  httpError: shape({}).isRequired,
  children: node.isRequired
};

const mapStateToProps = ({ httpError }) => ({
  httpError
});

export default connect(mapStateToProps)(ErrorHandler);
