/* eslint-disable no-nested-ternary */
import axios from 'axios';
import { map, find, every, some, isEmpty, cloneDeep, get, has } from 'lodash';
import { API_HOST, COMMERCE_API_HOST } from '../../../constants';
import { addToast } from '../toast';

export const COLLECTION_PRODUCTS_IS_FETCHING =
  'COLLECTION_PRODUCTS_IS_FETCHING';
export const SET_COLLECTION_PRODUCTS = 'SET_COLLECTION_PRODUCTS';
export const ADD_INDIVIDUAL_LISTING_PRODUCTS =
  'ADD_INDIVIDUAL_LISTING_PRODUCTS';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const SET_SORTBY = 'SET_SORTBY';
export const SET_VIEW = 'SET_VIEW';
export const SELECT_PRODUCT = 'SELECT_PRODUCT';
export const SELECT_ALL_PRODUCT = 'SELECT_ALL_PRODUCT';
export const COLLECTION_PRODUCTS_HIDE_LOAD_MORE_BUTTON =
  'COLLECTION_PRODUCTS_HIDE_LOAD_MORE_BUTTON';
export const SORT_LISTINGS = 'SORT_LISTINGS';
export const COLLECTION_PRODUCTS_SET_PAGE_NUM =
  'COLLECTION_PRODUCTS_SET_PAGE_NUM';

export const DEFAULT_PAGE_LIMIT = 10;

const getUserListings = async (userId, pageNum, sortBy, orderBy) => {
  const { data } = await axios.get(
    `${API_HOST}/api/v1/user/listings?userId=${userId}&page=${pageNum}&sortBy=${sortBy}&orderBy=${orderBy}`,
    { withCredentials: true }
  );
  return data;
};

export const fetchIndividualListingProducts =
  (listing, index) => async (dispatch) => {
    try {
      const { data } = await axios.get(
        `${COMMERCE_API_HOST}/v0/listing/${get(
          listing,
          'url'
        )}?currency=USD&region=USA&country_code=en&availability=any&visibility=any`
      );

      listing.products = data.products_payload;
      if (!isEmpty(data.products_payload)) {
        listing.primaryProductType = get(
          data.products_payload[0],
          'productType'
        );
      }

      dispatch({
        type: ADD_INDIVIDUAL_LISTING_PRODUCTS,
        payload: listing,
        index
      });
    } catch (err) {
      dispatch({ type: COLLECTION_PRODUCTS_IS_FETCHING, value: false });
      throw err;
    }
  };

export const fetchListingProducts = () => async (dispatch, getState) => {
  try {
    dispatch({ type: COLLECTION_PRODUCTS_IS_FETCHING, value: true });
    const listings = [...getState().collectionProducts.products];

    for (let i = 0; i < listings.length; i++) {
      try {
        const listing = listings[i];
        if (!has(listing, 'products')) {
          dispatch(fetchIndividualListingProducts(listing, i));
        }
      } catch (err) {
        throw err;
      }
    }
  } catch (err) {
    throw err;
  } finally {
    dispatch({ type: COLLECTION_PRODUCTS_IS_FETCHING, value: false });
  }
};

export const fetchCollectionProducts =
  (pageNum = 1) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: COLLECTION_PRODUCTS_IS_FETCHING, value: true });
      const { userId } = getState().user;

      const data = await getUserListings(
        userId,
        pageNum,
        'createdDate',
        'DESC'
      );

      if (
        isEmpty(get(data, 'listings', [])) ||
        data.listings.length !== DEFAULT_PAGE_LIMIT
      ) {
        dispatch({ type: COLLECTION_PRODUCTS_HIDE_LOAD_MORE_BUTTON });
      }

      dispatch({
        type: SET_COLLECTION_PRODUCTS,
        payload: data.listings,
        pageNum
      });

      dispatch({ type: COLLECTION_PRODUCTS_IS_FETCHING, value: false });

      await dispatch(fetchListingProducts(pageNum));
    } catch (err) {
      dispatch(addToast(err?.response?.data?.message ?? err.message, 'danger'));
      dispatch({ type: COLLECTION_PRODUCTS_IS_FETCHING, value: false });
    }
  };

export const selectProduct =
  (id, isListing = false, doesProductBelongToListing, productId) =>
  (dispatch, getState) => {
    const products = [...getState().collectionProducts.products];
    const listing = find(products, (prod) => prod.listingId === id);
    let isChecked = get(listing, 'isChecked', false);
    let updated;

    if (isListing) {
      const productLen = listing.products.length;
      const { isEveryChecked, checked } = listing.products.reduce(
        (acc, curr) => {
          if (get(curr, 'isChecked', false)) acc.checked.push(curr);
          if (acc.checked.length === productLen) acc.isEveryChecked = true;
          return acc;
        },
        { checked: [], isEveryChecked: false }
      );

      updated = map(listing.products, (product) => ({
        ...product,
        /*
        i apologize in advance to whoever has to work on this
        what we essentially want to do is take the inverse of the current checked value
        if all products within the listing is selected

        if some products are select we want to take the inverse of the selected products

        finally if none are select then we want to set every product in the listing to true
       */
        isChecked: isEveryChecked
          ? !isChecked
          : checked.length > 0
          ? !checked[0].isChecked
          : true
      }));
    } else {
      updated = map(
        doesProductBelongToListing ? listing.products : products,
        (product) => {
          const conditonal = doesProductBelongToListing
            ? product.productId === productId
            : product.listingId === id;

          return conditonal
            ? {
                ...product,
                isChecked: !get(product, 'isChecked', false)
              }
            : product;
        }
      );

      if (!doesProductBelongToListing) {
        return dispatch({
          type: SELECT_PRODUCT,
          payload: updated
        });
      }
    }

    isChecked = every(updated, (prod) => prod.isChecked);

    const isDeselect =
      !isChecked && some(updated, (prod) => get(prod, 'isChecked', false));

    const updatedListing = {
      ...listing,
      isChecked,
      isDeselect,
      products: updated
    };

    const indexToReplace = products.findIndex(
      (product) => product.listingId === updatedListing.listingId
    );

    products[indexToReplace] = updatedListing;

    return dispatch({
      type: SELECT_PRODUCT,
      payload: products
    });
  };

export const selectAllProducts = (checked) => (dispatch, getState) => {
  const products = cloneDeep(getState().collectionProducts.products);
  const updated = map(products, (prod) => {
    return {
      ...prod,
      isChecked: !checked,
      isDeselect: false,
      products: !isEmpty(get(prod, 'products', []))
        ? map(prod.products, (p) => ({
            ...p,
            isChecked: !checked
          }))
        : []
    };
  });

  dispatch({
    type: SELECT_ALL_PRODUCT,
    payload: updated
  });
};

export const setSearchResults = (results) => ({
  type: SET_SEARCH_RESULTS,
  results
});

export const selectSearchResult = (id) => (dispatch, getState) => {
  const results = [...getState().collectionProducts.searchResults];
  const updated = map(results, (result) =>
    result.listingId === id
      ? { ...result, isChecked: !get(result, 'isChecked', false) }
      : result
  );

  dispatch({
    type: SET_SEARCH_RESULTS,
    results: updated
  });
};

export const selectAllSearchResults = (checked) => (dispatch, getState) => {
  const results = [...getState().collectionProducts.searchResults];
  const updated = map(results, (result) => ({
    ...result,
    isChecked: !checked
  }));

  dispatch({
    type: SET_SEARCH_RESULTS,
    results: updated
  });
};

export const setSortBy = (sortBy) => async (dispatch, getState) => {
  try {
    const { userId } = getState().user;
    const { sortByParam, orderBy } = sortBy;
    dispatch({ type: COLLECTION_PRODUCTS_IS_FETCHING, value: true });
    dispatch({ type: SET_SORTBY, sortBy });
    const data = await getUserListings(userId, 1, sortByParam, orderBy);

    dispatch({
      type: SORT_LISTINGS,
      payload: data.listings
    });

    await dispatch(fetchListingProducts(1));
  } catch (err) {
    dispatch(addToast(err?.response?.data?.message ?? err.message, 'danger'));
  } finally {
    dispatch({ type: COLLECTION_PRODUCTS_IS_FETCHING, value: false });
  }
};

export const setView = (view) => ({
  type: SET_VIEW,
  view
});

export const setPageNum = (pageNum) => ({
  type: COLLECTION_PRODUCTS_SET_PAGE_NUM,
  pageNum
});
