import { v4 } from 'uuid';
import get from 'lodash/get';
import map from 'lodash/map';
import includes from 'lodash/includes';
import reduce from 'lodash/reduce';
import findIndex from 'lodash/findIndex';

/**
 * Generates a sku string from the passed variation
 * @param {Object} variation - Object representing current variation
 * @param {number} productId - Number representing product id
 * @param {number} qty - Number representing quantity
 * @param {array} products - Array defining product data
 * @returns {string} Returns a string representing the quantity appended to the product sku
 */
export const generateSku = (variation, productId, qty, products) => {
  const product = products.find((prod) => prod.id === variation.id);
  const skuArgs = [
    get(product, 'itemGroupId'),
    get(variation, 'selectedColor.id'),
    get(variation, 'selectedSize.id'),
    qty
  ];
  return product ? skuArgs.join('_') : '';
};

/**
 * Generates a string representing a valid checkout url
 * @param {Object} variations - Object defining variation data
 * @param {array} products - Array defining product data
 * @param {string} currency - currency type
 * @returns {string} - Returns a url string
 */
export const generateCheckoutUrl = (variations, products, currency) => {
  const skus = variations.reduce((acc, curr) => {
    if (curr.quantity > 0) {
      acc.push(generateSku(curr, curr.productId, curr.quantity, products));
    }
    return acc;
  }, []);

  return `/samples_checkout?currency=${currency}&payment_method=stripe&ship_method=1&skus=${skus.join(
    ','
  )}`;
};

/**
 * Flattens an array of product variations into an array of color objects
 * @param {array} productVariations - Collection of product.variations
 * @returns {array} Returns an array of color objects
 */
const flattenColors = (productVariations) => {
  return map(productVariations, (prod) => ({
    id: prod.variationId,
    label: prod.color
  }));
};

/**
 * Maps sizes for each color
 * @param productVariations
 * @returns {Object} Returns an object, mapping sizes to color ids
 */
const mapSizesToColors = (productVariations) => {
  return reduce(
    productVariations,
    (acc, variation) => {
      const availableSizeIds = map(
        get(variation, 'availableSizesWithId', []),
        (availableSize) => availableSize.id
      );
      const sizes = map(get(variation, 'sizes', []), (size) => ({
        ...size,
        disabled: !includes(availableSizeIds, size.id)
      }));
      acc[variation.variationId] = sizes;
      return acc;
    },
    {}
  );
};

/**
 * Generations a blank variation object with default
 * values pulled from the passed product
 * @param {Object} product - product data
 * @param {number} index - Index of this variation
 * @returns {Object} Returns a variation object
 */
export const generateBlankVariation = (product) => {
  const productVariations = get(product, 'variations', []);
  const colors = flattenColors(productVariations);
  const selectedColorIndex = 0;
  const firstColor = get(colors, `[${selectedColorIndex}]`, {});
  const colorSizeMap = mapSizesToColors(productVariations);
  const firstColorSizes = get(colorSizeMap, `${firstColor.id}`, []);
  const selectedSizeIndex = findIndex(firstColorSizes, ['disabled', false]);
  const firstAvailableSize = get(firstColorSizes, `[${selectedSizeIndex}]`, {});

  return {
    id: v4(),
    productId: get(product, 'productId'),
    quantity: 0,
    colors,
    colorSizeMap,
    selectedColor: firstColor,
    selectedSize: firstAvailableSize,
    basePrice: get(product, 'basePrice'),
    selectedSizeIndex,
    selectedColorIndex
  };
};

/**
 * Formats a number by the global currency value
 * @param {*} price - Price value to format
 * @param {*} currency - currency type to format
 * @returns {string} Returns a price value formatted based on the current region
 */

export const formatPriceByLocale = (price, currency) => {
  const currentPrice = +price;
  const priceValue = !Number.isNaN(currentPrice) ? +price : 0;
  return priceValue.toLocaleString(undefined, { style: 'currency', currency });
};
