import axios from 'axios';
import { formatAxiosRequestHeaders } from 'lib/helpers';
import { COMMERCE_API_V1_PATH } from '../constants';

/**
 * Sends service agreement request and provides agreement legal names
 * @param {object} user User Object
 * @return {object} returns object containing function to send agreement and agreement names
 */
const useServiceAgreement = (user) => {
  const legalNames = {
    DASHBOARD_TERMS_OF_SERVICE_AGREEMENT:
      'dashboard_terms_of_service_agreement',
    DOMAIN_PURCHASE_AGREEMENT: 'domain_purchase_agreement'
  };
  const signAgreement = async (legalName) => {
    const res = await axios.post(
      `${COMMERCE_API_V1_PATH}/user/agree_to`,
      { legalName, userId: user.userId },
      formatAxiosRequestHeaders(user.token)
    );
    return res;
  };
  return {
    legalNames,
    signAgreement
  };
};

export default useServiceAgreement;
