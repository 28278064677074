// https://github.com/reactivestack/cookies/tree/master/packages/universal-cookie
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const useCookie = () => {
  const setValue = (name, value, options) =>
    cookies.set(name, value, { ...options });

  const getValue = (key) => cookies.get(key);

  const removeValue = (key) => cookies.remove(key, { path: '/' });

  return {
    setValue,
    getValue,
    removeValue
  };
};

export default useCookie;
