import { TrackJS } from 'trackjs';

const trackjsMiddleware = () => (next) => (action) => {
  try {
    next(action);
  } catch (err) {
    if (TrackJS.isInstalled()) TrackJS.track(err);
  }
};

export default trackjsMiddleware;
