import { useState } from 'react';

const useClipboardCopy = () => {
  const [showCopyNotification, setShowCopyNotification] = useState(false);

  const copyToClipboard = (textToCopy) => {
    const tempTextArea = document.createElement('textarea');
    tempTextArea.textContent = textToCopy;
    tempTextArea.style.position = 'fixed';
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    setShowCopyNotification(true);
  };

  return { showCopyNotification, setShowCopyNotification, copyToClipboard };
};

export default useClipboardCopy;
