import { useLocation, useHistory } from 'react-router-dom';

const useQuery = () => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);

  const hideAllQueryParams = () => {
    history.replace({
      search: ''
    });
  };

  return {
    query,
    hideAllQueryParams
  };
};

export default useQuery;
