import { useState } from 'react';
import { get, chain, isEqual } from 'lodash';
import states from 'lib/hooks/states';
import SmartyStreetsSDK from 'smartystreets-javascript-sdk';

const SmartyStreetsCore = SmartyStreetsSDK.core;
const IntlLookup = SmartyStreetsSDK.internationalStreet.Lookup;
const USLookup = SmartyStreetsSDK.usStreet.Lookup;
const addressStatuses = {
  valid: 1,
  invalidWithSuggestion: 2,
  invalidWithoutSuggestion: 3
};

const useSmartyStreets = () => {
  const [error, setError] = useState(null);
  const [addressSuggestions, setAddressSuggestions] = useState([]);

  const credentials = new SmartyStreetsCore.SharedCredentials(
    process.env.REACT_APP_SMARTY_WEBSITE_KEY
  );
  const intlClient =
    SmartyStreetsCore.buildClient.internationalStreet(credentials);
  const usClient = SmartyStreetsCore.buildClient.usStreet(credentials);

  const determineStatus = (isValid, candidateAddress) => {
    if (isValid) return addressStatuses.valid;
    if (
      chain(candidateAddress)
        .omit(['countryAbbrv'])
        .values()
        .compact()
        .isEmpty()
        .value()
    )
      return addressStatuses.invalidWithoutSuggestion;
    setAddressSuggestions([candidateAddress]);
    return addressStatuses.invalidWithSuggestion;
  };

  const isValidUsAddress = async ({
    address,
    address2,
    city,
    state,
    zip,
    countryAbbrv
  }) => {
    const lookup = new USLookup();
    lookup.street = address;
    lookup.secondary = address2;
    lookup.city = city;
    lookup.state = state;
    lookup.zipCode = zip;
    lookup.maxCandidates = 1;
    lookup.match = 'strict';

    const response = await usClient.send(lookup);
    const candidateComponents = get(
      response,
      'lookups[0].result[0].components',
      {}
    );
    const candidateAddress = {
      address: chain([
        get(candidateComponents, 'primaryNumber', ''),
        get(candidateComponents, 'streetPredirection', ''),
        get(candidateComponents, 'streetName', ''),
        get(candidateComponents, 'streetPostdirection', ''),
        get(candidateComponents, 'streetSuffix', '')
      ])
        .compact()
        .join(' ')
        .value(),
      address2: chain([
        get(candidateComponents, 'secondaryDesignator', ''),
        get(candidateComponents, 'secondaryNumber', '')
      ])
        .compact()
        .join(' ')
        .value(),
      city: get(candidateComponents, 'cityName', ''),
      state: get(states, get(candidateComponents, 'state', ''), ''),
      zip: get(candidateComponents, 'zipCode', ''),
      countryAbbrv
    };
    const isValid = isEqual(
      { address, address2, city, state, zip, countryAbbrv },
      candidateAddress
    );
    return determineStatus(isValid, candidateAddress);
  };

  const didComponentChange = (analysisComponent) => {
    return (
      analysisComponent.includes('SmallChange') ||
      analysisComponent.includes('LargeChange') ||
      analysisComponent.includes('Unrecognized') ||
      analysisComponent.includes('Added')
    );
  };

  const isValidIntlAddress = async ({
    address,
    address2,
    city,
    state,
    zip,
    countryAbbrv
  }) => {
    const lookup = new IntlLookup();
    lookup.address1 = address;
    lookup.address2 = address2;
    lookup.locality = city;
    lookup.administrativeArea = state;
    lookup.postalCode = zip;
    lookup.country = countryAbbrv;

    const response = await intlClient.send(lookup);
    const candidateComponents = get(response, 'result[0].components');
    const candidateAddress = {
      address: chain([
        get(candidateComponents, 'premise', ''),
        get(candidateComponents, 'thoroughfare', '')
      ])
        .compact()
        .join(' ')
        .value(),
      address2: get(candidateComponents, 'subBuilding', ''),
      city: get(candidateComponents, 'locality', ''),
      state: get(candidateComponents, 'administrativeArea'),
      zip: get(candidateComponents, 'postalCode', ''),
      countryAbbrv
    };
    const componentChanges = get(
      response,
      'result[0].analysis.changes.components',
      {}
    );
    const premiseChanged = didComponentChange(
      get(componentChanges, 'premise', '')
    );
    const thoroughfareChanged = didComponentChange(
      get(componentChanges, 'thoroughfare', '')
    );
    const subBuildingChanged = didComponentChange(
      get(componentChanges, 'subBuilding', '')
    );
    const localityChanged = didComponentChange(
      get(componentChanges, 'locality', '')
    );
    const administrativeAreaChanged = didComponentChange(
      get(componentChanges, 'administrativeArea', '')
    );
    const postalCodeChanged = didComponentChange(
      get(componentChanges, 'postalCode', '')
    );
    const isValid = !(
      premiseChanged ||
      thoroughfareChanged ||
      subBuildingChanged ||
      localityChanged ||
      administrativeAreaChanged ||
      postalCodeChanged
    );
    return determineStatus(isValid, candidateAddress);
  };

  const validateAddress = async (targetAddress) => {
    let status = addressStatuses.valid;
    try {
      status =
        targetAddress.countryAbbrv === 'US'
          ? await isValidUsAddress(targetAddress)
          : await isValidIntlAddress(targetAddress);
    } catch (e) {
      setError(e);
    }
    return status;
  };

  return { addressSuggestions, validateAddress, error, addressStatuses };
};

export default useSmartyStreets;
