import { useHeaderData, useQuery } from 'hooks';
import get from 'lodash/get';
import { SPRING_FOR_CREATORS, TEESPRING } from '../constants';

export const useGTM = () => {
  const { user } = useHeaderData();
  const { query } = useQuery();

  const pushToDataLayer = (data) => {
    window.dataLayer = window.dataLayer ?? [];
    window.dataLayer.push(data);
  };

  const pushAccountToDataLayer = () => {
    const source = query.get('from');
    if (source === 'login' || source === 'sign-up') {
      const authMethod = query.get('auth_method');
      const fromSpring = query.get('from_spring') === '1';
      pushToDataLayer({
        event: 'GTMevent',
        eventCategory: 'account',
        eventAction: source,
        eventLabel: `${authMethod}|${
          fromSpring ? SPRING_FOR_CREATORS : TEESPRING
        }`,
        user_id: get(user, 'userId'),
        logged_in_user: 'true'
      });
    }
  };

  return {
    pushToDataLayer,
    pushAccountToDataLayer
  };
};

export default useGTM;
