import * as React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Iframe from './Iframe';
import {
  WIDGET_LAYOUT_HEIGHTS,
  WIDGET_LAYOUTS
} from '../../../constants/widgetLayouts';
import { typographyTheme } from './styles/formThemes';

const IframeContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  color: #bfbfbf;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  overflow: hidden;
  padding-bottom: 6px;
  padding-top: 6px;
`;

const IframeBox = styled.div`
  border: none;
  display: flex;
  border-radius: 24px;
  width: 320px;
  transition: width 0s ease-in, box-shadow 0.2s linear 0.2s;
  box-shadow: 0px 25px 71px rgba(0, 0, 0, 0.07),
    0px 16.2037px 41.581px rgba(0, 0, 0, 0.0531481),
    0px 9.62963px 22.6148px rgba(0, 0, 0, 0.0425185),
    0px 5px 11.5375px rgba(0, 0, 0, 0.035),
    0px 2.03704px 5.78519px rgba(0, 0, 0, 0.0274815),
    0px 0.462963px 2.79398px rgba(0, 0, 0, 0.0168519);
`;

function WidgetPreview(props) {
  const { storeslug, title, page } = props;
  const embedStoreParams = useSelector((state) => state.embedStoreParams);

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <IframeContainer>
        <IframeBox>
          <Iframe
            storeslug={storeslug}
            products={embedStoreParams.productAmount}
            title={title}
            layout={WIDGET_LAYOUTS.carouselSmall}
            theme={embedStoreParams.widgetTheme}
            width='100%'
            height={WIDGET_LAYOUT_HEIGHTS[WIDGET_LAYOUTS.carouselSmall]}
            page={page}
          />
        </IframeBox>
      </IframeContainer>
      <ThemeProvider theme={typographyTheme}>
        <Typography
          variant='caption'
          style={{ maxWidth: '22rem', textAlign: 'center' }}
        >
          Preview is available for the small carousel layout. To preview the other layouts, visit your website builder.
        </Typography>
      </ThemeProvider>
    </div>
  );
}

WidgetPreview.propTypes = {
  storeslug: PropTypes.string.isRequired,
  products: PropTypes.number,
  title: PropTypes.string,
  bgcolor: PropTypes.string,
  txtcolor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  page: PropTypes.number
};

WidgetPreview.defaultProps = {
  products: 6,
  title: '',
  bgcolor: '',
  txtcolor: '',
  width: '',
  height: '',
  page: 1
};

export default WidgetPreview;
