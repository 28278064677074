import React, { useEffect, useState } from 'react';
import {
  Icon,
  bpProps,
  ModalConductor,
  SideNav,
  Tooltip
} from '@teespring/ts-components';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { BRANDED_STORES_EVENT } from 'lib/tracking';
import { useLocation } from 'react-router-dom';
import { useAmplitude, useHeaderData } from 'hooks';
import { string } from 'prop-types';
import { setActiveModal } from 'redux/actions';
import { SPRING_BASE_URL } from '../../../constants';
import ListingActionsModal from './ListingActionsModal';
import ListingShareModal from './ListingShareModal';
import ListingAdminBarActions from './ListingAdminBarActions';
import ListingAdminBarUrl from './ListingAdminBarUrl';
import './ListingAdminBar.scss';

const ListingAdminBar = ({ listingUrl, listingError }) => {
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const { bpIsGT, bpIsLT } = useSelector((state) => bpProps(state));
  const listing = useSelector((state) => state.listing.listing);
  const { modal: modalState } = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  const { header } = useHeaderData();
  const locationPath = useLocation().pathname;
  const { logEvent } = useAmplitude();

  useEffect(() => {
    logEvent(`${BRANDED_STORES_EVENT}.logged_in_tool_bar.viewed`);
  }, [logEvent]);

  const actions = [
    {
      id: 'edit-listing',
      icon: 'Edit3',
      url: `https://teespring.com/dashboard/listings/${get(
        listing,
        'listingId'
      )}/edit`,
      label: 'Edit listing',
      event: `${BRANDED_STORES_EVENT}.logged_in_tool_bar.edit_listing.clicked`
    },
    {
      id: 'order-samples',
      icon: 'ShoppingBag',
      url: `https://teespring.com/samples/${get(listing, 'listingId')}`,
      label: 'Order samples',
      event: `${BRANDED_STORES_EVENT}.logged_in_tool_bar.order_samples.clicked`
    },
    {
      id: 'create-a-product',
      url: `${SPRING_BASE_URL}/design-launcher`,
      label: 'Create a product',
      event: `${BRANDED_STORES_EVENT}.logged_in_tool_bar.create_product.clicked`
    },
    {
      id: 'edit-store',
      icon: 'ShoppingCart',
      routeTo: '/stores',
      label: 'Edit store',
      event: `${BRANDED_STORES_EVENT}.logged_in_tool_bar.edit_store.clicked`
    }
  ];

  return (
    <>
      <div
        className={`spr_sidenav--offcanvas ${sideNavOpen ? 'is-open' : ''} ${
          bpIsGT('mobileLg') ? '' : 'spr_mobilenav__drawer'
        }`}
      >
        <SideNav
          items={get(header, 'items')}
          user={get(header, 'user')}
          collapsed={false}
          activeRoute={locationPath}
          version={2}
          logEvent={logEvent}
          showCollapseControls={false}
        />
      </div>
      <div
        className={`spr_sidenav__overlay ${sideNavOpen ? 'is-open' : ''}`}
        onClick={() => setSideNavOpen(false)}
      />
      <ModalConductor
        activeModal={modalState}
        setActiveModal={(data) => dispatch(setActiveModal(data))}
        modals={[
          {
            id: 'listing-actions-modal',
            node: <ListingActionsModal actions={actions} />
          },
          {
            id: 'listing-share-modal',
            node: <ListingShareModal listingUrl={listingUrl} />
          }
        ]}
      />
      <div className='adminbar'>
        <div className='adminbar__info'>
          <button
            type='button'
            className='adminbar__hamburger'
            onClick={() => setSideNavOpen(true)}
          >
            <Icon name='Menu' />
          </button>

          {listingUrl && <ListingAdminBarUrl listingUrl={listingUrl} />}
        </div>

        {bpIsGT('tabletLg') && !listingError && (
          <ListingAdminBarActions actions={actions.slice(0, 3)} />
        )}

        {bpIsLT('tabletLg') ? (
          <button
            type='button'
            className='adminbar__mobileaction'
            onClick={() =>
              dispatch(
                setActiveModal({ id: 'listing-actions-modal', props: {} })
              )
            }
          >
            <Icon name='MoreVertical' />
          </button>
        ) : (
          <Tooltip
            className='adminbar__mobileaction'
            toggle={<Icon name='MoreVertical' />}
            triggerMethod='click'
            alignment='align-bottom'
            content={
              <ListingAdminBarActions
                actions={actions.slice(3, actions.length)}
              />
            }
          />
        )}
      </div>
    </>
  );
};

ListingAdminBar.propTypes = {
  listingUrl: string,
  listingError: string
};

ListingAdminBar.defaultProps = {
  listingUrl: '',
  listingError: ''
};

export default ListingAdminBar;
