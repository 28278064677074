import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectProduct } from 'redux/actions';
import { CheckboxInput, Icon, bpProps } from '@teespring/ts-components';
import propTypes from 'prop-types';
import get from 'lodash/get';
import find from 'lodash/find';
import './CollectionProductItem.scss';

const CollectionProductItem = ({ product, checked, onChange }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const { bpIsGT, bpIsLT } = useSelector((state) => ({ ...bpProps(state) }));
  const dispatch = useDispatch();

  const thumbnail = find(
    get(product, 'images', []),
    (image) => image.label === 'front'
  );

  const childProducts = get(product, 'products', []);
  const accordionParent = childProducts.length > 1;
  const accordionChild = get(product, 'productType');
  const defaultItem = !accordionParent && !accordionChild;
  const checkboxId = product.productId ? product.productId : product.listingId;
  const deselect = get(product, 'isDeselect', false);

  const productDescription = () => {
    if (get(product, 'product_name')) {
      return get(product, 'product_name');
    } else if (defaultItem) {
      return get(product, 'primaryProductType');
    } else if (accordionChild) {
      return get(product, 'productType');
    } else {
      return `${childProducts.length} products`;
    }
  };

  const classNames = `${accordionChild ? 'collection__accordion-child' : ''} ${
    accordionParent ? 'collection__accordion-parent' : ''
  }`;

  const productType = <span>{productDescription()}</span>;

  return (
    <>
      <div className={`collection__productitem ${classNames}`}>
        {accordionParent && (
          <div
            className='collection__productitem__icon'
            data-testid={product.listingId}
            onClick={() => setAccordionOpen(!accordionOpen)}
          >
            <Icon
              name={`Chevron${accordionOpen ? 'Down' : 'Right'}`}
              size={18}
              className='mr1'
            />
          </div>
        )}
        <div
          className='collection__productitem__toggle'
          onClick={() => {
            onChange();
            setAccordionOpen(!accordionOpen);
          }}
        >
          <div className='collection__productitem__title'>
            <div className='collection__productitem__thumb'>
              <img src={thumbnail?.url ?? thumbnail?.src} alt='' />
            </div>
            <div>
              <h3>{product.title}</h3>
              {bpIsLT('mobileLg') && productType}
            </div>
          </div>
          {bpIsGT('mobileLg') && productType}
        </div>

        <CheckboxInput
          className={`mb0 ${deselect ? 'is-checked' : ''}`}
          manageStateExternally={true}
          checked={checked}
          name={checkboxId}
          id={checkboxId}
          customIcon={deselect ? 'Minus' : ''}
          onChange={onChange}
        />
      </div>
      {accordionParent && accordionOpen && (
        <>
          {product.products.map((variation) => (
            <CollectionProductItem
              key={variation.productId}
              product={{ ...variation, title: product.title }}
              checked={get(variation, 'isChecked', false)}
              onChange={() =>
                dispatch(
                  selectProduct(
                    product.listingId,
                    false,
                    true,
                    variation.productId
                  )
                )
              }
            />
          ))}
        </>
      )}
    </>
  );
};

const { bool, func, shape, string, number, oneOfType } = propTypes;
CollectionProductItem.propTypes = {
  onChange: func.isRequired,
  product: shape({
    id: oneOfType([string, number]),
    name: string,
    thumb: shape({
      src: string,
      alt: string
    })
  }).isRequired,
  checked: bool.isRequired
};

export default CollectionProductItem;
