import React from 'react';
import { object, func } from 'prop-types';
import parse from 'html-react-parser';
import CtaBanner from './CtaBanner';
import AppManagement from './AppManagement';

/**
 * Renders a Tab Content section for general content. Use for any service settings page within the dashboard.
 *
 * @return {object} JSX.Element
 * */
const GeneralTabTemplate = ({
  cta,
  management,
  onLogEvent: handleLogEvent
}) => {
  const {
    backgroundImage: ctaBackground,
    text: ctaText,
    button: ctaButton,
    ...otherCtaProps
  } = cta;
  const {
    title: mediaTitle,
    subtitle: mediaSubtitle,
    content: mediaContent,
    ...otherMediaProps
  } = management;
  return (
    <>
      <CtaBanner
        image={ctaBackground}
        text={ctaText}
        button={ctaButton}
        onLogEvent={handleLogEvent}
        variant='left-aligned-content-on-shrinking'
        {...otherCtaProps}
      />
      <AppManagement
        title={mediaTitle}
        subtitle={mediaSubtitle}
        content={mediaContent}
        onRenderContentList={(item, index) => {
          return (
            <li key={`media-list-item-${index + 1}`} className='flex-row item'>
              <div className='bullet'>{index + 1}</div>
              {parse(item)}
            </li>
          );
        }}
        {...otherMediaProps}
      />
    </>
  );
};

GeneralTabTemplate.propTypes = {
  cta: object.isRequired,
  management: object.isRequired,
  onLogEvent: func.isRequired
};

export default GeneralTabTemplate;
