import React, { useRef, useState } from 'react';
/*import { MoreVertical } from 'react-feather';*/
import { array, func, string } from 'prop-types';
import classnames from 'classnames';
import './Tabs.scss';
import capitalize from 'lodash/capitalize';
import findIndex from 'lodash/findIndex';
import {
  switchHyphenForWhitespace,
  switchWhitespacesForHyphen
} from '../../lib/helpers';

const findIndexOfSelectedTab = (selectedTab, tabs) =>
  findIndex(tabs, (tab) => tab.title === selectedTab);
const findLeftTabTitleOrCurrent = (selectedTab, tabs) => {
  const selectedTabIndex = findIndexOfSelectedTab(selectedTab, tabs);
  const isTheFirstTab = selectedTabIndex === 0;
  return isTheFirstTab ? selectedTab : tabs[selectedTabIndex - 1].title;
};
const findRightTabTitleOrCurrent = (selectedTab, tabs) => {
  const selectedTabIndex = findIndexOfSelectedTab(selectedTab, tabs);
  const isTheLastTab = selectedTabIndex === tabs.length - 1;
  return isTheLastTab ? selectedTab : tabs[selectedTabIndex + 1].title;
};
const findFirstTabTitle = (selectedTab, tabs) => {
  return tabs[0].title;
};
const findLastTabTitle = (selectedTab, tabs) => {
  return tabs[tabs.length - 1].title;
};

const Tabs = ({ tabs, selected, onTabSelected, ...other }) => {
  const sanitizedTabs = tabs.map((tab) => {
    return { ...tab, title: switchWhitespacesForHyphen(tab.title) };
  });
  const [selectedTab, setSelectedTab] = useState(
    selected || sanitizedTabs[0].title
  );
  const [hoveredTab, setHoveredTab] = useState(selectedTab);
  const buttonRefs = useRef({});
  const handleChangeTab = (newSelectedTab) => {
    setSelectedTab(newSelectedTab);
    buttonRefs.current[newSelectedTab].focus();
    onTabSelected(newSelectedTab);
  };
  const handleClickTab = ({ currentTarget }) =>
    handleChangeTab(currentTarget.id);
  const handleMouseEnter = ({ currentTarget }) =>
    setHoveredTab(currentTarget.id);
  const handleMouseLeave = () => setHoveredTab(selectedTab);
  const handleKeyDown = ({ key }) => {
    const keyboardNavigationAction = {
      End: findLastTabTitle,
      Home: findFirstTabTitle,
      ArrowRight: findRightTabTitleOrCurrent,
      ArrowLeft: findLeftTabTitleOrCurrent
    };
    const action = keyboardNavigationAction[key];
    if (action) handleChangeTab(action(selectedTab, sanitizedTabs));
  };
  const isTabHovered = (tabTitle) => tabTitle === hoveredTab;
  const isTabSelected = (tabTitle) => tabTitle === selectedTab;

  return (
    <div className='app-settings__tabs' {...other}>
      <div
        role='tablist'
        className='tabs-menu--horizontal-scrollable'
        aria-label='tabs menu'
      >
        {sanitizedTabs.map((tab) => {
          return (
            <button
              ref={(element) => (buttonRefs.current[tab.title] = element)}
              aria-selected={isTabSelected(tab.title)}
              aria-controls={`${tab.title}-tab`}
              role='tab'
              key={tab.title}
              id={tab.title}
              type='button'
              className={classnames({
                tab: true,
                'tab--overflow-fashioned': true,
                'tab--selected': isTabSelected(tab.title),
                'tab--hovered': isTabHovered(tab.title)
              })}
              onClick={handleClickTab}
              onKeyDown={handleKeyDown}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              tabIndex={isTabSelected(tab.title) ? 0 : -1}
            >
              {capitalize(switchHyphenForWhitespace(tab.title))}
            </button>
          );
        })}
        {/*<button title='collapsed-tabs-menu' type='button' className='collapsed-menu'>
      <MoreVertical />
    </button>*/}
      </div>
      <div className='tab-content-container'>
        {sanitizedTabs.map((tab) => {
          return (
            <div
              id={`${tab.title}-tab`}
              key={`${tab.title}-tab`}
              aria-labelledby={tab.title}
              role='tabpanel'
              tabIndex='0'
              className={
                isTabSelected(tab.title)
                  ? 'tab-content--shown'
                  : 'tab-content--hidden'
              }
            >
              {tab.render}
            </div>
          );
        })}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  tabs: array.isRequired,
  selected: string.isRequired,
  onTabSelected: func.isRequired
};

export default Tabs;
