import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers/rootReducer';
import trackjsMiddleware from './middlewares/trackjsMiddleware';

/* eslint-disable-next-line no-underscore-dangle */
const middleware = [trackjsMiddleware, thunk];

export const storeMiddlewares = applyMiddleware(...middleware);

const store = createStore(rootReducer, composeWithDevTools(storeMiddlewares));

export default store;
