import { bpProps, CheckboxInput } from '@teespring/ts-components';
import ModalTemplate from 'components/ModalTemplate';
import { func } from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useAmplitude, useBpProps, useServiceAgreement } from 'hooks';
import { addToast, fetchHeaderUserData } from 'redux/actions';

import './TermsOfServiceModal.scss';
import moment from 'moment';
import { IS_PRODUCTION } from '../../constants';

export const TERMS_OF_SERVICE_MODAL_ID = 'terms-of-service-modal';

export const TermsOfServiceModal = ({ handleClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const timestamp = moment().toISOString();
  const { deviceType } = useBpProps();
  const { user } = useSelector((state) => state);
  const { bpIsGT } = useSelector((state) => ({ ...bpProps(state) }));
  const { logEvent } = useAmplitude();
  const logTermsOfServiceEvent = useCallback(
    (eventName, route = undefined) =>
      logEvent(`terms_of_service.confirmation_modal.${eventName}`, {
        deviceType,
        userId: user.userId,
        creatorName: user.name,
        dateTime: timestamp,
        page: location.pathname,
        destinationUrl: route
      }),
    [deviceType, location.pathname, logEvent, timestamp, user.name, user.userId]
  );

  const [termsRead, setTermsRead] = useState(false);
  const [isAgreeing, setIsAgreeing] = useState(false);
  useEffect(() => logTermsOfServiceEvent('viewed'), [logTermsOfServiceEvent]);
  const { legalNames, signAgreement } = useServiceAgreement(user);
  const linkClick = (route) => {
    logTermsOfServiceEvent('link.clicked', route);
    history.push(route);
  };
  const agreeToTerms = async () => {
    setIsAgreeing(true);
    try {
      await signAgreement(legalNames.DASHBOARD_TERMS_OF_SERVICE_AGREEMENT);
      await fetchHeaderUserData(user.userId);
      handleClose();

      // Keep appearing to "load" the agree after we have confirmation and use
      // the modal close as the "complete" action.
    } catch (e) {
      // On error, stop the "load" so that the user can try again
      setIsAgreeing(false);
      dispatch(addToast(String(e), 'danger'));
    }
  };

  const currentDomain = IS_PRODUCTION ? 'teespring.com' : 'teedown.com';
  const payoutsLink = `https://${currentDomain}/dashboard/payouts`;

  return (
    <ModalTemplate
      modalTitle='We’ve changed our Terms of Service'
      modalTitleAlign={bpIsGT('mobileLg') ? 'center' : 'left'}
      modalBtnText='Agree and continue'
      modalClose={handleClose}
      modalHideCancelButton={true}
      modalBtnDisabled={!termsRead}
      modalBtnLoading={isAgreeing}
      modalHideCloseButton={true}
      modalBtnAction={async () => {
        logTermsOfServiceEvent('agree.clicked');
        await agreeToTerms();
      }}
      modalContent={
        <div className='terms_of_service__wrapper'>
          <div className='terms_of_service__content'>
            Spring’s{' '}
            <a
              onClick={() =>
                linkClick(
                  '/policies/spring-terms-of-service?section=terms-of-service'
                )
              }
              className='typ--link'
              target='_blank'
            >
              Terms Of Service
            </a>{' '}
            have changed. By clicking “agree and continue”, you acknowledge you
            have read and agree to the new{' '}
            <a
              onClick={() =>
                linkClick(
                  '/policies/spring-terms-of-service?section=terms-of-service'
                )
              }
              className='typ--link'
              target='_blank'
            >
              Terms
            </a>{' '}
            and{' '}
            <a
              onClick={() =>
                linkClick('/policies/spring-terms-of-service?section=privacy')
              }
              className='typ--link'
              target='_blank'
            >
              Privacy Policy
            </a>
            . <br />
            <br />
            If you would like to deactivate your Spring account, please contact{' '}
            <a
              className='typ--link-quiet'
              onClick={() => {
                logTermsOfServiceEvent('link.clicked', 'email.support');
                window.location.href = 'mailto:creatorhelp@spri.ng';
              }}
            >
              creatorhelp@spri.ng
            </a>{' '}
            after collecting your{' '}
            <a
              onClick={() => {
                logTermsOfServiceEvent('link.clicked', payoutsLink);
                window.location.href = payoutsLink;
              }}
              className='typ--link-quiet'
              target='_blank'
            >
              payouts
            </a>{' '}
            and canceling your{' '}
            <a
              onClick={() => linkClick('/purchases/subscriptions')}
              className='typ--link-quiet'
              target='_blank'
            >
              subscriptions
            </a>
            .
          </div>
          <div className='terms-checkbox'>
            <CheckboxInput
              id='terms-read'
              name='terms-read'
              checked={termsRead}
              onChange={(checked) => setTermsRead(checked)}
              title='I have read, understand, and agree to Spring’s Terms of Service.'
            />
          </div>
        </div>
      }
    />
  );
};

TermsOfServiceModal.propTypes = {
  handleClose: func.isRequired
};

export default TermsOfServiceModal;
