import { array } from 'prop-types';
import '../IntegrationsPage.scss';

export const IntegrationsPageSlider = ({ imageUrlArray }) => {
  return (
    <div className='integrations_banner'>
      {imageUrlArray?.map((imageUrl, index) => (
        <img
          style={{
            borderRadius: '16px',
            width: '220px',
            marginRight: index === 3 ? '0px' : '13.33px'
          }}
          src={imageUrl}
          alt='integrations page slide'
          key={imageUrl}
        />
      ))}
    </div>
  );
};

IntegrationsPageSlider.propTypes = {
  imageUrlArray: array.isRequired
};

export default IntegrationsPageSlider;
