import axios from 'axios';
import get from 'lodash/get';
import map from 'lodash/map';
import isString from 'lodash/isString';
import psl from 'psl';
import { DNS_STATUS, NETLIFY_STATUS } from 'constants/customDomainStatus';
import { generateClientToken } from './auth';
import { GRAPHQL_API_HOST } from '../constants';

/**
 * Combines the given store slug and custom url into the format that
 * the StoreCustomDomain url is stored in the databases
 *
 * @param  {string} _slug     DEPRECATED: The store slug
 * @param  {string} url       The custom url, given by the user
 * @return {string}           Returns the formatted StoreCustomDomain url
 */
export const customDomainUrlGenerator = (_slug, url) => url;

/**
 * Formats the headers necessary for a validated API request through axios
 *
 * @param {string} token  The X-CSRF-Token to be passed into the request headers
 * @return {!Object}          An object containing all the appropriate request headers for a validated API request
 */
export const formatAxiosRequestHeaders = (token, bearer = undefined) => {
  const req = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true,
    crossDomain: true
  };
  if (token) req.headers['X-CSRF-Token'] = token;
  if (bearer) req.headers.authorization = `${bearer.trim()}`;
  return req;
};

const newSubscriptionServiceHeaders = (userId) => {
  const token = generateClientToken(userId);
  const bearer = `Bearer ${token}`;

  const headers = formatAxiosRequestHeaders(undefined, bearer);
  return headers;
};

export const getUserStoreDomains = async (user, storeId) => {
  if (!user) return null;
  const { userId } = user;

  if (!userId || !storeId) return null;
  const url = GRAPHQL_API_HOST;
  const queryName = 'GetUserStoreDomains';
  const payload = {
    query: `query GetUserStoreDomains($userId: String!, $storeId: String!) {
      getUserStoreDomains(userId: $userId, storeId: $storeId) {
        user_store_domains {
          dns_verification_status
          facebook_pixel_verification_code
          id
          is_registered_with_spring
          netlify_build_error
          netlify_build_status
          netlify_site_id
          primary
          status
          store_id
          url
          user_id
        }
      }
    }`,
    variables: {
      userId: `${userId}`,
      storeId: `${storeId}`
    },
    operationName: queryName
  };

  const headers = newSubscriptionServiceHeaders(userId);
  const { data } = await axios.post(url, payload, headers);
  return data?.data?.getUserStoreDomains?.user_store_domains;
};

export const getNetlifyBuildStatus = async (siteId, user) => {
  if (!siteId) return 'build_pending';
  const { userId } = user;
  const url = GRAPHQL_API_HOST;
  const queryName = 'GetBuildStatus';
  const payload = {
    query: `query ${queryName}($siteId: String!) {\n  siteBuildStatus(siteId: $siteId) {\n    deploy_id\n    done\n    error\n    deploy_state\n  }\n}\n`,
    variables: {
      siteId: `${siteId}`
    },
    operationName: `${queryName}`
  };
  const headers = newSubscriptionServiceHeaders(userId);
  const result = await axios.post(url, payload, headers);
  if (result.data.errors) return 'build_pending';
  else {
    const buildStatus = result.data.data?.siteBuildStatus;
    if (buildStatus.deploy_state === 'ready' && buildStatus.done === true)
      return 'build_success';
    else if (buildStatus.error) return 'build_failed';
    else return 'build_pending';
  }
};

export const updateBuildStatus = async (customDomains) => {
  if (customDomains && customDomains.constructor === Array) {
    return await Promise.all(
      customDomains.map(async (domain) => {
        const buildStatus = await getNetlifyBuildStatus(domain.netlify_site_id);
        if (!buildStatus) return domain;
        return Object.assign(domain, { netlify_build_status: buildStatus });
      })
    );
  }
  return customDomains;
};

export const getDnsVerificationStatus = async (
  domain,
  user,
  buildSite = false
) => {
  if (!domain) return null;
  const { userId } = user;
  const url = GRAPHQL_API_HOST;
  const queryName = 'GetDNSVerificationStatus';
  const payload = {
    query: `
      query GetDNSVerificationStatus($buildSite: Boolean!, $hostname: String!) {
        getDNSVerificationStatus(buildSite: $buildSite, hostname: $hostname) {
          dnsVerificationStatus
        }
    }
    `,
    variables: {
      buildSite,
      hostname: `${domain}`
    },
    operationName: `${queryName}`
  };
  const headers = newSubscriptionServiceHeaders(userId);
  const { data } = await axios.post(url, payload, headers);
  return data?.data?.getDNSVerificationStatus?.dnsVerificationStatus;
};

export const updateDnsVerificationStatus = async (customDomains) => {
  if (customDomains && customDomains.constructor === Array) {
    return await Promise.all(
      customDomains.map(async (domain) => {
        const dnsVerificationStatus = await getDnsVerificationStatus(
          domain.domain
        );
        if (!dnsVerificationStatus) return domain;
        return Object.assign(domain, {
          dns_verification_status: dnsVerificationStatus
        });
      })
    );
  }
  return customDomains;
};

export const getDnsVerificationStatusForVerifyDomain = async (
  domain,
  buildSite,
  storeId,
  siteName,
  userId,
  email
) => {
  if (!domain) return null;
  const url = GRAPHQL_API_HOST;
  const queryName = 'GetDNSVerificationStatusVerifyDomain';
  const payload = {
    query: `
    query GetDNSVerificationStatusVerifyDomain($buildSite: Boolean!, $storeId: String!, $userId: String!, $email: String!, $siteName: String!, $hostname: String!) {
      getDNSVerificationStatusVerifyDomain(buildSite: $buildSite, storeId: $storeId, userId: $userId, email: $email, siteName: $siteName, hostname: $hostname) {
        dnsVerificationStatus
      }
    }
    `,
    variables: {
      buildSite,
      hostname: `${domain}`,
      userId: `${userId}`,
      email,
      siteName,
      storeId: `${storeId}`
    },
    operationName: `${queryName}`
  };
  const headers = newSubscriptionServiceHeaders(userId);
  const { data } = await axios.post(url, payload, headers);
  return data?.data?.getDNSVerificationStatusVerifyDomain
    ?.dnsVerificationStatus;
};

export const getRegistrantVerificationStatus = async (domain, user) => {
  if (!domain) return null;
  // For .co domains they are never verified (per opensrs) - no need to check this.
  let openSRSStatusResponse;
  if (psl.parse(domain).tld === 'co') {
    openSRSStatusResponse = {
      daysToSuspend: 0,
      emailBounced: 0,
      verificationDeadline: '',
      verificationStatus: 'verified'
    };
  } else {
    const { userId } = user;
    const url = GRAPHQL_API_HOST;
    const queryName = 'GetRegistrantVerificationStatus';
    const payload = {
      query: `
    query GetRegistrantVerificationStatus($domain: String!) {
      getRegistrantVerificationStatus(domain: $domain) {
        daysToSuspend
        emailBounced
        verificationDeadline
        verificationStatus
      }
    }
    `,
      variables: {
        domain
      },
      operationName: `${queryName}`
    };
    const headers = newSubscriptionServiceHeaders(userId);
    const result = await axios.post(url, payload, headers);
    if (result.data.errors) {
      const errorMessage = result.data.errors[0].message;
      throw new Error(errorMessage);
    }
    openSRSStatusResponse =
      result.data.data?.getRegistrantVerificationStatus || {};
  }

  const statusObj = {
    data: {
      domain,
      email: {
        code: openSRSStatusResponse.verificationStatus,
        details: {
          deadlineDate: openSRSStatusResponse.verificationDeadline,
          deadlineRemainingDays: openSRSStatusResponse.daysToSuspend,
          emailDeliveryStatus: openSRSStatusResponse.emailBounced
        }
      }
    }
  };
  return statusObj;
};

export const deleteDomain = async (domainId, storeId, netlifySiteId, user) => {
  const { userId } = user;
  const url = GRAPHQL_API_HOST;
  const queryName = 'DeleteCustomDomain';
  const payload = {
    query: `
      mutation DeleteCustomDomain($netlifySiteId: String!, $storeId: String!, $domainId: String!) {
        deleteCustomDomain(netlifySiteId: $netlifySiteId, storeId: $storeId, domainId: $domainId) {
          domainId
        }
      }
      `,
    variables: {
      storeId: `${storeId}`,
      domainId: `${domainId}`,
      netlifySiteId: `${netlifySiteId}`
    },
    operationName: `${queryName}`
  };
  const headers = newSubscriptionServiceHeaders(userId);
  await axios.post(url, payload, headers);
};
/**
 * Converts the color state object from the ColorPicker
 * into a color string accepted in css.
 *
 * If alpha channel is < 1, use rgba(), otherwise, use hex
 * @param {*} color color value
 * @returns {string} color string
 */
export const generateColorStr = (color) => {
  let colorStr = get(color, 'hex');

  if (get(color, 'rgb.a') < 1) {
    const rgb = get(color, 'rgb');
    if (rgb) colorStr = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`;
  }

  return colorStr;
};

/**
 * Gets the top level domain from a given domain
 *
 * @param {String} domain the domain to test
 * @return {String} returns the domain extension
 */
export const getDomainExtension = (domain) => {
  const { tld } = psl.parse(domain);
  return tld;
};

/**
 * Takes an object containing the dnsStatus and netlifyStatus
 * for a domain, and returns an integer representing it's state
 *
 * @param {string} dnsStatus      A string representing the dns build status
 * @param {string} netlifyStatus  A string representing the netlify build status
 * @return {number}               A numerical representation of the domain status
 */
export const getDomainStatus = (dnsStatus, netlifyStatus) => {
  switch (DNS_STATUS[dnsStatus]) {
    case DNS_STATUS.dns_pending:
      return 0;
    case DNS_STATUS.dns_failed:
      return 1;
    case DNS_STATUS.dns_success:
      switch (NETLIFY_STATUS[netlifyStatus]) {
        case NETLIFY_STATUS.build_pending:
          return 2;
        case NETLIFY_STATUS.build_failed:
          return 3;
        case NETLIFY_STATUS.build_success:
          return 4;
        default:
          break;
      }
      return -1;
    default:
      return -1;
  }
};

export const getFormAttrValueOrDefault = (attr, defaultValue) =>
  attr ? attr.value : defaultValue;

export const getRootEditPath = (pathname) => {
  const root = pathname.split('/edit')[0];
  return `${root}/edit`;
};

/**
 * Gets the subdomain from a given domain, assuming the subdomain is not 'www'
 *
 * @param {String} domain the domain to test
 * @return {?String} returns a subdomain string, if it's not www, or null
 */
export const getSubdomain = (domain) => {
  const { subdomain } = psl.parse(domain);
  if (subdomain && subdomain !== 'www') {
    return subdomain;
  }

  return null;
};

/**
 * Checks if path contains a valid image extension
 *
 * @param {String} imagePath path for an image
 * @return {Boolean} returns a true or false
 */
export const hasValidImageExtension = (imagePath) =>
  /\.(gif|jpe?g|JPE?G|tiff?|png|PNG|webp|bmp)$/i.test(imagePath);

/**
 * Takes on object and checks if each key has a value
 *
 * @param {object} obj a regular js object
 * @return {boolean}  returns true or false
 */
export const isObjectEmpty = (obj) => {
  const values = Object.values(obj);
  if (values.length === 0) return true;
  return values.every((val) => !val);
};

/**
 * Declares if this domain includes a subdomain, assuming the subdomain is not 'www'
 *
 * @param {String} domain the domain to test
 * @return {Boolean} returns true or false
 */
export const isSubdomain = (domain) => !!getSubdomain(domain);

/**
 * Slices off the trailing slash from the pathname, if present
 *
 * @param  {string} pathname  The pathname from the location hook
 * @return {string}           Returns the pathname without a trailing slash
 */
export const pathnameWithoutTrailingSlash = (pathname) => {
  return pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;
};

/**
 * Adds the http:// or https:// prefix to a valid url that does not already include it
 * @param {String} url   url string
 * @return {string}      Returns url string with http:// prepended
 */
export const prependHttp = (url) => {
  if (typeof url !== 'string') return url;

  const httpRegex = RegExp('^https?://');
  const hasHttp = httpRegex.test(url);
  return hasHttp ? url : `http://${url}`;
};

/**
 * Adds and s to the string if the amount of items is more than one
 * @param {Number} num   the amount of items
 * @param {String} str   the string to pluralize
 * @return {string}      Returns a string that contains the amount of items and the string pluralize if needed
 */
export const pluralize = (num, str) => {
  return `${num} ${str}${num > 1 || num === 0 ? 's' : ''}`;
};

/**
 * Adds the http:// or https:// prefix to a valid url that does not already include it
 * @param {String} username   users name
 * @return {object}      Returns users name separated by first and last
 */
export const formatName = (username) => {
  if (typeof username !== 'string' || !username) return username ?? '';
  const nameParts = username.replace(/[^a-zA-Z ]/g, ' ').split(' ');
  const lastName = nameParts.pop();
  const firstName = nameParts.join(' ');
  return { firstName, lastName };
};

// Image Helpers

/**
 *
 * @param {String} fileName
 * @param {String} storeSlug
 * @param {String} assetFolder
 *
 * @returns {String} returns the url page for an image
 */

export const getMediaPath = (fileName, storeSlug, assetFolder = 'media') => {
  if (fileName.includes('cloudfront')) {
    return fileName;
  }

  const rootPath =
    process.env.REACT_APP_S3_PATH ||
    'https://premium-storefronts.s3.amazonaws.com/storefronts';
  return `${rootPath}/${storeSlug}/${assetFolder}/${fileName}`;
};

/**
 *
 * @param {Object} formState
 * @param {String} field
 *
 * @returns {String} return the value of the field
 */

export const getImageUrl = (formState = {}, field) =>
  get(formState, `['${field}']value`, '');

/**
 *
 * @param {String} val
 * @param {Object} store
 *
 * @returns {String} returns the the original value or a full media path if the field is an image field
 */

export const processValue = (val, store) => {
  let tempVal = val;
  if (
    (isString(tempVal) && tempVal.includes('{{assetPath}}')) ||
    hasValidImageExtension(tempVal)
  ) {
    // Dont process images that have the s3 url populated
    if (!tempVal.includes('media') && !tempVal.includes('assets')) {
      const hasAssetPath = tempVal.includes('{{assetPath}}');
      const assetPath = hasAssetPath ? 'assets' : 'media';
      const fileName = hasAssetPath
        ? tempVal.split('{{assetPath}}/')[1]
        : tempVal;
      tempVal = getMediaPath(fileName, get(store, 'slug'), assetPath);
    }
  }
  return tempVal;
};

export const processCollectionItemSlug = (slug, pid) => {
  const formattedUrl = slug && slug.match('/');
  return formattedUrl ? slug : `/${slug}?pid=${pid}`;
};

export const formatStoresForDropdown = (stores) =>
  map(stores, (store) => ({
    id: store.id,
    label: store.name
  }));

export const parseQueryStrings = (querystring) => {
  const params = new URLSearchParams(querystring);

  const obj = {};

  // iterate over all keys
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key);
    } else {
      obj[key] = params.get(key);
    }
  }

  return obj;
};

export const switchWhitespacesForHyphen = (string) =>
  string.replace(/\s+/g, '-');
export const switchHyphenForWhitespace = (string) => string.replace(/-+/g, ' ');

export const getDeviceWithBpIsLT = (bpIsLT) => {
  if (bpIsLT('mobileLg')) {
    return 'mobile';
  } else if (bpIsLT('tabletLg')) {
    return 'tablet';
  } else {
    return 'desktop';
  }
};

export const isNotDigitalListing = (listing) =>
  get(listing, 'primaryProduct[0].sizes[0].label', '') !== 'Digital';

export const isNotDigitalProduct = (product) =>
  get(product, 'deliveryDetails.type', '') !== 'digital';
