import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchListing } from 'redux/actions';
import { usePreviewUrl } from 'hooks';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import merge from 'lodash/merge';

export const useListings = (listingData) => {
  const store = useSelector((state) => state.store);
  const user = useSelector((state) => state.user);
  const listing = useSelector((state) => state.listing.listing);
  const listingError = useSelector((state) => state.listing.error);

  let storeForPreview = store;
  let activeStore;

  if (store.slug !== listing.activeStoreSlug) {
    activeStore = user.stores.find((s) => s.slug === listing.activeStoreSlug);

    if (activeStore) {
      storeForPreview = merge(activeStore, {
        slug: activeStore.slug
      });
    }
  } else {
    activeStore = store;
  }

  const { previewUrl } = usePreviewUrl(
    storeForPreview,
    null,
    true,
    `listing/${get(listing, 'url')}`
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      (listingData.sku || listingData.listingId) &&
      get(store, 'name') &&
      (isEmpty(listing) ||
        listing.listingId !== parseInt(listingData.listingId) ||
        listing.itemGroupId !== listingData.sku)
    ) {
      dispatch(fetchListing(listingData));
    }
  }, [dispatch, listing, listingData, store]);

  return { listing, previewUrl, listingError, activeStore };
};

export default useListings;
