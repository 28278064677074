import jwt from 'jsonwebtoken';

/**
 * Build JWT for local user
 *
 * @param {*} userId
 * @returns
 */
export function generateClientToken(userId) {
  return jwt.sign(
    { userId },
    process.env.REACT_APP_ONBOARDING_SERVICE_SECRET || 'secret'
  );
}
