import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Icon, bpProps } from '@teespring/ts-components';
import { useSelector } from 'react-redux';
import map from 'lodash/map';
import get from 'lodash/get';
import { pluralize } from 'lib/helpers';
import { DASHBOARD_COLLECTIONS_EVENT } from 'lib/tracking';
import { useAmplitude } from 'hooks';
import './Collections.scss';

export const Collections = () => {
  const collections = useSelector((state) => state.collections);
  const { bpIsLT, setClass } = useSelector((state) => ({ ...bpProps(state) }));
  const isMobileScreen = bpIsLT('mobileLg');
  const { logEvent } = useAmplitude();

  useEffect(() => {
    logEvent(`${DASHBOARD_COLLECTIONS_EVENT}.viewed`);
  }, [logEvent]);

  const collectionDescription = (collection) => {
    return isMobileScreen
      ? pluralize(collection.totalItems, 'product')
      : get(collection, 'description');
  };

  return (
    <>
      <div className='collections__intro'>
        {isMobileScreen ? (
          <h2 className='mb1 collections__subtitle'>My collections</h2>
        ) : (
          <h1 className='mt1 mb1'>Collections</h1>
        )}
        <p className='collections__description'>
          Create groups of products, whether by the products properties (e.g.
          type, creation date, design) or manually defined by you.
        </p>
      </div>

      <div className={setClass({ default: 'mt4', mobileLg: 'mt3' })}>
        <h2
          className={setClass({
            default: 'mb1 collections__subtitle',
            mobileLg: 'hide'
          })}
        >
          My collections
        </h2>

        <ul className='collectionsfeed__header mb1'>
          <li>Collection</li>
          <li className='collectionsfeed__header__products typ--center'>
            Products
          </li>
        </ul>

        <ul className='collectionsfeed'>
          {map(Object.values(collections), (collection) => (
            <li className='collectionsfeed__item' key={collection.id}>
              <div className='collectionsfeed__item__info'>
                <div className='collectionsfeed__item__name'>
                  <h2>{`${collection.name}`}</h2>
                  <p className='mb0'>{get(collection, 'storeName')}</p>
                  <p className='mb0'>{collectionDescription(collection)}</p>
                </div>

                <div className='collectionsfeed__item__productcount'>
                  {collection.totalItems}
                </div>
              </div>

              <div className='collectionsfeed__item__edit'>
                <Link
                  data-testid={collection.id}
                  to={`collections/${collection.id}`}
                  onClick={() =>
                    logEvent(`${DASHBOARD_COLLECTIONS_EVENT}_edit.clicked`)
                  }
                >
                  <Icon name='Edit3' size={16} />
                </Link>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Collections;
