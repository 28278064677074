import React from 'react';
import PropTypes from 'prop-types';

export const YoutubeDisconnectButton = ({handleClick}) => <button type='button' onClick={handleClick}>
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M7.00024 11L3.91077 14.0895C2.34 15.6602 2.34001 18.2069 3.91077 19.7777V19.7777C5.48153 21.3485 8.02824 21.3485 9.599 19.7777L12.6885 16.6882'
            stroke='#08080D'
            strokeWidth='1.5'
        />
        <path
            d='M16.7546 12.6218L19.8441 9.53235C21.4149 7.96159 21.4149 5.41488 19.8441 3.84412V3.84412C18.2734 2.27335 15.7266 2.27335 14.1559 3.84411L11.0664 6.93359'
            stroke='#08080D'
            strokeWidth='1.5'
        />
        <line
            x1='5.03033'
            y1='4.96967'
            x2='19.0303'
            y2='18.9697'
            stroke='black'
            strokeWidth='1.5'
        />
    </svg>
    <span>Disconnect YouTube Account</span>
</button>;
YoutubeDisconnectButton.propTypes = {
    handleClick: PropTypes.func.isRequired
};
