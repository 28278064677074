import {
  RECEIVE_GUIDES_AND_TIPS_DATA,
  RECEIVE_WHATS_NEW_DATA
} from 'redux/actions';

const INITIAL_STATE = {
  guidesAndTips: [],
  whatsNew: []
};

/**
 * Get Overview data from S3
 * @param  {Object} state  Redux State
 * @param  {Object} action Action data
 * @return {Object}        Returns the state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECEIVE_GUIDES_AND_TIPS_DATA:
      return { ...state, guidesAndTips: action.data };
    case RECEIVE_WHATS_NEW_DATA:
      return { ...state, whatsNew: action.data };
    default:
      return state;
  }
};
