import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserSubscriptions } from 'redux/actions';
import { useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

const subscriptionHasActiveEquivalent = (
  subscription,
  activeSubscriptionsHash
) => {
  const subscriptionToolName = (subs) => subs.creatorToolPrice.creatorTool.name;
  const areSubscriptionsOfTheSameKind = (a, b) =>
    subscriptionToolName(a) === subscriptionToolName(b);
  const isCustomDomain = (subs) =>
    subscriptionToolName(subs) === 'Custom domain';
  const customDomainDomainId = (customDomain) =>
    customDomain.metadata.data.storeCustomDomainId;
  const customDomainsHasTheSameDomain = (customDomainA, customDomainB) =>
    customDomainDomainId(customDomainA) === customDomainDomainId(customDomainB);
  const activeSubscriptions = Object.values(activeSubscriptionsHash);
  return activeSubscriptions.some((activeSubscription) => {
    return isCustomDomain(subscription) && isCustomDomain(activeSubscription)
      ? customDomainsHasTheSameDomain(subscription, activeSubscription)
      : areSubscriptionsOfTheSameKind(activeSubscription, subscription);
  });
};

const useUserSubscriptions = (userId, secondCall = false) => {
  const { newSubServiceGetUserSubscriptions } = useFlags();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { active, inactive } = useSelector((state) => state.subscriptions);
  const inactiveWithoutAnActiveEquivalent = inactive
    ? Object.fromEntries(
        Object.entries(inactive).filter(
          ([, subscription]) =>
            !subscriptionHasActiveEquivalent(subscription, active)
        )
      )
    : {};
  const [isFetchingUserSubscriptions, setIsFetchingUserSubscriptions] =
    useState(false);

  useEffect(() => {
    const getUserSubscriptions = async () => {
      dispatch(
        fetchUserSubscriptions(userId, newSubServiceGetUserSubscriptions)
      );
    };
    setIsFetchingUserSubscriptions(true);
    getUserSubscriptions();
    // Adding second delayed call to get subscriptions as payment processing can
    // sometimes take longer than expected causing newly purchased subscriptions
    // to not apear immediately
    if (secondCall) {
      const delayCall = setTimeout(async () => {
        await getUserSubscriptions();
        clearTimeout(delayCall);
      }, 2000);
    }
    setIsFetchingUserSubscriptions(false);
  }, [dispatch, userId, pathname, secondCall]);

  return {
    active,
    inactive: inactiveWithoutAnActiveEquivalent,
    isFetchingUserSubscriptions
  };
};

export default useUserSubscriptions;
