import { object, string } from 'prop-types';
import { Link } from 'react-router-dom';
import './HeaderCopy.scss';

/**
 * This component generates the copy for the payment modal. It produces 2
 * different formats based on what is passed in:
 *
 * * Recurring, no listing
 *   * Activate your subscription to TOOL at PRICE/INTERVAL.
 * * Non-recurring, no listing
 *   * Purchase TOOL for COUNT INTERVAL for PRICE.
 */
const HeaderCopy = ({ priceOption, subName }) => {
  const embolden = (text) => <span className='bold'>{text}</span>;

  const price = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: priceOption.currency,
    minimumFractionDigits: 2
  }).format(priceOption.price / 100);

  const priceText = priceOption.recurring ? (
    <span>at {embolden(`${price}/${priceOption.interval}`)}</span>
  ) : (
    <span>
      for {embolden(priceOption.count)} {embolden(priceOption.interval)}(s) at{' '}
      {embolden(price)}
    </span>
  );

  const productText = <span>{embolden(subName)}</span>;

  const actionText = priceOption.recurring ? (
    <span data-testid='action-text'>
      Activate your subscription to {productText} {priceText}.
    </span>
  ) : (
    <span data-testid='action-text'>
      Purchase {productText} {priceText}.
    </span>
  );

  const subscriptionNotice = priceOption.recurring ? (
    <span>
      Your subscription will automatically renew using the default payment
      method provided. You can cancel this subscription at any time within the{' '}
      <Link
        className='header-copy__link typ--link'
        to='/purchases/subscriptions'
      >
        Payment section
      </Link>{' '}
      of your account.
    </span>
  ) : (
    <span />
  );

  return (
    <div className='header_copy__prompt'>
      <h2>Payment</h2>
      <p className='mt1 mb0'>
        {actionText}
        <span> </span>
        {subscriptionNotice}
      </p>
    </div>
  );
};

HeaderCopy.propTypes = {
  priceOption: object.isRequired,
  subName: string.isRequired
};

export default HeaderCopy;
