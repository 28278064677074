import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSearchResults, processListings } from 'redux/actions';
import axios from 'axios';
import { API_HOST } from '../constants';

/**
 * right now this is solely meant for listings
 * if ever want to expand this to other parts of the app, make useTypeahead accept a baseUri param
 * we can always move all the listing specific logic into its own typeahead called useListingsTypeahead
 */
const BASE_URI = `${API_HOST}/api/v1/user/listings`;

const useTypeahead = (setInRedux = false, needsProcess = false) => {
  const userId = useSelector((state) => state.user.userId);
  const [typeaheadResults, setTypeaheadResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [uri, setUri] = useState('');
  const dispatch = useDispatch();

  const setResultsInState = useCallback(
    (data) => {
      dispatch(setSearchResults(data));
    },
    [dispatch]
  );

  const search = (searchString) => {
    if (!searchString) {
      if (setInRedux) {
        setResultsInState([]);
      } else {
        setTypeaheadResults([]);
      }

      setUri('');
      setLoading(false);
    } else {
      setUri(`${BASE_URI}?userId=${userId}&searchTerm=${searchString}`);
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    let needsCancel = true;

    const fireSearch = async () => {
      if (uri) {
        setLoading(true);
        try {
          const {
            data: { listings }
          } = await axios.get(uri, {
            cancelToken: cancelToken.token
          });

          needsCancel = false;

          if (setInRedux) {
            setResultsInState(listings);
          } else {
            const results = needsProcess
              ? await processListings(listings)
              : listings;
            setTypeaheadResults(results);
          }

          setLoading(false);
        } catch (err) {
          if (axios.isCancel(err)) needsCancel = true;

          setLoading(false);
          setError(err);
        }
      }
    };

    fireSearch();

    const cleanup = () => {
      if (needsCancel) cancelToken.cancel();
    };
    return cleanup;
  }, [uri, setResultsInState, needsProcess, setInRedux]);
  return { loading, error, search, typeaheadResults };
};

export default useTypeahead;
