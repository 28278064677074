import { object } from 'prop-types';
import { useAmplitude } from 'hooks';
import { useEffect } from 'react';
import OrderSummaryCartItem from '../OrderSummaryCartItem';
import './OrderSummarySidebar.scss';

const OrderSummarySidebar = ({ cart }) => {
  const items = Object.values(cart);
  const { logEvent } = useAmplitude();
  useEffect(() => {
    logEvent('samples.order_summary.viewed');
  }, [logEvent]);

  return (
    <aside className='standard__layout__aside samples__summary__aside'>
      <div className='order__summary__container'>
        <h1 className='order__summary__header'>Order summary</h1>
        <div className='order__summary__cart'>
          {items.map((item) => {
            return <OrderSummaryCartItem key={item.id} item={item} />;
          })}
        </div>
      </div>
    </aside>
  );
};

OrderSummarySidebar.propTypes = {
  cart: object.isRequired
};

export default OrderSummarySidebar;
