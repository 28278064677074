import get from 'lodash/get';
import map from 'lodash/map';
import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { fetchThemeConfig, fetchCollections } from 'redux/actions';
import { FLAGS } from 'lib/featureFlags';
import { API_HOST, COMMERCE_API_HOST } from '../../../constants';
import { addToast } from '../toast';
import { setUnauthorizedUser, setUserStoreNotFound } from '../user';

export const RECEIVE_STORE = 'RECEIVE_STORE';
export const SET_STORE_VERSION = 'SET_STORE_VERSION';
export const SET_UPDATING_STORE = 'SET_UPDATING_STORE';
export const SET_USER_STORES = 'SET_USER_STORES';
export const SET_INFRINGEMENT_CHECK = 'SET_INFRINGEMENT_CHECK';

const HEADERS = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
};

//Helper
const formatStorePayload = (updatedStore, state) => {
  return {
    title: get(updatedStore, 'title', state.name) || '',
    description: get(updatedStore, 'description', state.description) || '',
    faviconUrl: get(updatedStore, 'faviconUrl', state.faviconUrl) || '',
    logoUrl: get(updatedStore, 'logoUrl', state.logoUrl) || '',
    storeUrl: get(updatedStore, 'slug', state.slug) || '',
    faviconImage: get(updatedStore, 'faviconImage', state.faviconImage) || '',
    logoImage: get(updatedStore, 'logoImage', state.logoUrl) || ''
  };
};

export const setUserStores = (userStores) => {
  return {
    type: SET_USER_STORES,
    userStores
  };
};

export const resetInfringementCheck = () => {
  return {
    type: SET_INFRINGEMENT_CHECK,
    infringementCheckComplete: false
  };
};

export const updateCurrentStores = (updatedStore) => (dispatch, getState) => {
  const currentStores = getState().user.stores.slice();
  const updated = map(currentStores, (store) => {
    if (store.id === updatedStore.id) {
      return {
        id: updatedStore.id,
        name: updatedStore.name,
        slug: updatedStore.slug,
        // The updated response does not return a primary url
        primaryUrl: store.primaryUrl
      };
    } else {
      return store;
    }
  });

  dispatch(setUserStores(updated));
};

export const updateStore = (updatedStore) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_UPDATING_STORE,
      isUpdating: true
    });
    const { store, user } = getState();
    const storeNameChanged =
      updatedStore?.title && store?.name !== updatedStore?.title;

    if (storeNameChanged) {
      const csrfToken = user.token;
      const url = `${API_HOST}/api/v1/stores/update_theme?storeId=${store.id}`;
      const { data } = await axios.put(
        url,
        formatStorePayload(updatedStore, store),
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
          },
          withCredentials: true
        }
      );

      // Errors come back from the API as an object where the key is the attribute
      // that had errors on update and the value is an array of messages.
      const errors = data.errors ?? {};
      Object.keys(errors).forEach((attribute) => {
        errors[attribute].forEach((message) => {
          dispatch(addToast(message), 'danger');
        });
      });

      dispatch({
        type: RECEIVE_STORE,
        storeData: data,
        isUpdating: false
      });
      dispatch(updateCurrentStores(data));
    }
  } catch (err) {
    dispatch({
      type: SET_UPDATING_STORE,
      isUpdating: false
    });
    dispatch(
      addToast(
        `Failed to update store: ${
          err?.response?.data?.message ?? err.message
        }`,
        'danger'
      )
    );
  } finally {
    dispatch({
      type: SET_INFRINGEMENT_CHECK,
      infringementCheckComplete: true
    });
    dispatch({
      type: RECEIVE_STORE,
      isUpdating: false
    });
  }
};

/**
 * Finished store request
 * @param  {number} storeId   The id from the url of the store to fetch
 * @param  {object} data      The store data
 * @return {func}             The dispatch function
 */
export const receiveStore = (storeId, data) => (dispatch) => {
  dispatch({
    type: RECEIVE_STORE,
    storeId,
    storeData: data
  });
};

export const setStoreVersion = (storeVersion) => {
  return {
    type: SET_STORE_VERSION,
    storeVersion
  };
};

/**
 * Finishing request for collections
 * @return {function}           The dispatch function
 * @param {object} collections The collections data for the store
 */
// export const receiveCollections = (collections) => (dispatch) => {
//   dispatch({
//     type: RECEIVE_COLLECTIONS,
//     collections
//   });
// };

// /**
//  * Get collections data for a store
//  * @param  {string}  storeSlug      The slug of the store
//  * @return {function}           The dispatch function
//  */
// export const fetchCollections = (storeSlug) => async (dispatch) => {
//   try {
//     const dataUrl = `${API_HOST}/api/v1/stores/collections?slug=${storeSlug}`;
//     const { data } = await axios.get(dataUrl, {
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json'
//       }
//     });

//     dispatch(receiveCollections(data.collections));
//   } catch (err) {
//     dispatch(
//       addToast(
//         `Failed to get collection for store: ${storeSlug}: ${err.message}`,
//         'danger'
//       )
//     );
//   }
// };

/**
 * Fetches store data and fires receiveStore action on success
 * @param {number} storeId   - Number representing storeId
 * @return {function}        - The dispatch function
 */
export const fetchStore =
  (storeId = process.env.REACT_APP_STORE_SLUG) =>
  async (dispatch, getState) => {
    const { store, user } = getState();

    try {
      if (parseInt(storeId) !== store.id) {
        const dataUrl = `${API_HOST}/api/v1/stores?storeId=${storeId}`;
        const { data } = await axios.get(dataUrl, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        });

        const userOwnsStore = data.sellerId === parseInt(user.userId);

        if (userOwnsStore) {
          await dispatch(fetchThemeConfig(data?.slug ?? ''));
          dispatch(receiveStore(storeId, data));
        } else {
          dispatch(setUnauthorizedUser(true));
        }
      }
    } catch (err) {
      if (err?.response?.status === 404) {
        dispatch(setUserStoreNotFound(true));
      }
      dispatch(
        addToast(
          `Failed to get store for storeId: ${storeId}: ${err.message}`,
          'danger'
        )
      );
    }
  };

export const getUserStores =
  (userId, activeStoreIndex = 0) =>
  async (dispatch, getState) => {
    if (!userId) return;

    const { user } = getState();

    const storesUrl = `${COMMERCE_API_HOST}/v1/users/stores?userId=${userId}`;

    try {
      const { data } = await axios.get(storesUrl, HEADERS);
      const stores = get(data, 'stores', []);

      if (stores.length) {
        const activeStore = stores[activeStoreIndex];
        await dispatch(fetchStore(activeStore.id));
        await dispatch(fetchThemeConfig(activeStore?.slug ?? ''));
        dispatch(setUserStores(stores));
      }
      if (user?.featureFlags?.[FLAGS.SHOW_COLLECTIONS]) {
        await dispatch(fetchCollections());
      }
    } catch (err) {
      dispatch(
        addToast(`Failed to get stores for ${userId}: ${err.message}`, 'danger')
      );
    }
  };
