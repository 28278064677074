import { array, func, string } from 'prop-types';
import map from 'lodash/map';
import OrderCartItem from '../../OrderSummaryCartItem';
import './SamplesMobileCheckoutModal.scss';

const SamplesMobileCheckoutModal = ({
  cartItems,
  region,
  handleCheckout,
  handleCancel
}) => {
  return (
    <div className='samples__mobile__checkout__modal__container'>
      {map(cartItems, (item) => (
        <OrderCartItem key={item.id} item={item} region={region} />
      ))}
      <div className='samples__mobile__checkout__modal__actions'>
        <button
          className='btn btn--fullwidth'
          onClick={handleCheckout}
          type='button'
        >
          Checkout
        </button>
        <button
          className='samples__mobile__checkout__cancel btn--fullwidth'
          onClick={handleCancel}
          type='button'
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

SamplesMobileCheckoutModal.propTypes = {
  cartItems: array.isRequired,
  region: string.isRequired,
  handleCheckout: func.isRequired,
  handleCancel: func.isRequired
};

export default SamplesMobileCheckoutModal;
