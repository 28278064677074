import PropTypes from 'prop-types';
import { WIDGET_THEMES } from '../../../constants/widgetThemes';
import { COLORS } from '../../../constants/colors';

export function ColorPicker(props) {
  const { theme, themeOnChange } = props;

  return (
    <div style={{ height: '24px' }}>
      <button
        type='button'
        onClick={() => themeOnChange(WIDGET_THEMES.light)}
        style={{
          cursor: 'pointer',
          borderRadius: '50%',
          width: '24px',
          height: '24px',
          marginRight: '6px'
        }}
      >
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect
            x='1'
            y='1'
            width='22'
            height='22'
            rx='11'
            fill={WIDGET_THEMES.light}
            stroke={
              theme === WIDGET_THEMES.light
                ? COLORS.picked_theme_stroke
                : COLORS.unpicked_theme_stroke
            }
            strokeWidth='2'
          />
        </svg>
      </button>

      <button
        type='button'
        onClick={() => themeOnChange(WIDGET_THEMES.dark)}
        style={{ cursor: 'pointer' }}
      >
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect
            x='1'
            y='1'
            width='22'
            height='22'
            rx='11'
            fill='#333333'
            stroke={
              theme === WIDGET_THEMES.dark
                ? COLORS.picked_theme_stroke
                : COLORS.unpicked_theme_stroke
            }
            strokeWidth='2'
          />
        </svg>
      </button>
    </div>
  );
}

ColorPicker.propTypes = {
  theme: PropTypes.string,
  themeOnChange: PropTypes.func
};

ColorPicker.defaultProps = {
  theme: WIDGET_THEMES.light,
  themeOnChange: () => {}
};
