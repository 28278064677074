import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { node } from 'prop-types';
import { Breadcrumbs, bpProps } from '@teespring/ts-components';
import classnames from 'classnames';
import OrderSummarySidebar from './OrderSummarySidebar';
import SamplesFooter from './SamplesFooter';
import './SamplesWrapper.scss';

const breadcrumbOptions = [
  {
    id: 'samples-home',
    label: 'Select listing',
    path: '/samples'
  },
  {
    id: 'samples-edit',
    label: 'Add products',
    path: '/samples/edit'
  }
];

const SamplesWrapper = ({ children }) => {
  const { pathname } = useLocation();
  const { samplesCart, region, variationsMap, currency } = useSelector(
    (state) => state.samples
  );
  const { bpIsLT } = useSelector((state) => ({ ...bpProps(state) }));
  const isSmallDesktop = bpIsLT('desktopSm');
  const isMobile = bpIsLT('mobileLg');
  const isMainPage = pathname === '/samples';
  const cartItems = Object.values(samplesCart);

  const totalItemQuantity = cartItems.reduce((acc, tool) => {
    return acc + tool.quantity;
  }, 0);

  const variations = Object.values(variationsMap).reduce((acc, curr) => {
    acc = [...acc, ...curr.variations];
    return acc;
  }, []);

  return (
    <div className='standard__layout samples__wrapper'>
      <main
        className={classnames({
          standard__layout__main: true,
          'hide-aside': isSmallDesktop || isMobile
        })}
      >
        {!isMobile && (
          <Breadcrumbs
            className='samples__wrapper__breadcrumbs'
            crumbs={breadcrumbOptions}
            activeCrumbId={isMainPage ? 'samples-home' : 'samples-edit'}
          />
        )}

        {children}
      </main>

      {!isMobile && <OrderSummarySidebar cart={samplesCart} />}

      <SamplesFooter
        cartItems={cartItems}
        totalItems={totalItemQuantity}
        isMobile={isMobile}
        variations={variations}
        currency={currency}
        region={region}
      />
    </div>
  );
};

SamplesWrapper.propTypes = {
  children: node.isRequired
};

export default SamplesWrapper;
