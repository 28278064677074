/* eslint-disable prefer-promise-reject-errors */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { TrackJS } from 'trackjs';
import axios from 'axios';
import { formatAxiosRequestHeaders } from 'lib/helpers';
import { hydrateStore } from 'redux/hydrateStore';
import { datadogRum } from '@datadog/browser-rum';
import { useCookie } from './hooks';
import { COMMERCE_API_V1_PATH } from './constants';
import { setFeatureFlags } from './redux/actions';
import store from './redux/createStore';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';

const theme = createTheme({
  typography: {
    fontFamily: '"DM Sans", sans-serif'
  }
});

datadogRum.init({
  applicationId: 'e03f571e-b243-4bc2-a50f-9471b05d03a0',
  clientToken: 'puba2b66f2f80fc1bd150e5ead339e4c991',
  site: 'us5.datadoghq.com',
  service: 'legacy-dashboard',
  sampleRate: 5,
  sessionReplaySampleRate: 0,
  trackInteractions: true,
  defaultPrivacyLevel: 'mask-user-input'
});

datadogRum.startSessionReplayRecording();

if (process.env.REACT_APP_TRACK_JS_TOKEN) {
  TrackJS.install({
    token: process.env.REACT_APP_TRACK_JS_TOKEN,
    application: process.env.REACT_APP_TRACK_JS_APPLICATION
  });
}

/**
 * Pre mount process
 * call token endpoint to get CSRF token
 * call session api to fetch user_id
 * if session api returns a failed response, delete current user_id in cookie then redirect
 * if success, store user_id in cookie with a 4 hour duration
 */

const parEmailVerification =
  process.env.REACT_APP_FF_PAR_EMAIL_VERIFICATION === 'true';

let userId = process.env.REACT_APP_USER_ID;

const showCollections = process.env.REACT_APP_FF_SHOW_COLLECTIONS === 'true';

Promise.resolve()
  .then(() => {
    return axios.get(`${COMMERCE_API_V1_PATH}/token`, {
      withCredentials: true
    });
  })
  .then(({ data: { token } }) => {
    return new Promise(async (resolve, reject) => {
      const currentPath = window.location.href || '/';
      const cookieLifeSpan = 4 * 60 * 60 * 1000; // 4 hours in milliseconds
      const { setValue, getValue, removeValue } = useCookie();

      let storeVersion =
        getValue('store_version') || process.env.REACT_APP_STORE_VERSION;

      store.dispatch(
        setFeatureFlags({
          parEmailVerification,
          showCollections
        })
      );

      if (token) {
        removeValue('token');
        setValue('token', token, { maxAge: cookieLifeSpan });
      }

      //In production
      if (!userId) {
        try {
          const res = await axios.get(
            `${COMMERCE_API_V1_PATH}/session`,
            formatAxiosRequestHeaders(token)
          );

          userId = res.data.userId;
          storeVersion = res.data.hasBrandedStoresAccess ? 'v2' : 'v1';

          store.dispatch(
            setFeatureFlags({
              parEmailVerification: res?.data?.parEmailVerification,
              showCollections: res?.data?.hasCollectionsDashboardAccess,
              hasZendeskLiveChat: res?.data?.hasZendeskLiveChat
            })
          );

          setValue('user_id', userId, { maxAge: cookieLifeSpan });
          setValue('store_version', storeVersion, { maxAge: cookieLifeSpan });
        } catch (err) {
          removeValue('user_id');
          return reject(
            `${err.response.data.redirectUrl}?redirect_to=${currentPath}`
          );
        }
      }

      await hydrateStore(store, userId, storeVersion, token);
      return resolve();
    });
  })
  .then(() => {
    ReactDOM.render(
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <App />
          </Provider>
        </ThemeProvider>
      </React.StrictMode>,
      document.getElementById('root')
    );
  })
  .catch((err) => {
    window.location.href = err;
  });

reportWebVitals();
// app id: 558c0145-33fa-4236-864e-f8859f4b02f3
// client token: pub172eb69dc1620c27a4d89e295b55aec5
// service name: legacy-dashboard
