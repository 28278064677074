import propTypes from 'prop-types';
import ShareTooltipContent from 'components/ShareTooltip/ShareTooltipContent';
import './ListingShareModal.scss';

const ListingShareModal = ({ closeModal, listingUrl }) => {
  return (
    <div className='sharemodal'>
      <ShareTooltipContent shareContent={listingUrl} />

      <button className='sharemodal__close' type='button' onClick={closeModal}>
        Cancel
      </button>
    </div>
  );
};

const { func, string } = propTypes;
ListingShareModal.propTypes = {
  closeModal: func,
  listingUrl: string
};

ListingShareModal.defaultProps = {
  closeModal: () => {},
  listingUrl: ''
};

export default ListingShareModal;
