export const OVERVIEW_EVENT = 'dashboard_overview';
export const UPGRADES_PAGE_EVENT = 'upgrades_page';

/* Branded Stores Events */
export const BRANDED_STORES_EVENT = 'branded_stores';
export const STORE_EDITOR_EVENT = `${BRANDED_STORES_EVENT}.store_editor`;
export const DOMAIN_STATUS_MENU_EVENT = `${BRANDED_STORES_EVENT}.domain_status_menu`;

/* Collections Events */
export const DASHBOARD_COLLECTIONS_EVENT = 'dashboard_collections';

/* Custom Domain Events */
export const CUSTOM_DOMAINS_EVENT = 'custom_domains';
export const CONNECT_A_DOMAIN_EVENT = `${CUSTOM_DOMAINS_EVENT}.connect_a_domain`;
export const PURCHASE_A_DOMAIN_EVENT = `${CUSTOM_DOMAINS_EVENT}.purchase_a_domain`;

/* Purchases Events */
export const DASHBOARD_PURCHASES_EVENT = 'dashboard_purchases';
export const PAYMENT_METHOD_EVENT = `${DASHBOARD_PURCHASES_EVENT}.payment_method`;
export const PURCHASE_HISTORY_EVENT = `${DASHBOARD_PURCHASES_EVENT}.purchase_history`;
export const SUBSCRIPTIONS_EVENT = `${DASHBOARD_PURCHASES_EVENT}.subscriptions`;

export const PURCHASE_FLOW_EVENT = 'purchase_flow';

/* Tools Events */
export const TOOLS_EVENT = 'dashboard_tools';

/* Verify Email Events */
export const VERIFY_EMAIL_PROMPT_VIEWED_EVENT =
  'Verify Email Prompt Modal Viewed';
export const VERIFY_EMAIL_PROMPT_CLICKED_EVENT = 'Verify Email Button Clicked';
