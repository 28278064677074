const VIEWPORT_SIZES = {
  desktopLg: '1400px',
  desktopMd: '1300px',
  desktopSm: '1200px',
  tabletLg: '1040px',
  tabletMd: '991px',
  tabletSm: '840px',
  mobileLg: '767px',
  mobileMd: '540px',
  mobileSm: '400px',
  mobileXsm: '350px'
};
export default VIEWPORT_SIZES;
