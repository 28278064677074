import './IntegrationDisconnectModal.scss';
// import { useAmplitude } from 'hooks';
import ModalTemplate from 'components/ModalTemplate';
import { func } from 'prop-types';

export const INTEGRATION_DISCONNECT_MODAL_ID = 'IN';

const IntegrationDisconnectModal = ({ handleClose }) => {
  //   const dispatch = useDispatch();

  //   const { logEvent } = useAmplitude();

  return (
    <ModalTemplate
      modalBtnClass='integrations_page_disconnect_button'
      modalTitle='Disconnect?'
      modalTitleAlign='center'
      modalBtnText='Yes, disconnect'
      modalBtnAction={() => {}}
      modalBtnDisabled={false}
      modalClose={handleClose}
      modalBtnLoading={false}
      modalContent={<>ok</>}
    />
  );
};

IntegrationDisconnectModal.propTypes = {
  handleClose: func.isRequired
};

export default IntegrationDisconnectModal;
