import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const PrimaryBtn = styled(Button)(({ theme }) => ({
  borderRadius: '16px',
  backgroundColor: '#1954EB',
  fontFamily: theme.fontFamilies?.inter,
  color: '#fff',
  boxShadow: theme.shadows[0],
  padding: '11px',
  textTransform: 'none',
  fontSize: '15px',
  lineHeight: '26px',
  gap: '4px',
  '&:hover': {
    backgroundColor: '#0034BB',
    boxShadow: 'none'
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#00278B'
  },
  '&:focus': {},
  '&:disabled': {
    boxShadow: 'none',
    backgroundColor: theme.palette?.action?.disabledBackground,
    fontFamily: theme.fontFamilies?.inter
  },
  '&.MuiButtonBase-root.MuiButton-sizeMedium': {
    fontSize: '14px',
    padding: '10px 16px',
    height: '40px'
  },
  '&.MuiButton-sizeLarge': {
    fontSize: '14px',
    padding: '10px 16px',
    height: '50px'
  }
}));
