import {
  CLEAR_MODAL_STATE,
  HIDE_MODALS,
  SET_ACTIVE_MODAL,
  UNHIDE_MODALS
} from 'redux/actions';
import { TERMS_OF_SERVICE_MODAL_ID } from '../../components/TermsOfServiceModal';

const INITIAL_STATE = {
  modal: { id: null, mProps: {} },
  hideModals: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ACTIVE_MODAL: {
      if (state.modal.id === TERMS_OF_SERVICE_MODAL_ID) {
        return state;
      } else {
        return {
          ...state,
          modal: { id: action.id, mProps: action.props || {} }
        };
      }
    }
    case CLEAR_MODAL_STATE:
      return { ...state, modal: INITIAL_STATE.modal };
    case HIDE_MODALS:
      return { ...state, hideModals: true };
    case UNHIDE_MODALS:
      return { ...state, hideModals: false };
    default:
      return state;
  }
};
