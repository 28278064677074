export default {
  youtube: {
    displayName: 'YouTube',
    integration: 'YouTube',
    alert: 'Available for channels with 10K+ subscribers.',
    heading: 'YouTube integration',
    body: 'Promote products directly below your videos and access features like live chat alerts and merch end cards.',
    ctaText: 'Connect',
    ctaButtonType: 'loader',
    ctaLink: '#',
    carouselSlides: [
      {
        src: 'youtube+01_desktop.jpg',
        srcSet:
          'https://teespring-ass.s3.amazonaws.com/Integrations/dashboard/youtube+01_desktop.jpg 904w, https://teespring-ass.s3.amazonaws.com/Integrations/dashboard/youtube+01_mobile.jpg 492w'
      },
      {
        src: 'https://teespring-ass.s3.amazonaws.com/Integrations/dashboard/youtube+02_desktop.jpg',
        srcSet:
          'https://teespring-ass.s3.amazonaws.com/Integrations/dashboard/youtube+02_desktop.jpg 904w, https://teespring-ass.s3.amazonaws.com/Integrations/dashboard/youtube+02_mobile.jpg 492w'
      }
    ],
    moreInfo: {
      heading: 'More information',
      text: 'Feature your digital and physical products directly below yourYouTube videos. Use this powerful integration to provide a seamless point-of-sale for your fans and increase purchases by 25%.',
      list: [
        {
          icon: 'PlaySquare',
          text: 'Feature your products directly below video content'
        },
        {
          icon: 'Tag',
          text: 'Feature your products directly below video content'
        },
        {
          icon: 'ShoppingBag',
          text: 'Build a dedicated product store on y our YouTube channel page'
        },
        {
          icon: 'Showcase',
          text: 'Showcase products in end cards to increase sales'
        }
      ]
    },
    links: {
      support: 'https://www.spri.ng/creator-resources/youtube-integration-faqs',
      resources: 'https://www.spri.ng/blog?search=youtube'
    }
  },
  linktree: {
    displayName: 'Linktree',
    integration: 'Linktree',
    alert: '',
    heading: 'Create with Spring. Sell on Linktree.',
    body: 'Embed your Spring store directly within your Linktree—seamless shopping for fans, more sales and traffic for you on all platforms.',
    ctaText: 'Add store to Linktree',
    ctaButtonType: 'embedded',
    ctaEmbeddedComponentUrl:
      'https://assets.production.linktr.ee/sdk/js/AddThisButton.js',
    ctaEmbeddedComponentRenderer: (storeUrl) =>
      `linktree.AddThisButton.render(${JSON.stringify({
        style: {
          size: 'medium',
          color: 'purple',
          height: 'small',
          shape: 'rectangle',
          hasIcon: true
        },
        link: {
          title: 'My Spring store',
          utm_channel: 'spring',
          url: storeUrl
        }
      })}, "#embedded-cta-container")`,
    ctaLink:
      'https://linktr.ee/register?utm_source=partnerships&utm_medium=in-app&utm_content=campaign',
    carouselSlides: [
      {
        src: 'https://teespring-ass.s3.amazonaws.com/Integrations/dashboard/linktree-desktop-03.png',
        srcSet:
          'https://teespring-ass.s3.amazonaws.com/Integrations/dashboard/linktree-desktop-03.png 904w, https://teespring-ass.s3.amazonaws.com/Integrations/dashboard/linktree-mobile-03.png 492w'
      },
      {
        src: 'https://teespring-ass.s3.amazonaws.com/Integrations/dashboard/linktree-desktop-04.png',
        srcSet:
          'https://teespring-ass.s3.amazonaws.com/Integrations/dashboard/linktree-desktop-04.png 904w, https://teespring-ass.s3.amazonaws.com/Integrations/dashboard/linktree-mobile-04.png 492w'
      },
      {
        src: 'https://teespring-ass.s3.amazonaws.com/Integrations/dashboard/linktree-desktop-05.png',
        srcSet:
          'https://teespring-ass.s3.amazonaws.com/Integrations/dashboard/linktree-desktop-05.png 904w, https://teespring-ass.s3.amazonaws.com/Integrations/dashboard/linktree-mobile-05.png 492w'
      }
    ],
    moreInfo: {
      heading: 'How to sell on Linktree',
      text: 'Spring has partnered with Linktree to make sure your products are only a click away. Embed your store in seconds with these simple steps.',
      list: [
        {
          icon: 'ExternalLink',
          text: 'Click the ”Add this to my Linktree” button above.'
        },
        {
          icon: 'LogIn',
          text: 'Log in to your Linktree account or sign up for free.'
        },
        {
          icon: 'ShoppingBag',
          text: 'Next, you’ll see the option to embed your Spring store. Hit “Show me more”. Select “Show my shop on Linktree."'
        },
        {
          icon: 'CheckCircle',
          text: 'You’re all set. Fans who click your Spring store link will see a preview of up to six products.'
        }
      ]
    },
    links: {
      support: 'https://www.spri.ng/creator-resources/creator-support',
      resources: ''
    }
  },
  onlyfans: {
    displayName: 'OnlyFans',
    integration: 'OnlyFans',
    alert: '',
    heading: 'Sell on your OnlyFans page.',
    body: 'Embed your Spring store directly within your OnlyFans page—seamless shopping for fans, more sales and traffic for you on all platforms.',
    ctaText: 'Connect OnlyFans',
    ctaButtonType: 'link',
    ctaLink:
      'https://onlyfans.com/my/settings/?utm_source=partnerships&utm_medium=in-app&utm_content=campaign',
    carouselSlides: [
      {
        src: 'https://teespring-ass.s3.amazonaws.com/Integrations/dashboard/onlyfans-01.png',
        srcSet:
          'https://teespring-ass.s3.amazonaws.com/Integrations/dashboard/onlyfans-01-904w.png 904w, https://teespring-ass.s3.amazonaws.com/Integrations/dashboard/onlyfans-01-492w.png 492w'
      },
      {
        src: 'https://teespring-ass.s3.amazonaws.com/Integrations/dashboard/onlyfans-02.png',
        srcSet:
          'https://teespring-ass.s3.amazonaws.com/Integrations/dashboard/onlyfans-02-904w.png 904w, https://teespring-ass.s3.amazonaws.com/Integrations/dashboard/onlyfans-02-492w.png 492w'
      }
    ],
    moreInfo: {
      heading: 'How to sell on OnlyFans',
      text: 'Spring has partnered with OnlyFans to make sure your products are only a click away. Embed your store in seconds with these simple steps.',
      list: [
        {
          icon: 'MousePointer',
          text: "Click 'Connect OnlyFans'"
        },
        {
          icon: 'LogIn',
          text: 'Login to OnlyFans, or Signup for Free'
        },
        {
          icon: 'User',
          text: 'Go to Profile, open your profile ‘Settings’ and select ‘Spring store’'
        },
        {
          icon: 'Clipboard',
          text: 'Paste your Spring store URL and press connect'
        },
        {
          icon: 'CheckCircle',
          text: 'Promote Your Merch!'
        }
      ]
    },
    links: {
      support: 'https://www.spri.ng/creator-resources/creator-support',
      resources: ''
    }
  },
};
