import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSortBy } from 'redux/actions';

const ProductViewSidebar = () => {
  const { sortBy } = useSelector((state) => state.collectionProducts);
  const dispatch = useDispatch();

  // const viewOptions = [
  //   { id: 'listing', label: 'By listing' },
  //   { id: 'product', label: 'By product' }
  // ];

  const sortOptions = [
    {
      id: 'recent',
      label: 'Recently created',
      sortByParam: 'createdDate',
      orderBy: 'DESC'
    },
    {
      id: 'asc',
      label: 'A-Z',
      sortByParam: 'name',
      orderBy: 'ASC'
    },
    {
      id: 'desc',
      label: 'Z-A',
      sortByParam: 'name',
      orderBy: 'DESC'
    }
  ];

  return (
    <aside className='standard__layout__aside'>
      {/* <div className='collections__controls mb3'>
        <p className='typ--bold'>View</p>
        {viewOptions.map((opt) => {
          const isActive = opt.id === view ? 'is-active' : '';

          return (
            <button
              key={opt.id}
              type='button'
              className={`btn btn--pill ${isActive}`}
              onClick={() => dispatch(setView(opt.id))}
            >
              {opt.label}
            </button>
          );
        })}
      </div> */}

      <div className='collections__controls'>
        <p className='typ--bold'>Sort</p>
        {sortOptions.map((opt) => {
          const isActive = opt.id === sortBy.id ? 'is-active' : '';
          const { id, sortByParam, orderBy } = opt;

          return (
            <button
              key={opt.id}
              type='button'
              className={`btn btn--pill ${isActive}`}
              onClick={() => dispatch(setSortBy({ id, sortByParam, orderBy }))}
            >
              {opt.label}
            </button>
          );
        })}
      </div>
    </aside>
  );
};

export default ProductViewSidebar;
