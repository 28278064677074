import React from 'react';

export const SellInOtherPlatformsGuide = () => <section className='sell_other_platforms_container'>
    <hgroup>
        <h2>Social Selling Tool Guides</h2>
        <h5>Tools and resources for selling your products on other platforms</h5>
    </hgroup>

    <section className='sell_other_platforms_content'>
        <figure>
            <img src='https://teespring-ass.s3.amazonaws.com/dashboard/other-platforms-instagram.jpg'
                 alt='visual summary of how to sell on instagram'/>
        </figure>
        <section>
            <hgroup>
                <h2>How to use Instagram to maximize your product sales</h2>
                <h5>Increase your sales with these game-changing tips and tricks for using Instagram social selling
                    tools!</h5>
            </hgroup>
            <a href='https://hub.spri.ng/learn/sell-on-instagram#03-%C2%B7-drive-traffic-to-your-store' target='_blank'
               rel='noopener noreferrer'> See guide</a>
        </section>
    </section>
</section>;
