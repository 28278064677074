import { useCallback, useEffect, useState } from 'react';
import map from 'lodash/map';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { addToast } from '../redux/actions';
import {
  SUBSCRIPTION_API_GATEWAY_KEY,
  SUBSCRIPTIONS_API_HOST
} from '../constants';

const useUserPurchases = (userId) => {
  const dispatch = useDispatch();
  const [purchases, setPurchases] = useState([]);
  const [isFetchingPurchases, setIsFetchingPurchases] = useState(true);

  const fetchUserPurchases = useCallback(async () => {
    try {
      setIsFetchingPurchases(true);
      const { data } = await axios.get(
        `${SUBSCRIPTIONS_API_HOST}/v1/purchases?userId=${userId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-API-Key': SUBSCRIPTION_API_GATEWAY_KEY
          }
        }
      );
      const formattedData = map(data, (item) => {
        const currency = item.amount.currency || 'USD';
        const price = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency,
          minimumFractionDigits: 2
        }).format(item.amount.price / 100);
        const date = new Date(item.date).toLocaleDateString('en-US');
        return {
          amount: `${currency} ${price}`,
          date,
          name: item.name,
          toolId: item.toolId
        };
      });
      setPurchases(formattedData);
    } catch (err) {
      dispatch(addToast(err?.response?.data?.message ?? err.message, 'danger'));
    } finally {
      setIsFetchingPurchases(false);
    }
  }, [dispatch, userId]);

  useEffect(() => {
    fetchUserPurchases();
  }, [fetchUserPurchases]);

  return { isFetchingPurchases, purchases };
};

export default useUserPurchases;
