import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from '@mui/material';
import styled from '@emotion/styled';
import Data from '../UpgradesConfig';

const UpgradeFilter = ({ filterItem, setItem, menuItems }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const StyledTabs = styled(Tabs)`
    margin-bottom: 16px;
    .MuiTabs-flexContainer {
      justify-content: start;
      gap: 10px;
    }
    .MuiTabs-indicator {
      display: none;
    }
  `;
  const StyledTab = styled(Tab)`
    min-width: initial;
    min-height: initial;
    padding: 8px 12px;
    text-transform: initial;
    font-weight: 400;
    font-size: 14px;
    color: #000;
    letter-spacing: 0;
    border-radius: 200px;
    transition: background 0.1s linear 0.1s;
    :hover {
      background: rgba(0, 0, 0, 0.04);
    }
    &.Mui-selected {
      background-color: #000;
      color: #fff;
      font-weight: 500;
      :hover {
        background: rgba(0, 0, 0, 0.8);
      }
    }
  `;

  return (
    <>
      <StyledTabs value={value} onChange={handleChange} centered={true}>
        <StyledTab onClick={() => setItem(Data)} label='Discover' />
        {menuItems.map((Val) => {
          return <StyledTab onClick={() => filterItem(Val)} label={Val} />;
        })}
      </StyledTabs>
    </>
  );
};

UpgradeFilter.propTypes = {
  filterItem: PropTypes.func.isRequired,
  setItem: PropTypes.func,
  menuItems: PropTypes.array
};

UpgradeFilter.defaultProps = {
  setItem: '',
  menuItems: ''
};

export default UpgradeFilter;
