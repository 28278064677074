import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Button, Fab, useMediaQuery } from '@mui/material';
import '../integrations.scss';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { array } from 'prop-types';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export const IntegrationsCarousel = (props) => {
  const { steps } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const tablet = useMediaQuery(theme.breakpoints.only('md'));

  const largeDesktop = useMediaQuery(theme.breakpoints.only('xl'));
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    if (activeStep >= maxSteps - 1) {
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep <= 0) {
      setActiveStep(Math.max(maxSteps - 1, 0));
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const carouselContainer = {
    maxWidth: '1232px',
    margin: '0 auto',
    display: 'flex',
    height: '424px',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 50,
    minWidth: 50,
    justifyContent: largeDesktop ? 'space-between' : 'center',
    overflow: 'hidden'
  };

  const desktopCarousel = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '424px',
    maxWidth: '920px',
    margin: '0 auto',
    overflow: 'hidden'
  };

  const desktopCarouselContext = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: 'calc(40%)'
  };

  const desktopCarouselTitle = {
    width: '408px',
    fontFamily: 'DM Sans',
    fontWeight: '500',
    fontSize: '42px',
    lineHeight: '44.1px',
    letterSpacing: '-2%'
  };

  const desktopCarouselText = {
    margin: '11px 0',
    width: '348.12px',
    fontFamily: 'Inter',
    fontSize: '14px',
    lineHeight: '20.72px',
    letterSpacing: '-1%'
  };

  const stepperStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: mobile ? '32px' : '48px'
  };

  const stepperPill = {
    backgroundColor: '#F2F2F2',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const tabletCarouselContainer = {
    maxWidth: '1232px',
    margin: '0 auto',
    display: 'flex',
    height: '424px',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 50,
    minWidth: 50,
    justifyContent: 'space-between'
  };

  const smallArrowBtn = {
    color: '#595959',
    minWidth: 'auto',
    '&hover': { backgroundColor: '#D9D9D9' }
  };
  const fabButton = {
    zIndex: '600',
    backgroundColor: '#F2F2F2',
    margin: mobile ? '12px' : '0 12px',
    padding: 0,
    boxShadow: 'none'
  };

  return (
    <div
      style={{
        background: '#FFFFFF',
        borderRadius: mobile ? '0 0 24px 24px' : '0'
      }}
      id='integrations-carousel'
    >
      {!mobile && !tablet && (
        <div style={carouselContainer}>
          {largeDesktop && (
            <Fab onClick={handleBack} elevation={0} sx={fabButton}>
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
            </Fab>
          )}
          <AutoPlaySwipeableViews
            index={activeStep}
            onChangeIndex={(e) => setActiveStep(e)}
            style={{ maxWidth: 'calc(920px)', overflow: 'hidden' }}
          >
            {steps.map((step) => {
              return (
                <div style={desktopCarousel} key={step.label}>
                  <div style={desktopCarouselContext}>
                    <div style={desktopCarouselTitle}>{step.label}</div>
                    <div style={desktopCarouselText}>{step.description}</div>
                  </div>
                  <div
                    style={{
                      height: '100%',
                      width: 'calc(60%)',
                      overflow: 'hidden',
                      backgroundImage: `url(${step.imageHref})`,
                      backgroundPosition: 'right',
                      backgroundSize: '100% 100%',
                      backgroundRepeat: 'no-repeat'
                    }}
                  />
                </div>
              );
            })}
          </AutoPlaySwipeableViews>
          {largeDesktop && (
            <Fab onClick={handleNext} elevation={0} sx={fabButton}>
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Fab>
          )}
        </div>
      )}
      {mobile && (
        <AutoPlaySwipeableViews
          index={activeStep}
          onChangeIndex={(e) => setActiveStep(e)}
        >
          {steps.map((step) => (
            <div
              className='integrations_mobile_background'
              style={{
                background: `url(${steps[activeStep].imageHrefMobile})`
              }}
              key={step.label}
            >
              <div key={step.label} className='integrations_item_title_mobile'>
                {step.label}
              </div>
            </div>
          ))}
        </AutoPlaySwipeableViews>
      )}
      {tablet && (
        <div style={tabletCarouselContainer}>
          <AutoPlaySwipeableViews
            index={activeStep}
            onChangeIndex={(e) => setActiveStep(e)}
            style={{ maxWidth: 'calc(920px)', margin: '0 auto' }}
          >
            {steps.map((step) => (
              <div className='integrations_tablet_background' key={step.label}>
                <img
                  src={step.imageHref}
                  style={{ width: '295px' }}
                  alt='splash'
                />
                <div
                  key={step.label}
                  className='integrations_item_title_tablet'
                >
                  {step.label}
                </div>
              </div>
            ))}
          </AutoPlaySwipeableViews>
        </div>
      )}
      <div style={stepperStyle}>
        <div style={stepperPill}>
          {!largeDesktop && (
            <Button
              size='small'
              sx={smallArrowBtn}
              variant='text'
              onClick={() => handleBack()}
            >
              <KeyboardArrowLeft sx={{ fontSize: '14px' }} />
            </Button>
          )}
          <MobileStepper
            steps={maxSteps}
            sx={{ backgroundColor: 'transparent', padding: '0px' }}
            variant='dots'
            position='static'
            activeStep={activeStep}
          />
          {!largeDesktop && (
            <Button
              size='small'
              sx={smallArrowBtn}
              variant='text'
              onClick={() => handleNext()}
            >
              <KeyboardArrowRight sx={{ fontSize: '14px' }} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

IntegrationsCarousel.propTypes = {
  steps: array.isRequired
};

export default IntegrationsCarousel;
