import React, { useEffect } from 'react';
import { shape, arrayOf, string, object, func } from 'prop-types';
import { Helmet } from 'react-helmet-async';
import './AppSettingsTemplate.scss';
import Header from './Header';
import Tabs from './Tabs';

/**
 * Renders the service settings template.
 *
 * @return {object} JSX.Element
 * */
const AppSettingsTemplate = ({
  content: { displayName, body, header, tabs },
  onTabSelected,
  onMount
}) => {
  useEffect(() => onMount(), [onMount]);

  return (
    <section className='app-settings'>
      <Helmet>
        <title>Dashboard | Spring | Apps | {displayName} | Settings</title>
        <meta name='description' content={body} />
      </Helmet>
      <Header logo={header.logo} text={header.text} status={header.status} />
      <Tabs
        tabs={tabs}
        selected={tabs[0].title}
        onTabSelected={onTabSelected}
      />
    </section>
  );
};

AppSettingsTemplate.propTypes = {
  content: shape({
    displayName: string.isRequired,
    body: arrayOf(string),
    header: shape({
      text: shape({
        title: string.isRequired,
        subtitle: string.isRequired,
        author: string.isRequired
      }),
      status: shape({
        description: string
      }),
      logo: shape({
        imgSrc: string,
        svgToParse: string,
        alt: string
      })
    }),
    tabs: arrayOf(
      shape({
        title: string.isRequired,
        render: object.isRequired
      })
    ).isRequired
  }),
  onTabSelected: func,
  onMount: func
};

AppSettingsTemplate.defaultProps = {
  content: {
    body: [],
    header: {
      text: {
        subtitle: null
      },
      status: {
        description: 'Active'
      },
      logo: {
        imgSrc: '',
        svgToParse: '',
        alt: null
      }
    }
  },
  onTabSelected: () => {},
  onMount: () => {}
};

export default AppSettingsTemplate;
