import { useSelector } from 'react-redux';
import get from 'lodash/get';

const useCollection = (collectionId) => {
  const collection = useSelector((state) => state.collections[collectionId]);
  const collectionItems = get(collection, 'collectionItems', []);

  return { collection, collectionItems };
};

export default useCollection;
