import axios from 'axios';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import { isNotDigitalListing } from 'lib/helpers';
import { API_HOST, COMMERCE_API_HOST } from '../../../constants';

export const SAMPLES_SET_PRODUCTS = 'SAMPLES_SET_PRODUCTS';
export const SAMPLES_SET_REGION = 'SAMPLES_SET_REGION';
export const SAMPLES_SET_CURRENCY = 'SAMPLES_SET_CURRENCY';
export const SAMPLES_LOADING = 'SAMPLES_LOADING';
export const SAMPLES_ADD_TO_CART = 'SAMPLES_ADD_TO_CART';
export const SAMPLES_REMOVE_ITEM_FROM_CART = 'SAMPLES_REMOVE_ITEM_FROM_CART';
export const SAMPLES_CLEAR_CART = 'SAMPLES_CLEAR_CART';
export const SAMPLES_ADD_VARIATION = 'SAMPLES_ADD_VARIATION';
export const SAMPLES_UPDATE_VARIATION = 'SAMPLES_UPDATE_VARIATION';
export const SAMPLES_SET_PAGE_NUM = 'SAMPLES_SET_PAGE_NUM';
export const SAMPLES_SET_FETCHING_MORE = 'SAMPLES_SET_FETCHING_MORE';
export const SAMPLES_HIDE_SHOW_MORE = 'SAMPLES_HIDE_SHOW_MORE';

// helpers
export const fetchSamplesProductsForListing = async (
  listingUrl,
  currency = 'USD',
  region = 'USA'
) => {
  const { data } = await axios.get(
    `${COMMERCE_API_HOST}/v0/listing/${listingUrl}?currency=${currency}&region=${region}&country_code=en&availability=any&visibility=any`
  );

  return get(data, 'products_payload', []);
};

export const processListings = async (listings) => {
  if (isEmpty(listings)) return listings;

  const nonDigitalListings = listings.filter(isNotDigitalListing);

  const results = [];

  for (const listing of nonDigitalListings) {
    try {
      if (!has(listing, 'products')) {
        const products = await fetchSamplesProductsForListing(
          get(listing, 'url')
        );

        results.push({
          ...listing,
          products
        });
      } else {
        results.push(listing);
      }
    } catch (err) {
      throw err;
    }
  }

  return results;
};

export const computeTotalCost = (variations) => {
  return variations.reduce((acc, curr) => {
    const price = curr.selectedSize.basePrice;
    acc += curr.quantity * price;
    return acc;
  }, 0);
};

// Actions
export const setRegion = (region) => {
  region = region.toLocaleUpperCase();
  return {
    type: SAMPLES_SET_REGION,
    region
  };
};

export const setCurrency = (currency) => {
  currency = currency.toLocaleUpperCase();
  return {
    type: SAMPLES_SET_CURRENCY,
    currency
  };
};

export const setSamplesLoading = (loading) => ({
  type: SAMPLES_LOADING,
  loading
});

export const setSamplesPageNum = (pageNum) => ({
  type: SAMPLES_SET_PAGE_NUM,
  pageNum
});

export const setSamplesFetchingMore = (isFetchingMore) => ({
  type: SAMPLES_SET_FETCHING_MORE,
  isFetchingMore
});

// Thunked actions

// needs to take a sortBy param
export const fetchSamplesProducts = () => async (dispatch, getState) => {
  try {
    const {
      user: { userId },
      samples: { pageNum }
    } = getState();
    const perPage = 10;

    if (pageNum === 1) {
      dispatch(setSamplesLoading(true));
    } else {
      dispatch(setSamplesFetchingMore(true));
    }

    const { data } = await axios.get(`${API_HOST}/api/v1/user/listings`, {
      withCredentials: true,
      params: {
        userId,
        per: perPage,
        page: pageNum,
        sortBy: 'createdDate',
        orderBy: 'DESC'
      }
    });

    const listings = get(data, 'listings', []);

    if (isEmpty(listings) || listings.length < perPage) {
      dispatch({ type: SAMPLES_HIDE_SHOW_MORE });
    }
    dispatch({
      type: SAMPLES_SET_PRODUCTS,
      listings
    });
  } catch (err) {
    throw err;
  } finally {
    dispatch(setSamplesLoading(false));
    dispatch(setSamplesFetchingMore(false));
  }
};

export const addSamplesVariation =
  (samplesVariations, key) => (dispatch, getState) => {
    const currentVariations = { ...getState().samples.variationsMap };
    const isInMap = has(currentVariations, key);

    if (isInMap) {
      const updatedVariations = [
        ...currentVariations[key]?.variations,
        samplesVariations
      ];

      currentVariations[key] = {
        variations: updatedVariations,
        totalCost: computeTotalCost(updatedVariations)
      };
    } else {
      currentVariations[key] = {
        variations: [samplesVariations],
        totalCost: '0'
      };
    }

    dispatch({
      type: SAMPLES_ADD_VARIATION,
      variations: currentVariations
    });
  };

export const updateSamplesVariation =
  (newVariations, key) => (dispatch, getState) => {
    const currentVariations = { ...getState().samples.variationsMap };
    currentVariations[key] = {
      variations: newVariations,
      totalCost: computeTotalCost(newVariations)
    };

    dispatch({
      type: SAMPLES_ADD_VARIATION,
      variations: currentVariations
    });
  };

export const addSampleToCart = (variant, quantity) => (dispatch, getState) => {
  const currentCart = { ...getState().samples.samplesCart };
  const key = variant.variantCartId;
  const cartItem = get(currentCart, key);

  if (quantity === 0) {
    delete currentCart[key];
  } else if (cartItem) {
    currentCart[key] = {
      ...cartItem,
      quantity,
      selectedSize: variant.selectedSize,
      selectedColor: variant.selectedColor
    };
  } else {
    currentCart[key] = { ...variant, quantity };
  }

  // if we need to persist the cart in localstorage. Do it here

  dispatch({
    type: SAMPLES_ADD_TO_CART,
    cart: currentCart
  });
};

export const removeItemFromCart = (cartId) => (dispatch, getState) => {
  const { samplesCart, variationsMap } = getState().samples;
  const currentCart = { ...samplesCart };
  const currentVariationsMap = { ...variationsMap };
  const { productKey } = currentCart[cartId];
  const variations = get(currentVariationsMap[productKey], 'variations', []);
  const updatedVariations = variations.map((variation) =>
    variation.variantCartId === cartId
      ? { ...variation, quantity: 0 }
      : variation
  );

  delete currentCart[cartId];

  dispatch(updateSamplesVariation(updatedVariations, productKey));

  dispatch({
    type: SAMPLES_REMOVE_ITEM_FROM_CART,
    cart: currentCart
  });
};

export const clearSamplesCart = () => ({
  type: SAMPLES_CLEAR_CART
});
