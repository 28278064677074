import React from 'react';
import { useHistory } from 'react-router-dom';
import { arrayOf, node, oneOfType, string, func, oneOf } from 'prop-types';

const BackButton = ({
  children,
  className,
  handleClick,
  pathOverride,
  ...props
}) => {
  const history = useHistory();

  const goBack = () => {
    if (handleClick) handleClick();

    if (pathOverride) {
      history.push(pathOverride);
    } else {
      history.goBack();
    }
  };

  return (
    <button
      className={`btn ${className}`}
      onClick={goBack}
      type='button'
      {...props}
    >
      {children}
    </button>
  );
};

BackButton.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  className: string,
  handleClick: oneOfType([func, oneOf([null])]),
  /**
   * If provided, will navigate to the provided path instead of "going back"
   */
  pathOverride: string
};

BackButton.defaultProps = {
  className: '',
  handleClick: null,
  pathOverride: null
};

export default BackButton;
