import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

export const StandardTextField = styled(TextField)(({ theme }) => ({
  fontFamily: '"Inter", sans-serif !important',
  fontSize: '15px !important',
  fontWeight: '400',
  letterSpacing: '-0.5',
  '& .MuiInputLabel-root': {
    fontFamily: '"Inter", sans-serif !important',
    fontSize: '15px !important',
    fontWeight: '400',
    letterSpacing: '-0.5'
  },
  '& .MuiOutlinedInput-root': {
    border: 'solid 0.5px rgba(255 255 255 0)',
    color: theme.text?.secondary,
    fontFamily: '"Inter", sans-serif !important',
    fontWeight: '400',
    fontSize: '15px',
    borderRadius: '12px',
    '&.Mui-focused': {
      border: 'none'
    }
  },
  // default disabled styles not working
  '& .MuiOutlinedInput-root.Mui-disabled': {},

  input: {
    fontFamily: '"Inter", sans-serif !important',
    fontSize: '15px',
    fontWeight: '400'
    // letterSpacing: "-0.5px",
  }
}));
