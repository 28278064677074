import React, { useEffect, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import { Toast, useToaster } from '@teespring/ts-components';
import { useDispatch } from 'react-redux';
import { removeToast, TOAST_POSITION } from 'redux/actions';
import { shape, arrayOf, string, object } from 'prop-types';

const TIMEOUT_DURATION = 60 * 5 * 1000; //5 minutes

const NotificationToaster = ({ notifications }) => {
  const { deleteToast } = useToaster();
  const dispatch = useDispatch();

  const handleClose = useCallback(
    (id) => {
      deleteToast(id, TOAST_POSITION);
      return dispatch(removeToast(id));
    },
    [deleteToast, dispatch]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isEmpty(notifications) && notifications.length > 1) {
        const removeNotifications = [
          ...notifications.slice(0, notifications.length - 1)
        ];

        forEach(removeNotifications, (notif) => handleClose(notif.id));
      }
    }, TIMEOUT_DURATION);
    return () => clearTimeout(timeout);
  }, [notifications, handleClose]);

  return (
    <>
      {!isEmpty(notifications) &&
        map(notifications, (notification) => {
          return (
            <Toast
              toastId={notification.id}
              key={notification.id}
              type={notification.type}
              title={notification.type === 'danger' ? 'Error' : 'Success'}
              options={{
                ...notification.options
              }}
              onClose={() => handleClose(notification.id)}
            >
              {notification.message}
            </Toast>
          );
        })}
    </>
  );
};

NotificationToaster.propTypes = {
  notifications: arrayOf(
    shape({ id: string, type: string, message: string, options: object })
  ).isRequired
};

export default NotificationToaster;
