import React from 'react';
import { object, func, string } from 'prop-types';
import './CtaBanner.scss';
import { useMediaQuery } from 'react-responsive';
import VIEWPORT_SIZES from '../../constants/viewportSizes';

/**
 * Renders a Call to action banner for app pages
 *
 * @return {object} JSX.Element
 * */
const CtaBanner = ({
  image,
  text,
  button,
  onLogEvent: handleLogEvent,
  variant,
  className,
  ...other
}) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${VIEWPORT_SIZES.mobileLg}`
  });
  return (
    <div className={`apps__cta-banner ${className}`} {...other}>
      <div
        className={`cta-banner-content--${variant}`}
        style={{
          backgroundImage: `url(${
            isMobile ? image.mobileUrl : image.desktopUrl
          })`
        }}
      >
        <div className='text'>
          <h1>{text.title}</h1>
          <p>{text.subtitle}</p>
        </div>
        <a href={button.link} onClick={handleLogEvent}>
          {button.text}
        </a>
      </div>
    </div>
  );
};

CtaBanner.propTypes = {
  className: string,
  image: object.isRequired,
  text: object.isRequired,
  button: object.isRequired,
  variant: string.isRequired,
  onLogEvent: func.isRequired
};

CtaBanner.defaultProps = {
  className: ''
};

export default CtaBanner;
