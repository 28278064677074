import Slider from '@mui/material/Slider';
import styled from '@emotion/styled';

export const StyledSlider = styled(Slider)`
  .MuiSlider-track {
    height: 7px;
    border: none;
    background: linear-gradient(
      90deg,
      #002fff -36.67%,
      #a75de8 31.49%,
      #e65ba7 58.38%,
      #fd7170 80.51%
    );
  }
  .MuiSlider-rail {
    border: 4px solid #e0e0e0;
    opacity: 1;
  }
  .MuiSlider-thumb {
    background: linear-gradient(0deg, #fd7270, #fd7270), #1954eb;
    height: 24px;
    width: 24px;

    &:hover {
      box-shadow: 0px 0px 0px 8px #fd727024;
    }
  }
  .MuiSlider-mark {
    height: 2px;
    width: 2px;
    background-color: #9e9e9e;
    opacity: 0.5;

    &.MuiSlider-markActive {
      background-color: #fff !important;
    }

    &:nth-of-type(3),
    &:nth-of-type(14) {
      display: none;
    }
  }
`;
