export const WIDGET_LAYOUTS = {
  carouselSmall: 'carousel-small',
  carouselWide: 'carousel-wide',
  grid: 'grid-sm-4'
};

export const WIDGET_LAYOUT_HEIGHTS = {
  [WIDGET_LAYOUTS.carouselSmall]: 420,
  [WIDGET_LAYOUTS.carouselWide]: 420,
  [WIDGET_LAYOUTS.grid]: 960
};

export const WIDGET_LAYOUT_NAMES = {
  [WIDGET_LAYOUTS.carouselSmall]: 'Small Carousel',
  [WIDGET_LAYOUTS.carouselWide]: 'Wide Carousel',
  [WIDGET_LAYOUTS.grid]: 'Grid'
};
