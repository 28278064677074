import axios from 'axios';
import { S3_DASHBOARD_CONTENT_URL } from 'constants/index';
import { addToast } from '../toast';

export const RECEIVE_GUIDES_AND_TIPS_DATA = 'RECEIVE_GUIDES_AND_TIPS_DATA';
export const RECEIVE_WHATS_NEW_DATA = 'RECEIVE_WHATS_NEW_DATA';

export const getGuidesAndTipsData = () => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${S3_DASHBOARD_CONTENT_URL}/guidesAndTips.json`
    );

    dispatch({
      type: RECEIVE_GUIDES_AND_TIPS_DATA,
      data
    });
  } catch (err) {
    dispatch(addToast(err?.response?.data?.message ?? err.message, 'danger'));
  }
};

export const getWhatsNewData = () => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${S3_DASHBOARD_CONTENT_URL}/whatsNew.json`
    );

    dispatch({
      type: RECEIVE_WHATS_NEW_DATA,
      data
    });
  } catch (err) {
    dispatch(addToast(err?.response?.data?.message ?? err.message, 'danger'));
  }
};

export const getOverviewContent = () => async (dispatch) => {
  try {
    await Promise.all([
      dispatch(getGuidesAndTipsData()),
      dispatch(getWhatsNewData())
    ]);
  } catch (err) {
    dispatch(addToast(err?.response?.data?.message ?? err.message, 'danger'));
  }
};
