import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import get from 'lodash/get';
import filter from 'lodash/filter';

import { Loader } from '@teespring/ts-components';

import {
  useAmplitude,
  useSubscriptionService,
  useUserSubscriptions
} from '../../../hooks';
import { SERVICE_ID_MAP } from '../../../constants';
import AppSettingsTemplate from '../../AppSettings/AppSettingsTemplate';
import SpringMarkContent from './SpringMarkContent';
import './ToolsSettingsPage.scss';
import { TOOLS_EVENT } from '../../../lib/tracking';

/**
 * Renders the settings page for tools
 *
 * @return {object} JSX.Element
 * */
const ToolsSettingsPage = () => {
  const { logEvent } = useAmplitude();
  const { serviceId } = useParams();
  const history = useHistory();

  const { userId } = useSelector((state) => state.user);
  const service = useSubscriptionService(serviceId);
  const { active: activeSubscriptions, isFetchingUserSubscriptions } =
    useUserSubscriptions(userId);

  const subscriptions = filter(
    activeSubscriptions,
    (subscription) =>
      subscription.creatorToolPrice?.creatorTool?.id === serviceId
  );

  const isSubscribed = subscriptions.length > 0;
  // Consider this page to be loading if we are fetching the service or the
  // subscription. If we are all loaded and have confirmed that we are not
  // subscribed, keep the loader up while we wait for the redirect away from
  // this page.
  const isLoading = isFetchingUserSubscriptions || !service || !isSubscribed;

  useEffect(() => {
    // Debounce this to allow redux state to settle
    const timeout = setTimeout(() => {
      if (!isFetchingUserSubscriptions && !isSubscribed) {
        // If we have completed loading and are not subscribed to the service,
        // redirect to the tool's details page
        history.push(`/tools/${serviceId}`);
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [isFetchingUserSubscriptions, isSubscribed, history, serviceId]);

  const displayName = service.name ?? '';
  const body = [service.description ?? ''];

  const header = {
    text: {
      title: service.name ?? '',
      author: 'Spring',
      subtitle: service.description ?? ''
    },
    status: {
      description: 'Active'
    },
    logo: {
      imgSrc: get(service, 'metaData.data.icon.url', ''),
      alt: get(service, 'metaData.data.icon.alt', '')
    }
  };

  const renderGeneralTabContent = () => {
    switch (serviceId) {
      case SERVICE_ID_MAP.spring_mark:
        return <SpringMarkContent subscriptions={subscriptions} />;
      default:
        return <></>;
    }
  };

  const tabs = [
    {
      title: 'general',
      render: renderGeneralTabContent()
    },
    {
      title: 'help and support',
      render: <div />
    }
  ];

  const handleTabSelected = (tab) => {
    if (
      tab === 'help-and-support' &&
      serviceId === SERVICE_ID_MAP.spring_mark
    ) {
      window.location.href =
        'https://www.spri.ng/creator-resources/springmark-faqs';
    }
  };

  return isLoading ? (
    <div className='tools_settings_page__loader'>
      <Loader />
    </div>
  ) : (
    <AppSettingsTemplate
      content={{
        displayName,
        body,
        header,
        tabs
      }}
      onTabSelected={handleTabSelected}
      onMount={() =>
        subscriptions.length > 0 &&
        logEvent(`${TOOLS_EVENT}.settings_page.viewed`, {
          toolId: service.id,
          toolName: service.name
        })
      }
    />
  );
};

export default ToolsSettingsPage;
