/* eslint-disable react/prop-types, import/no-dynamic-require */

import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useParams
} from 'react-router-dom';
import { Loader } from '@teespring/ts-components';

import CollectionsRoute from 'components/CollectionsRoute';
import CollectionWrapper from 'containers/CollectionsWrapper';
import MainLayout from 'containers/MainLayout';
import SamplesWrapper from 'containers/SamplesWrapper';
import ScrollToTop from 'containers/ScrollToTop';
import Overview from './Overview';
import Analytics from './Analytics';
import Listing from './Listing';
import Collections from './Listing/Collections';
import CollectionDetail from './Listing/CollectionDetail';
import ProductPicker from './Listing/ProductPicker';
import PurchasesWrapper from './Purchases';
import NotFoundPage from './NotFoundPage';
import AppStoreCategoryTemplate from './AppStoreCategory/AppStoreCategoryTemplate';
import AppSettingsPage from './AppSettings/AppSettingsPage';
import ToolsSettingsPage from './Tools/ToolSettings/ToolSettingsPage';
import SpringMarkPage from './Tools/SpringMark';
import Upgrades from './Upgrades';
import IntegrationsMain from './Integrations';
import IntegrationsPageTemplate from './Integrations/IntegrationsPage';

// Lazy Load / Code Split Routes
const SamplesEdit = React.lazy(() => import('./Samples/SamplesEdit'));
const SamplesMain = React.lazy(() => import('./Samples/SamplesMain'));
const Subscriptions = React.lazy(() => import('./Purchases/Subscriptions'));
const PurchaseHistory = React.lazy(() => import('./Purchases/PurchaseHistory'));
const ToolsPage = React.lazy(() => import('./Tools/ToolsPage'));
const ToolsSuccess = React.lazy(() => import('./Tools/ToolSuccess'));
const PaymentMethod = React.lazy(() => import('./Purchases/PaymentMethod'));
const General = React.lazy(() => import('./Stores/General'));
const ConnectDomain = React.lazy(() => import('./Stores/ConnectDomain'));
const VerifyDomain = React.lazy(() =>
  import('./Stores/ConnectDomain/VerifyDomain')
);
const PurchaseDomain = React.lazy(() => import('./Stores/PurchaseDomain'));
const ConfirmDomainPurchase = React.lazy(() =>
  import('./Stores/PurchaseDomain/ConfirmDomainPurchase')
);
const WrappedEdit = React.lazy(() => import('./Stores/Edit/WrappedEdit'));
const Appearance = React.lazy(() => import('./Stores/Appearance'));
const PurchaseSuccess = React.lazy(() =>
  import('./Stores/PurchaseDomain/PurchaseSuccess')
);

/**
 * We can refactor this to be simplier
 * passThroughProps is empty and the props in render is just match props
 * which each component has access to via hooks exposed by react router dom
 */

// we should probably wrap main layout here and use the match props for the configs
const Integrations = () => {
  const { integrationSlug } = useParams();

  if (integrationSlug === 'popupshop') {
    return <Redirect to='/integrations/storedrop' />;
  }

  return (
    <MainLayout
      mobileNavTitle='Integrations'
      useWhiteBackground={true}
      hideCreateButton={true}
    >
      <Suspense fallback={<Loader />}>
        <IntegrationsPageTemplate />
      </Suspense>
    </MainLayout>
  );
};

const Routes = (passThroughProps) => {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route
            path='/'
            exact={true}
            render={() => <Redirect to='/overview' />}
          />

          <Route
            path='/404'
            exact={true}
            render={() => (
              <MainLayout collapsedNav={false}>
                <NotFoundPage />
              </MainLayout>
            )}
          />
          <Route
            path='/overview'
            exact={true}
            render={(props) => (
              <MainLayout>
                <Overview {...props} {...passThroughProps} />
              </MainLayout>
            )}
          />
          <Route
            path='/analytics'
            exact={true}
            render={(props) => (
              <MainLayout>
                <Analytics {...props} {...passThroughProps} />
              </MainLayout>
            )}
          />
          <Route
            path='/stores'
            exact={true}
            render={(props) => (
              <MainLayout
                className='layout--nopadding'
                mobileNavTitle='Store'
                hideCreateButton={true}
              >
                <Suspense fallback={<Loader />}>
                  <General {...props} {...passThroughProps} />
                </Suspense>
              </MainLayout>
            )}
          />
          <Route
            path='/stores/purchase-domain'
            render={({ location }) => {
              return (
                <MainLayout
                  mobileNavTitle='Stores: Purchase a Domain'
                  useWhiteBackground={!location.pathname.includes('confirm')}
                >
                  <Suspense fallback={<Loader />}>
                    <Switch>
                      <Route
                        path='/stores/purchase-domain/confirm'
                        component={ConfirmDomainPurchase}
                      />
                      <Route
                        exact={true}
                        path='/stores/purchase-domain'
                        component={PurchaseDomain}
                      />
                    </Switch>
                  </Suspense>
                </MainLayout>
              );
            }}
          />
          <Route
            path='/purchase-domain/success'
            render={() => (
              <MainLayout useWhiteBackground={true}>
                <Suspense fallback={<Loader />}>
                  <PurchaseSuccess />
                </Suspense>
              </MainLayout>
            )}
          />
          <Route
            path='/stores/:storeId/connect-domain/new'
            exact={true}
            render={(props) => (
              <MainLayout
                className='layout--nopadding'
                mobileNavTitle='Stores: Connect a Domain'
              >
                <Suspense fallback={<Loader />}>
                  <ConnectDomain {...props} {...passThroughProps} />
                </Suspense>
              </MainLayout>
            )}
          />
          <Route
            path='/stores/:storeId/connect-domain/:customDomainId/verify'
            exact={true}
            render={(props) => (
              <MainLayout
                className='layout--nopadding'
                mobileNavTitle='Stores: Connect a Domain'
              >
                <Suspense fallback={<Loader />}>
                  <VerifyDomain {...props} {...passThroughProps} />
                </Suspense>
              </MainLayout>
            )}
          />
          <Route
            path='/stores/appearance'
            exact={true}
            render={(props) => (
              <MainLayout mobileNavTitle='Stores: Appearance'>
                <Suspense fallback={<Loader />}>
                  <Appearance {...props} {...passThroughProps} />
                </Suspense>
              </MainLayout>
            )}
          />
          <Route
            path='/integrations/all'
            exact={true}
            render={() => (
              <MainLayout
                mobileNavTitle='Integrations'
                useWhiteBackground={false}
                hideCreateButton={true}
              >
                <Suspense fallback={<Loader />}>
                  <IntegrationsMain />
                </Suspense>
              </MainLayout>
            )}
          />

          <Route
            path='/integrations/:integrationSlug'
            exact={true}
            render={() => <Integrations />}
          />

          {/* collections route */}
          <CollectionsRoute
            path='/listings/collections'
            component={
              <MainLayout mobileNavTitle='Listings: Collections'>
                <CollectionWrapper>
                  <Switch>
                    <Route
                      path='/listings/collections/:collectionId/products'
                      component={ProductPicker}
                    />
                    <Route
                      path='/listings/collections/:collectionId'
                      component={CollectionDetail}
                    />
                    <Route
                      exact={true}
                      path='/listings/collections'
                      component={Collections}
                    />
                  </Switch>
                </CollectionWrapper>
              </MainLayout>
            }
          />
          <Route
            path='/listings/:listingId/admin'
            exact={true}
            render={(props) => (
              <MainLayout
                className='layout--nopadding'
                collapsedNav={true}
                sidePane={null}
                hideNav={true}
              >
                <Listing {...props} {...passThroughProps} />
              </MainLayout>
            )}
          />
          {/* Upgrades routes */}
          <Route
            exact={true}
            path='/upgrades'
            render={(props) => (
              <MainLayout
                className='layout--nopadding'
                mobileNavTitle='Upgrades'
              >
                <Suspense fallback={<Loader />}>
                  <Upgrades {...props} {...passThroughProps} />
                </Suspense>
              </MainLayout>
            )}
          />
          {/* Store editor routes */}
          <Route
            exact={true}
            path={[
              '/stores/:storeId/edit/:tabId',
              '/stores/:storeId/edit/:tabId/:sectionId',
              '/stores/:storeId/edit'
            ]}
            render={(props) => (
              <Suspense fallback={<Loader />}>
                <WrappedEdit {...props} />
              </Suspense>
            )}
          />
          {/* Tools Routes */}
          <Route
            path='/tools/springmark'
            exact={true}
            render={() => (
              <MainLayout useWhiteBackground={true}>
                <Suspense fallback={<Loader />}>
                  <SpringMarkPage />
                </Suspense>
              </MainLayout>
            )}
          />
          <Route
            path='/tools/thank-you'
            exact={true}
            render={(props) => (
              <MainLayout useWhiteBackground={true}>
                <Suspense fallback={<Loader />}>
                  <ToolsSuccess {...props} {...passThroughProps} />
                </Suspense>
              </MainLayout>
            )}
          />
          <Route
            path='/tools/:serviceId'
            exact={true}
            render={(props) => (
              <MainLayout useWhiteBackground={true}>
                <Suspense fallback={<Loader />}>
                  <ToolsPage {...props} {...passThroughProps} />
                </Suspense>
              </MainLayout>
            )}
          />
          <Route
            path='/tools/:serviceId/settings'
            exact={true}
            render={() => (
              <MainLayout useWhiteBackground={true}>
                <ToolsSettingsPage />
              </MainLayout>
            )}
          />
          <Route
            path='/apps/:app/settings'
            exact={true}
            render={() => (
              <MainLayout useWhiteBackground={true}>
                <AppSettingsPage />
              </MainLayout>
            )}
          />
          <Route
            path='/apps/categories/:category/'
            exact={true}
            render={() => (
              <MainLayout>
                <AppStoreCategoryTemplate />
              </MainLayout>
            )}
          />
          {/* Purchase Routes */}
          <Route
            path='/purchases'
            render={() => (
              <MainLayout mobileNavTitle='Purchases' useWhiteBackground={false}>
                <PurchasesWrapper>
                  <Suspense fallback={<Loader />}>
                    <Switch>
                      <Route
                        path='/purchases/subscriptions'
                        render={(props) => (
                          <Subscriptions {...props} {...passThroughProps} />
                        )}
                      />
                      <Route
                        path='/purchases/payment-method'
                        render={(props) => (
                          <PaymentMethod {...props} {...passThroughProps} />
                        )}
                      />
                      <Route
                        path='/purchases/purchase-history'
                        component={PurchaseHistory}
                      />
                    </Switch>
                  </Suspense>
                </PurchasesWrapper>
              </MainLayout>
            )}
          />
          {/* Samples Routes */}
          <Route
            path='/samples'
            render={() => (
              <MainLayout
                useWhiteBackground={true}
                collapsedNav={true}
                hideActionButtons={true}
              >
                <SamplesWrapper>
                  <Switch>
                    <Route
                      path='/samples/edit'
                      render={() => (
                        <Suspense fallback={<Loader />}>
                          <SamplesEdit />
                        </Suspense>
                      )}
                    />
                    <Route
                      path='/samples'
                      exact={true}
                      render={() => (
                        <Suspense fallback={<Loader />}>
                          <SamplesMain />
                        </Suspense>
                      )}
                    />
                  </Switch>
                </SamplesWrapper>
              </MainLayout>
            )}
          />
          {/* Route to catch marketing mixup */}
          <Route
            path='/dashboard/overview'
            exact={true}
            render={() => <Redirect to='/overview?from=/dashboard' />}
          />
          {/* Catch all route */}
          <Route render={() => <Redirect to='/404' />} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default Routes;
