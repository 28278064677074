import PropTypes from 'prop-types';
import { StandardTextField } from './StyledMuiComponents/StandardTextInput';
import './styles/slider.scss';
import { StyledSlider } from './StyledMuiComponents/Slider';

function SliderAndInput(props) {
  const { isPro, value, onChange, marks, min, max } = props;

  return (
    <>
      <div className='slider-and-input'>
        <div className='slider'>
          <span className='min-products'>{min}</span>
          <StyledSlider
            style={{ width: '100%' }}
            value={value}
            min={min}
            step={1}
            max={max}
            marks={marks}
            onChange={onChange}
            aria-labelledby='input-slider'
          />
          <span className='max-products'>{max}</span>
        </div>
        <StandardTextField
          disabled={!isPro}
          id='title'
          value={value}
          onChange={onChange}
          sx={{
            input: {
              width: '54px',
              height: '48px',
              padding: '0px',
              textAlign: 'center'
            }
          }}
          style={{
            borderRadius: '12px',
            backgroundColor: '#fff'
          }}
        />
      </div>
    </>
  );
}

SliderAndInput.propTypes = {
  value: PropTypes.number,
  marks: PropTypes.bool,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  isPro: PropTypes.bool
};

SliderAndInput.defaultProps = {
  value: null,
  marks: true,
  onChange: null,
  min: 1,
  max: 10,
  isPro: null
};

export default SliderAndInput;
