import { string } from 'prop-types';
import { useSelector } from 'react-redux';
import IntegrationsPageConnected from '../IntegrationsPageConnected';
import '../IntegrationsPage.scss';

export const IntegrationsPageTitle = (props) => {
  const { title, iconUrl } = props;
  const connected = useSelector(
    (state) => state.integrationConnection.isConnected
  );
  return (
    <div className='integrations_page_title'>
      <div
        style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}
      >
        <img
          src={iconUrl}
          style={{ width: '32px', height: '32px' }}
          alt='social icon'
        />
        <div
          className='integrations_page_title_text'
          style={{
            fontFamily: 'DM Sans',
            fontWeight: '500',
            fontSize: '18px',
            margin: '0 8px'
          }}
        >
          {title}
        </div>
      </div>
      <IntegrationsPageConnected connected={connected} />
    </div>
  );
};

IntegrationsPageTitle.propTypes = {
  title: string.isRequired,
  iconUrl: string.isRequired
};

export default IntegrationsPageTitle;
